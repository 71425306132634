import { post } from '@/utils/http';

export const getAdWordList = (params) =>
  post({
    url: '/manage/video/shieldingWord/examine/get/page',
    data: params,
  });
export const addAdWord = (params) =>
  post({
    url: '/manage/video/shieldingWord/examine/insert',
    data: params,
  });
export const delAdWord = (params) =>
  post({
    url: '/manage/video/shieldingWord/examine/delete/batch',
    data: params,
  });
