import {
  PageHeader,
  Card,
  Space,
  Form,
  Row,
  Col,
  Select,
  Button,
  Table,
  Badge,
  Popconfirm,
  message,
} from 'antd';
import { useOutletContext } from 'react-router-dom';
import { useAntdTable, useMemoizedFn } from 'ahooks';
import { FILTER1_OPTIONS, FILTER2_OPTIONS, COLUMNS } from './config';
import { EditableRow, EditableCell, AddVipOperator } from '@/components';
import { useTableColumns } from '@/hooks';
import {
  getList,
  addTimes,
  addVip,
  suspend,
  changePhone,
} from '@/services/customer-service/workOrder';
import { getOrderParams } from '@/utils/commonUtils';
import './index.less';

const { Option } = Select;

const components = {
  body: {
    row: EditableRow,
    cell: EditableCell,
  },
};

const initialValues = {
  filter1: '',
  filter2: '',
};

const getTableData = async ({ current, pageSize }, formData) => {
  const { search } = getOrderParams(formData);

  const result = await getList({
    pageIndex: current,
    pageSize,
    params: search,
  });

  const data = result.data || {};

  return {
    total: data.total || 0,
    list: data.data || [],
  };
};

function WorkOrder() {
  const { openChat, unDealCount } = useOutletContext();
  const [form] = Form.useForm();
  const { tableProps, search, params, run, refresh } = useAntdTable(
    getTableData,
    {
      form,
      defaultParams: [{ current: 1, pageSize: 10 }, initialValues],
    }
  );

  const handleDisabled = async (record) => {
    const result = await suspend({
      userId: record.userId,
    });

    if (result.code === 200) {
      message.success('操作成功');
    }
  };

  const handleAddTimes = async (values, record) => {
    const result = await addTimes({
      userId: record.userId,
      watchNumber: values.times,
    });
    if (result.code === 200) {
      message.success('操作成功');
    }
  };

  const handleAddVip = async (values, record) => {
    const result = await addVip({
      userId: record.userId,
      days: values.days,
    });
    if (result.code === 200) {
      message.success('操作成功');
    }
  };

  const handleSave = useMemoizedFn(async (val) => {
    const result = await changePhone({
      userId: val.userId,
      phone: val.phone,
    });
    if (result.code === 200) {
      message.success('操作成功');
      refresh();
    }
  });

  const { tableColumns, setTableColumns } = useTableColumns(COLUMNS, {
    onSave: handleSave,
    controlColumns: {
      title: '操作',
      key: 'action',
      width: 220,
      align: 'center',
      fixed: 'right',
      render: (text, record) => (
        <>
          <Button type="link" size="small" onClick={() => openChat(record)}>
            回复
          </Button>
          <Popconfirm
            title="确定要将此用户封号?"
            onConfirm={() => handleDisabled(record)}
            // onCancel={cancel}
            okText="确定"
            cancelText="取消"
          >
            <Button type="link" size="small" danger>
              封号
            </Button>
          </Popconfirm>
          <AddVipOperator type="times" onAdd={handleAddTimes} record={record} />
          <AddVipOperator onAdd={handleAddVip} record={record} type="vip" />
        </>
      ),
    },
  });

  return (
    <div className="user-management work-order">
      <PageHeader
        ghost={false}
        backIcon={false}
        title={
          <Badge count={unDealCount} offset={[15, 4]}>
            <div className="title">工单处理</div>
          </Badge>
        }
      />
      <div className="content-wrap">
        <Space direction="vertical" style={{ width: '100%' }}>
          <Card>
            <Form initialValues={initialValues} form={form}>
              <Row>
                <Col span={5} offset={1}>
                  <Form.Item
                    label="筛选1"
                    name="filter1"
                    style={{ marginBottom: 0 }}
                  >
                    <Select>
                      {FILTER1_OPTIONS.map((item) => (
                        <Option value={item.value} key={item.value}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={5} offset={1}>
                  <Form.Item
                    label="筛选2"
                    name="filter2"
                    style={{ marginBottom: 0 }}
                  >
                    <Select>
                      {FILTER2_OPTIONS.map((item) => (
                        <Option value={item.value} key={item.value}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={2} offset={1}>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Button type="primary" onClick={search.submit}>
                      搜索
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
          <Card>
            <div className="table-title">查询结果</div>
            <Table
              className="edit-table"
              components={components}
              rowClassName={() => 'editable-row'}
              bordered
              columns={tableColumns}
              // scroll={{ x: 'max-content' }}
              {...tableProps}
              pagination={{
                ...tableProps.pagination,
                showTotal: (total) => `共 ${total} 条`,
                // showSizeChanger: true,
                // showQuickJumper: true,
              }}
              rowKey="id"
            />
          </Card>
        </Space>
      </div>
    </div>
  );
}

export default WorkOrder;
