import { post } from '@/utils/http';

export const getList = (params) =>
  post({
    url: '/manage/withdraw/examine/get/page',
    data: params,
  });
export const passBatch = (params) =>
  post({
    url: '/manage/withdraw/examine/approve/batch',
    data: params,
  });
export const rejectBatch = (params) =>
  post({
    url: '/manage/withdraw/examine/reject/batch',
    data: params,
  });
