export const COLUMNS = [
  {
    title: '过滤id',
    dataIndex: 'shieldingWordId',
    align: 'center',
    width: 80,
  },

  {
    title: '过滤内容',
    dataIndex: 'content',
    align: 'center',
    ellipsis: true,
    formEditable: true,
    type: 'input',
    rules: [{ required: true, message: '请输入过滤内容' }],
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    align: 'center',
    width: 150,
    render: (text) => (text ? text.replace('T', ' ') : ''),
  },
];
