export const COLUMNS = [
  {
    title: '功能项',
    dataIndex: 'feature',
    width: 210,
    align: 'center',
  },
  {
    title: '参数配置',
    dataIndex: 'content',
    align: 'center',
    editable: true,
    type: 'textarea',
    autoSize: { minRows: 4, maxRows: 10 },
    ellipsis: true,
    rules: [{ required: true, message: '请输入参数配置' }],
    render: (text) => <div className="config-cell">{text}</div>,
  },
];

export const DATA_SOURCE = [
  {
    key: 0,
    name: '进入APP官方公告的标题',
    config: '官方公告',
  },
  {
    key: 1,
    name: '进入安卓端官方公告的内容',
    // config:
    //   '<div style="color:red">富文本</div><form><input type="text" name="q" value="test"><button id="submit">Submit</button></form><a href="https://www.baidu.com/" target="_blank">baidu</a>',
    configRich: true,
    config:
      '<p><span style="color:#e91212">富文本</span><span style="color:#333333">lalala<a href="https://www.baidu.com" target="_blank">链接</a></span></p>',
  },
  {
    key: 2,
    name: '进入苹果商店端官方公告的内容',
    config: '<p><span style="color:#e91212">啦啦啦</span></p>',
    configRich: true,
  },
  {
    key: 3,
    name: '进入PWA/企业签端官方公告的内容',
    config: 'dasd',
    configRich: true,
  },
];
