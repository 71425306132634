import { post } from '@/utils/http';

export const getCommentList = (params) =>
  post({
    url: '/manage/video/comment/examine/get/page',
    data: params,
  });
export const passComment = (params) =>
  post({
    url: '/manage/video/comment/examine/approve/batch',
    data: params,
  });
export const rejectComment = (params) =>
  post({
    url: '/manage/video/comment/examine/reject/batch',
    data: params,
  });
export const eidtUserCommentStatus = (params) =>
  post({
    url: '/manage/video/comment/examine/user/status/change',
    data: params,
  });
