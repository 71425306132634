import { useEffect } from 'react';
import { Modal, Button, message } from 'antd';
import ClipboardJS from 'clipboard';

import './index.less';

function PassModal({ visible, data, onClose, onOk }) {
  useEffect(() => {
    const copyDataIndex = ['accountNum', 'payee'];
    const clipboardArr = [];
    copyDataIndex.forEach((dataIndex) => {
      if (data[dataIndex]) {
        const clipboard = new ClipboardJS(`#modal-copy-${dataIndex}`, {
          text(trigger) {
            return data[dataIndex];
          },
        });

        clipboard.on('success', (e) => {
          message.success('复制成功！');
        });

        clipboard.on('error', (e) => {
          message.error('复制失败！');
        });
        clipboardArr.push(clipboard);
      }
    });

    return () => {
      clipboardArr.forEach((clipboard) => {
        clipboard.destroy();
      });
    };
  }, [data]);
  return (
    <Modal
      title="通过确认"
      visible={visible}
      onOk={onOk}
      onCancel={onClose}
      className="cash-out-modal"
    >
      <div className="content-top">
        <div key="1">通过后，将自动从用户账户中扣除金额</div>
        <div key="2">客户端将为用户展示为已处理打款</div>
      </div>
      <div className="copy-tips">复制提现信息</div>
      <div className="copy-item">
        <span>提现账户：</span>
        <span>{data.accountNum}</span>
        <Button type="link" id="modal-copy-accountNum">
          复制
        </Button>
      </div>
      <div className="copy-item">
        <span>提现人：</span>
        <span>{data.payee}</span>
        <Button type="link" id="modal-copy-payee">
          复制
        </Button>
      </div>
    </Modal>
  );
}

export default PassModal;
