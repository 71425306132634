/* eslint-disable react/jsx-filename-extension */
import {
  FundViewOutlined,
  ContainerOutlined,
  CustomerServiceOutlined,
  InteractionOutlined,
  VideoCameraOutlined,
  TeamOutlined,
  AuditOutlined,
  AppstoreOutlined,
  FundProjectionScreenOutlined,
  SyncOutlined,
  TagOutlined,
  DeploymentUnitOutlined,
  SettingOutlined,
  FileTextOutlined,
  ToolOutlined,
  VideoCameraAddOutlined,
  MobileOutlined,
  SmileOutlined,
  DollarOutlined,
  FunnelPlotOutlined,
  StarOutlined,
  ProfileOutlined,
  OrderedListOutlined,
  BorderOuterOutlined,
} from '@ant-design/icons';

export default [
  {
    name: '数据看板',
    url: '/data',
    icon: <FundViewOutlined />,
    value: 'A0',
  },
  {
    name: '业务管理',
    url: '/business',
    icon: <ContainerOutlined />,
    value: 'B0',
    children: [
      // {
      //   name: '排片管理',
      //   url: '/filming-management',
      //   icon: <VideoCameraOutlined />,
      //   parentKey: '/business',
      //   value: 'B1',
      // },
      {
        name: '订单管理',
        url: '/order-management',
        icon: <OrderedListOutlined />,
        parentKey: '/business',
        value: 'B11',
      },
      {
        name: '用户管理',
        url: '/user-management',
        icon: <TeamOutlined />,
        value: 'B2',
      },
      {
        name: '作品管理',
        url: '/works-management',
        icon: <AppstoreOutlined />,
        value: 'B3',
        children: [
          {
            name: '长视频管理',
            url: '/long-video-management',
            parentKey: '/works-management',
            value: 'B31',
          },
          {
            name: '短视频管理',
            url: '/short-video-management',
            parentKey: '/works-management',
            value: 'B32',
          },
          {
            name: '动态贴管理',
            url: '/sticker-management',
            parentKey: '/works-management',
            value: 'B33',
          },
          // {
          //   name: '花絮管理',
          //   url: '/trivia-management',
          //   parentKey: '/works-management',
          //   value: 'B34',
          // },
        ],
      },
      {
        name: '更新管理',
        url: '/update-management',
        icon: <SyncOutlined />,
        value: 'B4',
      },
      {
        name: '渠道管理',
        url: '/channel-management',
        icon: <DeploymentUnitOutlined />,
        value: 'B5',
        children: [
          {
            name: '渠道列表',
            url: '/channel-list',
            parentKey: '/channel-management',
            value: 'B51',
          },
          {
            name: '渠道砍量',
            url: '/channel-cut',
            parentKey: '/channel-management',
            value: 'B52',
          },
        ],
      },
      {
        name: '片商管理',
        url: '/film-company-management',
        icon: <TagOutlined />,
        value: 'B6',
      },
      {
        name: '配置管理',
        url: '/config',
        icon: <SettingOutlined />,
        value: 'B7',
        children: [
          {
            name: '基础配置',
            url: '/basic-config',
            parentKey: '/config',
            value: 'B71',
          },
          {
            name: '公告配置',
            url: '/announcement-config',
            parentKey: '/config',
            value: 'B72',
          },
          // {
          //   name: '专题配置',
          //   url: '/thematic-config',
          //   parentKey: '/config',
          //   value: 'B73',
          // },
          // {
          //   name: '标签配置',
          //   url: '/label-config',
          //   parentKey: '/config',
          //   value: 'B74',
          // },
          {
            name: '域名配置',
            url: '/domain-config',
            parentKey: '/config',
            // value: 'B74',
          },
          {
            name: '商品配置',
            url: '/product-config',
            parentKey: '/config',
            value: 'B75',
          },
          {
            name: '奖励配置',
            url: '/award-config',
            parentKey: '/config',
            value: 'B76',
          },
          {
            name: '砍量配置',
            url: '/cut-config',
            parentKey: '/config',
            value: 'B77',
          },
        ],
      },
    ],
  },
  {
    name: '客服相关',
    url: '/customer-service',
    icon: <CustomerServiceOutlined />,
    badge: true,
    value: 'C0',
    children: [
      {
        name: '工单处理',
        url: '/customer-service/work-order',
        parentKey: '/customer-service',
        icon: <FileTextOutlined />,
        value: 'C3',
      },
      {
        name: '域名检测',
        url: '/customer-service/domain-test',
        parentKey: '/customer-service',
        icon: <ToolOutlined />,
        value: 'C2',
      },
      {
        name: '快捷消息设置',
        url: '/customer-service/quick-message',
        parentKey: '/customer-service',
        icon: <SettingOutlined />,
        value: 'C1',
      },
    ],
  },
  {
    name: '审批管理',
    url: '/approval-management',
    icon: <AuditOutlined />,
    badge: true,
    value: 'D0',
    children: [
      {
        name: '长视频审批',
        url: '/long-video-approval',
        parentKey: '/approval-management',
        icon: <VideoCameraAddOutlined />,
        value: 'D1',
        badge: 'unExamineLongVideoNum',
      },
      {
        name: '短视频审批',
        url: '/short-video-approval',
        parentKey: '/approval-management',
        icon: <MobileOutlined />,
        value: 'D2',
        badge: 'unExamineShortVideoNum',
      },
      {
        name: '动态贴审批',
        url: '/sticker-approval',
        parentKey: '/approval-management',
        icon: <SmileOutlined />,
        value: 'D3',
        badge: 'unExamineActionVideoNum',
      },
      // {
      //   name: '花絮审批',
      //   url: '/trivia-approval',
      //   parentKey: '/trivia-management',
      //   icon: <StarOutlined />,
      //   value:'D4'
      //     badge: 'unExamineTitbitVideoNum',
      // },
      {
        name: '提现审批',
        url: '/cash-out-approval',
        parentKey: '/approval-management',
        icon: <DollarOutlined />,
        value: 'D5',
        badge: 'unExamineWithdrawRecordNum',
      },
      {
        name: '评论审批',
        url: '/comment-approval',
        parentKey: '/approval-management',
        icon: <ProfileOutlined />,
        value: 'D6',
      },
      {
        name: '广告词过滤',
        url: '/ad-word-filter',
        parentKey: '/approval-management',
        icon: <FunnelPlotOutlined />,
        value: 'D7',
      },
    ],
  },
  {
    name: '通道管理',
    url: '/aisle-management',
    icon: <InteractionOutlined />,
    value: 'E0',
  },
  {
    name: '广告管理',
    url: '/advertising-management',
    icon: <FundProjectionScreenOutlined />,
    value: 'F0',
  },
  {
    name: '弹窗管理',
    url: '/modal-management',
    icon: <BorderOuterOutlined />,
    value: 'F0',
  },
];
