/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
import {
  PageHeader,
  Card,
  Space,
  Form,
  Row,
  Col,
  Select,
  Input,
  Button,
  Table,
  Modal,
  Popover,
  message,
} from 'antd';
import { PlusOutlined, DownOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useRequest, useAntdTable } from 'ahooks';
import { COLUMNS } from './config';
import { useDetailModal, useTableColumns } from '@/hooks';
import { EditModal } from './components';
import {
  getPlateforms,
  getList,
  addPlateform,
  addPayAisle,
  editPayAisle,
} from '@/services/aisle-management';

const { Option } = Select;

const initialValues = {
  platformId: '',
  channelName: '',
};

const getPlateformList = async () => {
  const result = await getPlateforms();
  return result.data || [];
};

const getTableData = async ({ current, pageSize }, formData) => {
  const result = await getList({
    page: current,
    size: pageSize,
    ...formData,
  });
  return {
    total: result.data?.total || 0,
    list: result.data?.list || [],
  };
};

function AisleManagement() {
  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();
  const { data: platformList, refresh: refreshPlatformList } =
    useRequest(getPlateformList);
  const {
    tableProps,
    search,
    params,
    run: getDatasource,
    refresh: refreshDataSource,
  } = useAntdTable(getTableData, {
    form: searchForm,
    defaultParams: [{ current: 1, pageSize: 10 }, initialValues],
  });
  const { showDetail, selectRecord, handleShowDetail, handleCloseDetail } =
    useDetailModal();
  const [showPlatform, setShowPlatform] = useState(false);

  const [showPlateformList, setShowPlateformList] = useState(false);

  const { tableColumns, setTableColumns } = useTableColumns(COLUMNS, {
    controlColumns: {
      title: '操作',
      key: 'action',
      width: 80,
      align: 'center',
      fixed: 'right',
      render: (text, record) => (
        <Button
          type="link"
          size="small"
          onClick={() => handleShowDetail(record)}
        >
          编辑
        </Button>
      ),
    },
  });

  const handleAdd = () => {
    setShowPlatform(true);
  };

  const handleClosePlatform = () => {
    setShowPlatform(false);
    form.resetFields();
  };

  const handleAddPlatform = async () => {
    const values = await form.validateFields();
    console.log(values);

    const result = await addPlateform(values);

    if (result.code === 200) {
      message.success('平台添加成功');
      refreshPlatformList();
      handleClosePlatform();
      handleShowDetail(result.data);
    }
  };

  const handleAddAisle = async (value) => {
    console.log(value);
    value.status = value.status ? 1 : 0;

    const result = value.id
      ? await editPayAisle(value)
      : await addPayAisle(value);
    if (result.code === 200) {
      message.success(value.id ? '通道编辑成功' : '通道添加成功');
      handleCloseDetail();
      if (value.id) {
        refreshDataSource();
      } else {
        getDatasource(
          {
            ...params[0],
            current: 1,
          },
          params[1]
        );
      }
    }
  };

  const handleShowAddAisle = (platform) => {
    handleShowDetail(platform);
    setShowPlateformList(false);
  };

  return (
    <div className="user-management aisle-management">
      <PageHeader ghost={false} backIcon={false} title="通道管理" />
      <div className="content-wrap">
        <Space direction="vertical" style={{ width: '100%' }}>
          <Card>
            <Form initialValues={initialValues} form={searchForm}>
              <Row>
                <Col span={3}>
                  <Button
                    type="primary"
                    style={{ marginBottom: '10px' }}
                    onClick={handleAdd}
                  >
                    <PlusOutlined />
                    新建通道平台
                  </Button>
                </Col>
                <Col span={3} offset={1}>
                  <Popover
                    content={(platformList || []).map((item) => (
                      <div key={item.platformId}>
                        {item.platformName}
                        <Button
                          type="link"
                          onClick={() => handleShowAddAisle(item)}
                        >
                          新增通道
                        </Button>
                      </div>
                    ))}
                    placement="bottom"
                    trigger="click"
                    visible={showPlateformList}
                    onVisibleChange={(visible) => setShowPlateformList(visible)}
                  >
                    <Button type="primary">
                      原有通道平台 <DownOutlined />
                    </Button>
                  </Popover>
                </Col>
                <Col span={5} offset={3}>
                  <Form.Item
                    label="支付平台"
                    name="platformId"
                    style={{ marginBottom: 0 }}
                  >
                    <Select>
                      <Option value="">全部</Option>
                      {(platformList || []).map((item) => (
                        <Option value={item.platformId} key={item.platformId}>
                          {item.platformName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={5} offset={1}>
                  <Form.Item
                    label="关键搜索"
                    name="channelName"
                    style={{ marginBottom: 0 }}
                  >
                    <Input placeholder="输入关键字" />
                  </Form.Item>
                </Col>
                <Col span={2} offset={1}>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Button type="primary" onClick={search.submit}>
                      确认查看
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
          <Card>
            <div className="table-title">查询结果</div>
            <Table
              className="edit-table"
              bordered
              columns={tableColumns}
              rowKey="id"
              {...tableProps}
              pagination={{
                ...tableProps.pagination,
                showTotal: (total) => `共 ${total} 条`,
                // showSizeChanger: true,
                // showQuickJumper: true,
              }}
            />
          </Card>
        </Space>
      </div>
      <EditModal
        visible={showDetail}
        onClose={handleCloseDetail}
        data={selectRecord}
        onOk={handleAddAisle}
      />
      <Modal
        title="新建通道平台"
        visible={showPlatform}
        onCancel={handleClosePlatform}
        okText="确定新增并前往编辑该平台旗下通道"
        onOk={handleAddPlatform}
      >
        <Form form={form}>
          <Form.Item
            label="平台名称"
            name="platformName"
            rules={[{ required: true, message: '请输入平台名称' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default AisleManagement;
