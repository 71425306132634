import {
  PageHeader,
  Card,
  Space,
  Form,
  Row,
  Col,
  Select,
  Input,
  Button,
  Table,
  Popconfirm,
  Modal,
  message,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useAntdTable, useMemoizedFn } from 'ahooks';
import { SORT1_OPTIONS, COLUMNS } from './config';
import { EditableRow, EditableCell, DetailModal } from '@/components';
import {
  useDetailModal,
  useTableColumns,
  useModalFormColumns,
  useTableRowSelection,
} from '@/hooks';
import {
  getCommentList,
  passComment,
  rejectComment,
  eidtUserCommentStatus,
} from '@/services/approval-management/commentApproval';
import { getOrderParams, getPageUpdate } from '@/utils/commonUtils';

const { Option } = Select;
const { confirm } = Modal;

const components = {
  body: {
    row: EditableRow,
    cell: EditableCell,
  },
};

const initialValues = {
  sort1: '',
  search: '',
};

const getTableData = async ({ current, pageSize }, formData) => {
  const { orders } = getOrderParams(formData);

  const queryParams = {
    pageIndex: current,
    pageSize,
  };

  if (orders.length) {
    queryParams.orders = orders;
  }
  if (formData.search) {
    queryParams.params = {
      userName: formData.search,
    };
  }

  const result = await getCommentList(queryParams);
  console.log(result, 213);
  const data = result.data || {};
  const list = data.data || [];
  list.forEach((item) => {
    item.commentStatus = item.commentStatus !== '1';
  });
  return {
    total: data.total || 0,
    list: data.data || [],
  };
};

function CommentApproval() {
  const [form] = Form.useForm();
  const { tableProps, search, params, run } = useAntdTable(getTableData, {
    form,
    defaultParams: [{ current: 1, pageSize: 10 }, initialValues],
  });
  const [selectedRowKeys, setSelectedRowKeys] = useTableRowSelection({
    dataSource: tableProps.dataSource,
    keyName: 'commentId',
  });
  const { showDetail, selectRecord, handleShowDetail, handleCloseDetail } =
    useDetailModal();
  const { formColumns, formType, getModalFormColumns } =
    useModalFormColumns(COLUMNS);

  const handleSave = useMemoizedFn(async (val) => {
    console.log(val);
    if (val.commentStatus) {
      const result = await eidtUserCommentStatus({
        userId: val.userId,
        commentStatus: val.commentStatus ? 0 : 1,
      });
      if (result.code === 200) {
        message.success('操作成功');
        run({ ...params[0], current: 1 }, params[1]);
        if (showDetail) {
          handleCloseDetail();
        }
      }
    } else if (showDetail) {
      handleCloseDetail();
    }
  });

  const delAndPassUpdate = (commentIds) => {
    // const page = { ...params[0] };
    // const {
    //   dataSource,
    //   pagination: { current, pageSize, total },
    // } = tableProps;
    // if (
    //   dataSource.length === commentIds.length &&
    //   current > 1 &&
    //   current === Math.ceil(total / pageSize)
    // ) {
    //   page.current = current - 1;
    // }
    const page = getPageUpdate(tableProps, commentIds.length);
    run(
      {
        ...params[0],
        current: page,
      },
      params[1]
    );
  };

  const delComments = useMemoizedFn(async (commentIds) => {
    const result = await rejectComment({
      commentIds,
    });
    if (result.code === 200) {
      message.success('操作成功');
      delAndPassUpdate(commentIds);
    }
  });

  const passComments = useMemoizedFn(async (commentIds) => {
    console.log(commentIds);
    const result = await passComment({
      commentIds,
    });
    if (result.code === 200) {
      message.success('操作成功');
      delAndPassUpdate(commentIds);
    }
  });

  const handleDetail = (record) => {
    getModalFormColumns(record, 'detail');
    handleShowDetail(record);
  };

  const { tableColumns, setTableColumns } = useTableColumns(COLUMNS, {
    onSave: handleSave,
    controlColumns: {
      title: '操作',
      key: 'action',
      width: 150,
      align: 'center',
      fixed: 'right',
      render: (text, record) => (
        <>
          <Button type="link" size="small" onClick={() => handleDetail(record)}>
            查看
          </Button>
          <Popconfirm
            title={
              <>
                {/* <div>确定要通过该条评论吗？</div> */}
                <div>
                  通过后，该评论将从审核列表
                  <br />
                  消失， 并且展示在客户端
                </div>
              </>
            }
            onConfirm={() => passComments([record.commentId])}
            okText="确定"
            cancelText="取消"
          >
            <Button type="link" size="small">
              通过
            </Button>
          </Popconfirm>

          <Popconfirm
            title="确定要删除该条评论吗？"
            onConfirm={() => delComments([record.commentId])}
            okText="确定"
            cancelText="取消"
          >
            <Button size="small" type="text" danger>
              删除
            </Button>
          </Popconfirm>
        </>
      ),
    },
  });

  const handleBatchPass = () => {
    console.log(selectedRowKeys);
    confirm({
      title: '确定要批量通过这些评论?',
      content: '通过后，这些评论将从审核列表消失，并且展示在客户端',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        passComments(selectedRowKeys);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };
  const handleBatchDel = () => {
    console.log(selectedRowKeys);
    confirm({
      title: '确定要批量删除这些评论?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        delComments(selectedRowKeys);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  return (
    <div className="long-video-approval">
      <PageHeader ghost={false} backIcon={false} title="评论审批" />
      <div className="content-wrap">
        <Space direction="vertical" style={{ width: '100%' }}>
          <Card>
            <Form initialValues={initialValues} form={form}>
              <Row>
                <Col span={5} offset={1}>
                  <Form.Item
                    label="排序1"
                    name="sort1"
                    style={{ marginBottom: 0 }}
                  >
                    <Select>
                      {SORT1_OPTIONS.map((item) => (
                        <Option value={item.value} key={item.value}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={5} offset={1}>
                  <Form.Item
                    label="关键搜索"
                    name="search"
                    style={{ marginBottom: 0 }}
                  >
                    <Input placeholder="搜索用户名" />
                  </Form.Item>
                </Col>
                <Col span={2} offset={1}>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Button type="primary" onClick={search.submit}>
                      确认查看
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
          <Card>
            <div className="table-title">查询结果</div>
            <div className="batch-btn-wrap">
              <Space>
                <Button
                  type="primary"
                  size="small"
                  disabled={!selectedRowKeys.length}
                  onClick={handleBatchPass}
                >
                  通过
                </Button>
                <Button
                  type="primary"
                  size="small"
                  disabled={!selectedRowKeys.length}
                  danger
                  onClick={handleBatchDel}
                >
                  删除
                </Button>
              </Space>
            </div>
            <Table
              className="edit-table"
              components={components}
              rowClassName={() => 'editable-row'}
              bordered
              columns={tableColumns}
              rowSelection={{
                selectedRowKeys,
                onChange: (selectedRowKeys) =>
                  setSelectedRowKeys(selectedRowKeys),
              }}
              {...tableProps}
              pagination={{
                ...tableProps.pagination,
                showTotal: (total) => `共 ${total} 条`,
                // showSizeChanger: true,
                // showQuickJumper: true,
              }}
              rowKey="commentId"
            />
          </Card>
        </Space>
      </div>
      <DetailModal
        columns={formColumns}
        data={selectRecord}
        destroyOnClose
        visible={showDetail}
        onClose={handleCloseDetail}
        onOk={handleSave}
        modalType={formType}
        title="评论详情"
        centered
        labelWrap
      />
    </div>
  );
}

export default CommentApproval;
