/* eslint-disable react/jsx-filename-extension */
import { SortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';

const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
));
export const COLUMNS = [
  // {
  //   title: '',
  //   dataIndex: 'sort',
  //   align: 'center',
  //   width: 50,
  //   className: 'drag-visible',
  //   render: () => <DragHandle />,
  //   fixed: 'left',
  //   notShowForm: true,
  //   // type: 'inputNumber',
  //   // formEditable: true,
  // },
  {
    title: 'ID',
    dataIndex: 'id',
    align: 'center',
    ellipsis: true,
    width: 60,
    notShowForm: true,
  },
  {
    title: '主题',
    dataIndex: 'theme',
    align: 'center',
    width: 200,
    ellipsis: true,
    formEditable: true,
    type: 'input',
    rules: [{ required: true, message: '请输入主题' }],
    formSort: 1,
  },
  {
    title: '快捷回复内容',
    dataIndex: 'content',
    align: 'center',
    ellipsis: true,
    formEditable: true,
    type: 'input',
    rules: [{ required: true, message: '请输入快捷回复内容' }],
  },
  {
    title: '排序',
    dataIndex: 'order',
    align: 'center',
    width: 50,
    type: 'inputNumber',
    formEditable: true,
    // rules: [{ required: true, message: '请输入排序' }],
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    align: 'center',
    width: 150,
    notShowForm: true,
    render: (text) => text?.replace('T', ' ') || '',
  },
];
