/* eslint-disable no-shadow */
import {
  PageHeader,
  Space,
  Card,
  Form,
  Input,
  Row,
  Col,
  Button,
  Table,
  Select,
  message,
  Modal,
} from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useAntdTable, useMemoizedFn } from 'ahooks';
import { EditableRow, EditableCell, DetailModal } from '@/components';
import {
  useDetailModal,
  useModalFormColumns,
  useTableColumns,
  useTableRowSelection,
} from '@/hooks';
import {
  getLabelList,
  add,
  edit,
  batchDelete,
  batchChangeStatus,
} from '@/services/business-management/config-management/labelConfig';
import { getPageUpdate } from '@/utils/commonUtils';

import { COLUMNS } from './config';

const { confirm } = Modal;

const components = {
  body: {
    row: EditableRow,
    cell: EditableCell,
  },
};

const getTableData = async ({ current, pageSize }, formData) =>
  // const result = await getLabelList({
  //   pageIndex: current,
  //   pageSize,
  //   params: formData.search
  //     ? { [formData.searchType]: formData.search }
  //     : undefined,
  // });

  // const data = result.data || {};

  // return {
  //   total: data.total || 0,
  //   list: data.data || [],
  // };
  ({
    total: 1,
    list: [{ domainId: 1 }],
  });
function LabelConfig() {
  const [form] = Form.useForm();
  const { tableProps, search, params, refresh, run } = useAntdTable(
    getTableData,
    {
      form,
      defaultParams: [{ current: 1, pageSize: 10 }],
    }
  );
  const { showDetail, selectRecord, handleShowDetail, handleCloseDetail } =
    useDetailModal();
  const { formColumns, formType, getModalFormColumns } =
    useModalFormColumns(COLUMNS);

  const handleSave = async (val) => {
    if (showDetail && formType === 'add') {
      const result = await add(val);
      if (result.code === 200) {
        message.success('添加成功');
        handleCloseDetail();
        run(
          {
            ...params[0],
            current: 1,
          },
          params[1]
        );
      }
    } else {
      const result = await edit(val);
      if (result.code === 200) {
        message.success('编辑成功');
        refresh();
        if (showDetail) {
          handleCloseDetail();
        }
      }
    }
  };

  const delLabel = useMemoizedFn(async (tagsIds) => {
    const result = await batchDelete({
      tagsIds,
    });
    if (result.code === 200) {
      const page = getPageUpdate(tableProps, tagsIds.length);
      run(
        {
          ...params[0],
          current: page,
        },
        params[1]
      );
      message.success('删除成功！');
    }
  });

  const { tableColumns, setTableColumns } = useTableColumns(COLUMNS, {
    onSave: handleSave,
    delTitle: (record) => `确定要删除${record.tagsName}吗?`,
    onDelete: (record) => delLabel([record.tagsId]),
    onDetail: (record) => {
      getModalFormColumns(record, 'detail');
      handleShowDetail(record);
    },
  });

  const handleShowAdd = () => {
    getModalFormColumns({}, 'add');
    handleShowDetail({ status: false });
  };

  return (
    <div className="label-config">
      <PageHeader ghost={false} backIcon={false} title="域名配置" />
      <div className="content-wrap">
        <Space direction="vertical" style={{ width: '100%' }}>
          <Card>
            <Row>
              <Col span={14}>
                <Button
                  type="primary"
                  style={{ marginBottom: '10px' }}
                  onClick={handleShowAdd}
                >
                  <PlusOutlined />
                  新建域名
                </Button>
              </Col>
            </Row>
            <Table
              className="edit-table"
              components={components}
              rowClassName={() => 'editable-row'}
              bordered
              columns={tableColumns}
              {...tableProps}
              pagination={{
                ...tableProps.pagination,
                showTotal: (total) => `共 ${total} 条`,
                // showSizeChanger: true,
                // showQuickJumper: true,
              }}
              rowKey="tagsId"
            />
          </Card>
        </Space>
      </div>
      <DetailModal
        title={formType === 'add' ? '新建域名' : '域名详情'}
        columns={formColumns}
        data={selectRecord}
        visible={showDetail}
        onClose={handleCloseDetail}
        onOk={handleSave}
        centered
      />
    </div>
  );
}

export default LabelConfig;
