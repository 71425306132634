import { useState } from 'react';

const useDetailModal = () => {
  const [showDetail, setShowDetail] = useState(false);
  const [selectRecord, setSelectRecord] = useState({});

  const handleShowDetail = (record) => {
    setShowDetail(true);
    setSelectRecord(record);
  };

  const handleCloseDetail = () => setShowDetail(false);

  return {
    showDetail,
    selectRecord,
    handleShowDetail,
    handleCloseDetail,
  };
};

export default useDetailModal;
