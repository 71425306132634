/* eslint-disable no-shadow */
import {
  PageHeader,
  Space,
  Card,
  Form,
  Input,
  Row,
  Col,
  Button,
  Table,
  Select,
  message,
  Modal,
} from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useAntdTable, useMemoizedFn } from 'ahooks';
import { EditableRow, EditableCell, DetailModal } from '@/components';
import {
  useDetailModal,
  useModalFormColumns,
  useTableColumns,
  useTableRowSelection,
} from '@/hooks';
import {
  getLabelList,
  add,
  edit,
  batchDelete,
  batchChangeStatus,
} from '@/services/business-management/config-management/labelConfig';
import { getPageUpdate } from '@/utils/commonUtils';

import { COLUMNS } from './config';

import './index.less';

const { Option } = Select;
const { confirm } = Modal;

const components = {
  body: {
    row: EditableRow,
    cell: EditableCell,
  },
};

const selectBefore = (
  <Form.Item name="searchType" noStyle>
    <Select>
      <Option value="tagsName">标签名</Option>
      <Option value="tagsId">标签ID</Option>
    </Select>
  </Form.Item>
);

const initialValues = {
  searchType: 'tagsName',
  search: '',
};

const getTableData = async ({ current, pageSize }, formData) => {
  const result = await getLabelList({
    pageIndex: current,
    pageSize,
    params: formData.search
      ? { [formData.searchType]: formData.search }
      : undefined,
  });

  const data = result.data || {};

  return {
    total: data.total || 0,
    list: data.data || [],
  };
};

function LabelConfig() {
  const [form] = Form.useForm();
  const { tableProps, search, params, refresh, run } = useAntdTable(
    getTableData,
    {
      form,
      defaultParams: [{ current: 1, pageSize: 10 }, initialValues],
    }
  );
  const { showDetail, selectRecord, handleShowDetail, handleCloseDetail } =
    useDetailModal();
  const { formColumns, formType, getModalFormColumns } =
    useModalFormColumns(COLUMNS);
  const [selectedRowKeys, setSelectedRowKeys] = useTableRowSelection({
    dataSource: tableProps.dataSource,
    keyName: 'tagsId',
  });

  const handleSave = async (val) => {
    if (showDetail && formType === 'add') {
      const result = await add(val);
      if (result.code === 200) {
        message.success('添加成功');
        handleCloseDetail();
        run(
          {
            ...params[0],
            current: 1,
          },
          params[1]
        );
      }
    } else {
      const result = await edit(val);
      if (result.code === 200) {
        message.success('编辑成功');
        refresh();
        if (showDetail) {
          handleCloseDetail();
        }
      }
    }
  };

  const delLabel = useMemoizedFn(async (tagsIds) => {
    const result = await batchDelete({
      tagsIds,
    });
    if (result.code === 200) {
      const page = getPageUpdate(tableProps, tagsIds.length);
      run(
        {
          ...params[0],
          current: page,
        },
        params[1]
      );
      message.success('删除成功！');
    }
  });

  const { tableColumns, setTableColumns } = useTableColumns(COLUMNS, {
    onSave: handleSave,
    delTitle: (record) => `确定要删除${record.tagsName}吗?`,
    onDelete: (record) => delLabel([record.tagsId]),
    onDetail: (record) => {
      getModalFormColumns(record, 'detail');
      handleShowDetail(record);
    },
  });

  const handleShowAdd = () => {
    getModalFormColumns({}, 'add');
    handleShowDetail({ status: false });
  };

  const handleBatchDel = () => {
    confirm({
      title: '确定要批量删除这些标签?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        delLabel(selectedRowKeys);
      },
      onCancel() {},
    });
  };

  const handleBatchChangeStatus = () => {
    confirm({
      title: '确定要批量启用这些标签?',
      icon: <ExclamationCircleOutlined />,
      async onOk() {
        const result = await batchChangeStatus({
          tagsIds: selectedRowKeys,
          status: true,
        });
        if (result.code === 200) {
          message.success('批量启用成功');
          refresh();
        }
      },
      onCancel() {},
    });
  };

  return (
    <div className="label-config">
      <PageHeader ghost={false} backIcon={false} title="标签配置" />
      <div className="content-wrap">
        <Space direction="vertical" style={{ width: '100%' }}>
          <Card>
            <Row>
              <Col span={14}>
                <Button
                  type="primary"
                  style={{ marginBottom: '10px' }}
                  onClick={handleShowAdd}
                >
                  <PlusOutlined />
                  新建标签
                </Button>
              </Col>
              <Col span={10}>
                <Form initialValues={initialValues} form={form}>
                  <Row>
                    <Col span={16}>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) =>
                          prevValues.searchType !== curValues.searchType
                        }
                      >
                        {({ getFieldValue }) => {
                          const type = getFieldValue('searchType');
                          return (
                            <Form.Item
                              label="关键搜索"
                              name="search"
                              style={{ marginBottom: 0 }}
                            >
                              <Input
                                placeholder={`输入${
                                  type === 'id' ? '标签id' : '标签名'
                                }`}
                                addonBefore={selectBefore}
                              />
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                    </Col>
                    <Col offset={2} span={6}>
                      <Form.Item style={{ marginBottom: 0 }}>
                        <Button type="primary" onClick={search.submit}>
                          确认查看
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
            <div className="batch-btn-wrap">
              <Space>
                <Button
                  type="primary"
                  size="small"
                  disabled={!selectedRowKeys.length}
                  onClick={handleBatchChangeStatus}
                >
                  启用
                </Button>
                <Button
                  type="primary"
                  danger
                  size="small"
                  disabled={!selectedRowKeys.length}
                  onClick={handleBatchDel}
                >
                  删除
                </Button>
              </Space>
            </div>
            <Table
              className="edit-table"
              components={components}
              rowClassName={() => 'editable-row'}
              bordered
              columns={tableColumns}
              rowSelection={{
                selectedRowKeys,
                onChange: (selectedRowKeys) =>
                  setSelectedRowKeys(selectedRowKeys),
              }}
              {...tableProps}
              pagination={{
                ...tableProps.pagination,
                showTotal: (total) => `共 ${total} 条`,
                // showSizeChanger: true,
                // showQuickJumper: true,
              }}
              rowKey="tagsId"
            />
          </Card>
        </Space>
      </div>
      <DetailModal
        title={formType === 'add' ? '新建标签' : '标签详情'}
        columns={formColumns}
        data={selectRecord}
        visible={showDetail}
        onClose={handleCloseDetail}
        onOk={handleSave}
        centered
      />
    </div>
  );
}

export default LabelConfig;
