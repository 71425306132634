import { Modal, Alert, Button, Tag, Form, Select } from 'antd';
import moment from 'moment';
import { test } from '@/services/customer-service/domainTest';
import './index.less';

const { Option } = Select;
const { confirm } = Modal;

function TestModal({ visible, onClose, title, data, refresh }) {
  const [form] = Form.useForm();
  const onFinish = async (values) => {
    console.log(values);
    const result = await test({
      domainDetectionId: data.domainDetectionId,
      ...values,
    });
    if (result.code === 200) {
      refresh();
      handleClose();
      let notAccess = true;
      let notDownload = true;
      if (
        (data.type === 1 && values.foreignAccessStatus) ||
        (data.type === 0 &&
          values.domesticAccessStatus &&
          values.foreignAccessStatus)
      ) {
        notAccess = false;
      }
      if (values.androidDownloadStatus && values.iosDownloadStatus) {
        notDownload = false;
      }
      confirm({
        title,
        icon: '',
        centered: true,
        zIndex: 1001,
        width: 500,
        className: 'domain-test-result',
        content: (
          <>
            {!notAccess && !notDownload ? (
              <div className="domain-health-status">该域名目前健康</div>
            ) : (
              <div className="domain-error-status">
                该域名目前
                {`${notAccess ? '【无法访问】' : ''} ${
                  values.androidDownloadStatus ? '' : '【安卓无法下载】'
                } ${values.iosDownloadStatus ? '' : '【ios无法下载】'}`}
                <br />
                请立即通知技术人员修复
              </div>
            )}
            <div className="domain-test-time">
              测试时间：{moment().format('YYYY-MM-DD HH:mm')}
            </div>
          </>
        ),
        onOk() {
          // console.log('OK');
        },
        onCancel() {
          // console.log('Cancel');
        },
      });
    }
  };

  const handleClose = () => {
    onClose();
    form.resetFields();
  };

  return (
    <Modal
      className="domain-test-modal"
      title={title}
      visible={visible}
      onCancel={handleClose}
      footer=""
    >
      <Alert
        message="请使用【国外网络】和【国内网络】分别访问该域名"
        type="warning"
      />
      <div className="test-btn">
        <Button type="primary" href={data.domain} target="_blank">
          点击访问该域名
        </Button>
      </div>
      <div className="tips">
        <Tag color="success" style={{ marginRight: 0 }}>
          **测试完毕，请仔细给出测试结果**
        </Tag>
      </div>
      <Form
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        form={form}
      >
        <Form.Item
          label="国内网络访问状态"
          name="domesticAccessStatus"
          rules={[{ required: true, message: '请选择国内网络访问状态' }]}
        >
          <Select>
            <Option value={1}>正常访问</Option>
            <Option value={0}>无法访问</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="国外网络访问状态"
          name="foreignAccessStatus"
          rules={[{ required: true, message: '请选择国外网络访问状态' }]}
        >
          <Select>
            <Option value={1}>正常访问</Option>
            <Option value={0}>无法访问</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="安卓下载状态"
          name="androidDownloadStatus"
          rules={[{ required: true, message: '请选择安卓下载状态' }]}
        >
          <Select>
            <Option value={1}>正常下载</Option>
            <Option value={0}>无法下载</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="ios下载状态（ios为打开商店链接视作正常）"
          name="iosDownloadStatus"
          rules={[{ required: true, message: '请选择ios下载状态' }]}
        >
          <Select>
            <Option value={1}>正常下载</Option>
            <Option value={0}>无法下载</Option>
          </Select>
        </Form.Item>

        <Form.Item style={{ textAlign: 'center' }}>
          <Button type="primary" htmlType="submit">
            确认测试结果
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default TestModal;
