import { PageHeader, Card, Space, Button, Table, Modal, message } from 'antd';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useAntdTable, useMemoizedFn } from 'ahooks';
import { COLUMNS } from './config';
import { EditableRow, EditableCell, DetailModal } from '@/components';
import {
  useDetailModal,
  useTableColumns,
  useModalFormColumns,
  useTableRowSelection,
} from '@/hooks';
import {
  getAdWordList,
  addAdWord,
  delAdWord,
} from '@/services/approval-management/adWordFilter';
import { getPageUpdate } from '@/utils/commonUtils';

const { confirm } = Modal;

const components = {
  body: {
    row: EditableRow,
    cell: EditableCell,
  },
};

const getTableData = async ({ current, pageSize }, formData) => {
  const result = await getAdWordList({
    pageIndex: current,
    pageSize,
  });
  const data = result.data || {};
  return {
    total: data.total || 0,
    list: data.data || [],
  };
};

function AdWordFilter() {
  const { tableProps, run, params } = useAntdTable(getTableData, {
    defaultParams: [{ current: 1, pageSize: 10 }],
  });
  const [selectedRowKeys, setSelectedRowKeys] = useTableRowSelection({
    dataSource: tableProps.dataSource,
    keyName: 'shieldingWordId',
  });
  const { showDetail, selectRecord, handleShowDetail, handleCloseDetail } =
    useDetailModal();
  const { formColumns, formType, getModalFormColumns } =
    useModalFormColumns(COLUMNS);

  const handleOk = async (value) => {
    let result;
    if (formType === 'add') {
      result = await addAdWord(value);
    }
    if (result.code === 200) {
      const page = { ...params[0] };
      if (formType === 'add') {
        page.current = 1;
      }
      run(page, params[1]);
      handleCloseDetail();
      message.success('操作成功！');
    }
  };

  const delWords = useMemoizedFn(async (shieldingWordIds) => {
    const result = await delAdWord({
      shieldingWordIds,
    });
    if (result.code === 200) {
      const page = getPageUpdate(tableProps, shieldingWordIds.length);
      run(
        {
          ...params[0],
          current: page,
        },
        params[1]
      );
      message.success('删除成功！');
    }
  });

  const { tableColumns, setTableColumns } = useTableColumns(COLUMNS, {
    noDetail: true,
    delTitle: (record) => `确定要删除该条过滤内容吗？?`,
    onDelete: (record) => delWords([record.shieldingWordId]),
  });

  const handleShowAdd = () => {
    getModalFormColumns({}, 'add');
    handleShowDetail({});
  };
  const handleBatchDel = () => {
    confirm({
      title: '确定要批量删除这些过滤内容?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        delWords(selectedRowKeys);
      },
      onCancel() {},
    });
  };

  return (
    <div className="long-video-approval">
      <PageHeader ghost={false} backIcon={false} title="广告词过滤" />
      <div className="content-wrap">
        <Card>
          <Button
            type="primary"
            style={{ marginBottom: '10px' }}
            onClick={handleShowAdd}
          >
            <PlusOutlined />
            新建广告词过滤
          </Button>
          <div className="batch-btn-wrap">
            <Space>
              <Button
                type="primary"
                size="small"
                disabled={!selectedRowKeys.length}
                danger
                onClick={handleBatchDel}
              >
                删除
              </Button>
            </Space>
          </div>
          <Table
            className="edit-table"
            components={components}
            rowClassName={() => 'editable-row'}
            bordered
            columns={tableColumns}
            scroll={{ x: 'max-content' }}
            rowSelection={{
              preserveSelectedRowKeys: false,
              selectedRowKeys,
              onChange: (selectedRowKeys) =>
                setSelectedRowKeys(selectedRowKeys),
            }}
            {...tableProps}
            pagination={{
              ...tableProps.pagination,
              showTotal: (total) => `共 ${total} 条`,
            }}
            rowKey="shieldingWordId"
          />
        </Card>
      </div>
      <DetailModal
        columns={formColumns}
        data={selectRecord}
        destroyOnClose
        visible={showDetail}
        onClose={handleCloseDetail}
        onOk={handleOk}
        modalType={formType}
        title="评论详情"
        centered
        labelWrap
      />
    </div>
  );
}

export default AdWordFilter;
