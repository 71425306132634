import { Space, Tag } from 'antd';
import { useState, useEffect } from 'react';

function LabelSelect({
  value = [],
  onChange,
  labelList = [],
  mode = 'multiple',
}) {
  const [finalList, setFinalList] = useState(
    typeof labelList !== 'function' ? labelList || [] : []
  );

  useEffect(async () => {
    if (typeof labelList === 'function') {
      const list = await labelList();
      setFinalList(list);
    }
  }, []);

  const handleSelect = ({ value: val, isAll }) => {
    if (mode === 'single') {
      onChange(val === value ? undefined : val);
      return;
    }
    let newValue = [...value];
    if (isAll) {
      newValue = [val];
    } else {
      const allIndex = newValue.indexOf('all');
      if (allIndex > -1) {
        newValue.splice(allIndex, 1);
      }
      const index = newValue.indexOf(val);
      if (index > -1) {
        newValue.splice(index, 1);
      } else {
        newValue.push(val);
      }
    }

    if (!newValue.length) {
      const allItem = finalList.find((item) => item.isAll);
      newValue = allItem ? [allItem.value] : [];
    }

    onChange(newValue);
  };

  return (
    <div className="label-wrap">
      <Space wrap>
        {finalList?.map((item) => (
          <div
            key={item.value}
            style={{ cursor: 'pointer' }}
            onClick={() => handleSelect(item)}
          >
            <Tag
              color={
                (
                  Array.isArray(value)
                    ? value.indexOf(item.value) > -1
                    : value === item.value
                )
                  ? 'processing'
                  : 'default'
              }
            >
              {item.label}
            </Tag>
          </div>
        ))}
      </Space>
    </div>
  );
}

export default LabelSelect;
