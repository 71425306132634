/* eslint-disable react/jsx-filename-extension */

import ResetPassword from '../../../reset-password';

const OS_MAP = {
  1: '安卓',
  2: '苹果',
};

const TYPE_MAP = {
  '-1': '游客',
  0: '临时会员',
};

const USER_SOURCE = {
  1: '官方装机',
  2: '好友邀请',
  3: '渠道邀请',
  4: '片商邀请',
};

export const columns = [
  {
    title: '昵称',
    dataIndex: 'nickname',
  },
  {
    title: '重置密码',
    dataIndex: 'inviteCode',
    format: (text, record) => <ResetPassword userId={record.userId} />,
  },
  {
    title: '邀请码',
    dataIndex: 'inviteCode',
  },
  {
    title: '用户身份', // 需要修改
    dataIndex: 'memberLevel',
    format: (text) => TYPE_MAP[text] || `V${text}`,
  },
  {
    title: '电话',
    dataIndex: 'phone',
    editable: true,
    type: 'input',
    rules: [{ required: true, message: '请输入电话号码' }],
  },
  {
    title: '邀请人数',
    dataIndex: 'inviteNumber',
  },
  {
    title: '每日最大观影次数',
    dataIndex: 'dayWatchNumber',
  },
  {
    title: '今日剩余观影次数',
    dataIndex: 'dayWatchNumberLefted',
  },

  {
    title: 'vip剩余天数',
    dataIndex: 'memberLeftDays',
  },
  {
    title: '临时VIP到期时间',
    dataIndex: 'v0Time',
    format: (text, record) => {
      const data = record.memberInfo?.find((item) => item.level === 0);
      return data?.finalDate || '无';
    },
  },
  {
    title: 'VIP1到期时间',
    dataIndex: 'v1Time',
    format: (text, record) => {
      const data = record.memberInfo?.find((item) => item.level === 1);
      return data?.finalDate || '无';
    },
  },
  {
    title: 'VIP2到期时间',
    dataIndex: 'v2Time',
    format: (text, record) => {
      const data = record.memberInfo?.find((item) => item.level === 2);
      return data?.finalDate || '无';
    },
  },
  {
    title: 'VIP3到期时间',
    dataIndex: 'v3Time',
    format: (text, record) => {
      const data = record.memberInfo?.find((item) => item.level === 3);
      return data?.finalDate || '无';
    },
  },
  {
    title: '金币余额',
    dataIndex: 'coin',
  },
  {
    title: '钱包余额',
    dataIndex: 'money',
  },
  {
    title: '已提现金额',
    dataIndex: 'withdraw',
  },
  {
    title: '装机时间',
    dataIndex: 'installDate',
  },
  {
    title: '装机来源',
    dataIndex: 'joinSource',
    format: (text) => USER_SOURCE[text],
  },
  {
    title: '运行系统',
    dataIndex: 'os',
    format: (text) => OS_MAP[text],
  },
  {
    title: '上次登录时间',
    dataIndex: 'lastLoginDate',
  },

  {
    title: '签名',
    dataIndex: 'sign',
  },
  {
    title: '是否禁用',
    dataIndex: 'disable',
    format: (text) => (text ? '是' : '否'),
  },
];

export const orderColumns = [
  // {
  //   title: '订单号',
  //   dataIndex: 'orderId',
  //   type: 'input',
  //   editable: true,
  //   rules: [{ required: true, message: '请输入订单号' }],
  // },
  {
    title: '操作码',
    dataIndex: 'code',
    type: 'input',
    editable: true,
    rules: [{ required: true, message: '请输入操作码' }],
    placeholder: '输入补单码（订单金额必须与补单码金额相匹配）',
  },
];
