import { post } from '@/utils/http';

export const getCompanyList = (params) =>
  post({
    url: '/manage/factoryConfig/get/page',
    data: params,
  });
export const add = (params) =>
  post({
    url: '/manage/factoryConfig/insert',
    data: params,
  });
export const edit = (params) =>
  post({
    url: '/manage/factoryConfig/update',
    data: params,
  });
export const del = (params) =>
  post({
    url: '/manage/factoryConfig/delete',
    data: params,
  });
