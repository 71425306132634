import { Form, Switch, Modal, Button, Popconfirm } from 'antd';
import { useEffect, useRef } from 'react';

function CutDetailModal({ visible, onClose, data, onDel, onOk }) {
  const [form] = Form.useForm();
  const init = useRef(false);

  const handleOk = async () => {
    const values = await form.validateFields();
    if (values.enable !== data.enable) {
      onOk({ ...data, ...values });
    }
    onClose();
  };

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(data);
    } else if (init.current) {
      form.resetFields();
      const ele = document.querySelector('.ant-modal-body');
      ele.scrollTo(0, 0);
    }
    init.current = true;
  }, [visible]);

  return (
    <Modal
      title="渠道砍量任务详情"
      visible={visible}
      className="add-cut-modal"
      onCancel={onClose}
      width={600}
      bodyStyle={{
        maxHeight: '500px',
        overflow: 'auto',
      }}
      footer={[
        <Button key="cancel" onClick={onClose}>
          取消
        </Button>,
        <Popconfirm
          key="del"
          title="确定要删除该条任务吗？"
          okText="是"
          cancelText="否"
          onConfirm={onDel}
        >
          <Button type="primary" danger>
            删除
          </Button>
        </Popconfirm>,
        <Button key="deploy" type="primary" onClick={handleOk}>
          发布任务
        </Button>,
      ]}
    >
      <Form layout="vertical" autoComplete="off" form={form}>
        <div className="tips" style={{ padding: 0 }}>
          *砍量将只作用到渠道邀请的用户，如若要对普通用户进行砍量，请在配置管理-砍量配置中进行操作
        </div>
        <Form.Item label="渠道id">
          <div>
            {data.channelId}（渠道名：{data.channelName}）
          </div>
        </Form.Item>
        <div className="tips-title">是否启用</div>
        <div className="tips">
          *请仔细核对任务参数，特别是历史解绑任务，一旦启用并发布，瞬间生效！
        </div>
        <Form.Item
          label=""
          name="enable"
          valuePropName="checked"
          rules={[{ required: true, message: '请选择是否启用!' }]}
        >
          <Switch />
        </Form.Item>
        <Form.Item label="砍量类型">
          <div>
            {data.recedeType === 1
              ? '历史解绑（对已经被邀请进入app的用户进行解绑）'
              : '新增解绑（对未来被该渠道邀请的用户进行解绑）'}
          </div>
        </Form.Item>
        <Form.Item label="砍量对象">
          <div>
            对{data.startTime}
            {data.endTime ? `-${data.endTime}日期间` : '之后'}新增的用户进行砍量
          </div>
          {/* <div>对2019.12.11之后新增的用户进行砍量（结束时间选择永久生效的这样显示） </div> */}
        </Form.Item>
        <Form.Item label="砍量力度">
          {data.recedeType === 1 ? (
            <div>
              <span style={{ color: 'red' }}>{data.percent}%</span>
              的被邀请用户，将会解绑该渠道
            </div>
          ) : (
            <div>
              被邀请用户<span style={{ color: 'red' }}>{data.percent}%</span>
              概率无法绑定该渠道
            </div>
          )}
        </Form.Item>
        <Form.Item label="砍量成果">
          <div>
            <span style={{ color: 'red' }}>{data.releaseNumber}</span>
            名用户已被解绑，已偷掉充值
            <span style={{ color: 'red' }}>{data.amount}</span>元
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CutDetailModal;
