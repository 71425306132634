/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
import {
  PageHeader,
  Card,
  Space,
  Form,
  Row,
  Col,
  Select,
  Input,
  Button,
  Table,
  Modal,
  message,
  Popconfirm,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useAntdTable, useMemoizedFn } from 'ahooks';
import {
  FILTER1_OPTIONS,
  FILTER2_OPTIONS,
  SORT1_OPTIONS,
  COLUMNS,
} from './config';
import {
  useDetailModal,
  useTableColumns,
  useTableRowSelection,
  useModalFormColumns,
} from '@/hooks';
import { EditableRow, EditableCell, ApprovalDetailModal } from '@/components';
import { SEARCH_TYPE_LIST, selectBefore } from '../commonConfig';
import {
  getList,
  edit,
  batchChangeStatus,
  batchDel,
} from '@/services/business-management/works-management/stickerManagement';
import { getOrderParams, getPageUpdate } from '@/utils/commonUtils';

const { Option } = Select;
const { confirm } = Modal;

const components = {
  body: {
    row: EditableRow,
    cell: EditableCell,
  },
};

const initialValues = {
  filter1: '',
  filter2: '',
  sort: '',
  searchType: 'factoryName',
  search: '',
};

const getTableData = async ({ current, pageSize }, formData) => {
  const { orders, search } = getOrderParams(formData);

  const result = await getList({
    pageIndex: current,
    pageSize,
    orders,
    params: search,
  });

  const data = result.data || {};

  const list = data.data || [];
  list.forEach((item) => {
    item.onShelf = item.status === '3';
  });

  return {
    total: data.total || 0,
    list,
  };
};

function ShortVideoManagement() {
  const [form] = Form.useForm();
  const { tableProps, search, params, run, refresh } = useAntdTable(
    getTableData,
    {
      form,
      defaultParams: [{ current: 1, pageSize: 10 }, initialValues],
    }
  );
  const { showDetail, selectRecord, handleShowDetail, handleCloseDetail } =
    useDetailModal();
  const { formColumns, formType, setFormColumns, getModalFormColumns } =
    useModalFormColumns(COLUMNS);
  const [selectedRowKeys, setSelectedRowKeys] = useTableRowSelection({
    dataSource: tableProps.dataSource,
    keyName: 'movieId',
  });

  const handleSave = async (val) => {
    val = {
      movieId: val.movieId,
      videoAttribute: val.videoAttribute,
      moviePrice: val.videoAttribute === 2 ? val.moviePrice : undefined,
      freeDays: val.videoAttribute === 4 ? val.freeDays : undefined,
      watchs: val.watchs,
      thumbs: val.thumbs,
      status: val.onShelf ? '3' : '5', // 3上架 5下架
    };
    const result = await edit(val);
    if (result.code === 200) {
      message.success('编辑成功');
      refresh();
      if (showDetail) {
        handleCloseDetail();
      }
    }
  };

  const delMovie = useMemoizedFn(async (movieIds) => {
    const result = await batchDel({
      movieIds,
    });
    if (result.code === 200) {
      const page = getPageUpdate(tableProps, movieIds.length);
      run(
        {
          ...params[0],
          current: page,
        },
        params[1]
      );
      message.success('删除成功！');
      if (showDetail) {
        handleCloseDetail();
      }
    }
  });

  const handleDetail = (record) => {
    getModalFormColumns(record, 'detail');
    handleShowDetail(record);
  };

  const { tableColumns, setTableColumns } = useTableColumns(COLUMNS, {
    onSave: handleSave,
    controlColumns: {
      title: '操作',
      key: 'action',
      width: 120,
      align: 'center',
      fixed: 'right',
      render: (text, record) => (
        <>
          <Button type="link" size="small" onClick={() => handleDetail(record)}>
            编辑
          </Button>
          <Popconfirm
            key="del"
            title="确定要删除该条影片吗？"
            okText="是"
            cancelText="否"
            onConfirm={() => delMovie([record.movieId])}
          >
            <Button type="link" size="small" danger>
              删除
            </Button>
          </Popconfirm>
        </>
      ),
    },
  });

  const handleBatchDel = () => {
    confirm({
      title: '确定要批量删除这些过滤内容?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        delMovie(selectedRowKeys);
      },
      onCancel() {},
    });
  };

  const batchChange = async (movieIds, status) => {
    const result = await batchChangeStatus({
      movieIds,
      status,
    });
    if (result.code === 200) {
      message.success(`批量${status === '3' ? '上架' : '下架'}成功`);
      refresh();
    }
  };

  const handleBatchDown = () => {
    confirm({
      title: '确定要批量下架这些影片?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        batchChange(selectedRowKeys, '5');
      },
      onCancel() {},
    });
  };

  const handleBatchUp = () => {
    confirm({
      title: '确定要批量上架这些影片?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        batchChange(selectedRowKeys, '3');
      },
      onCancel() {},
    });
  };

  return (
    <div className="user-management aisle-management long-video-approval">
      <PageHeader ghost={false} backIcon={false} title="短视频管理" />
      <div className="content-wrap">
        <Space direction="vertical" style={{ width: '100%' }}>
          <Card>
            <Form initialValues={initialValues} form={form}>
              <Row>
                <Col span={7}>
                  <Form.Item
                    label="筛选1"
                    name="filter1"
                    style={{ marginBottom: '10px' }}
                  >
                    <Select>
                      {FILTER1_OPTIONS.map((item) => (
                        <Option value={item.value} key={item.value}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={7} offset={1}>
                  <Form.Item
                    label="筛选2"
                    name="filter2"
                    style={{ marginBottom: 0 }}
                  >
                    <Select>
                      {FILTER2_OPTIONS.map((item) => (
                        <Option value={item.value} key={item.value}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={7} offset={1}>
                  <Form.Item
                    label="排序1"
                    name="sort"
                    style={{ marginBottom: 0 }}
                  >
                    <Select>
                      {SORT1_OPTIONS.map((item) => (
                        <Option value={item.value} key={item.value}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.searchType !== curValues.searchType
                    }
                  >
                    {({ getFieldValue }) => {
                      const type = getFieldValue('searchType');
                      const item = SEARCH_TYPE_LIST.find(
                        (item) => item.value === type
                      );
                      return (
                        <Form.Item
                          label="关键搜索"
                          name="search"
                          style={{ marginBottom: 0, marginLeft: '-19px' }}
                        >
                          <Input
                            placeholder={`输入${item.label}`}
                            addonBefore={selectBefore}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={2} offset={1}>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Button type="primary" onClick={search.submit}>
                      确认查看
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
          <Card>
            <div className="table-title">查询结果</div>
            <div className="batch-btn-wrap">
              <Space>
                <Button
                  type="primary"
                  size="small"
                  disabled={!selectedRowKeys.length}
                  onClick={() => handleBatchUp(selectedRowKeys)}
                >
                  上架
                </Button>
                <Button
                  type="primary"
                  size="small"
                  disabled={!selectedRowKeys.length}
                  onClick={() => handleBatchDown(selectedRowKeys)}
                >
                  下架
                </Button>
                <Button
                  type="primary"
                  size="small"
                  danger
                  disabled={!selectedRowKeys.length}
                  onClick={() => handleBatchDel(selectedRowKeys)}
                >
                  删除
                </Button>
              </Space>
            </div>
            <Table
              className="edit-table"
              components={components}
              rowClassName={() => 'editable-row'}
              rowSelection={{
                selectedRowKeys,
                onChange: (selectedRowKeys) =>
                  setSelectedRowKeys(selectedRowKeys),
              }}
              bordered
              columns={tableColumns}
              scroll={{ x: 'max-content' }}
              {...tableProps}
              pagination={{
                ...tableProps.pagination,
                showTotal: (total) => `共 ${total} 条`,
                // showSizeChanger: true,
                // showQuickJumper: true,
              }}
              rowKey="movieId"
            />
          </Card>
        </Space>
      </div>
      <ApprovalDetailModal
        columns={formColumns}
        visible={showDetail}
        onClose={handleCloseDetail}
        data={selectRecord}
        onDel={() => delMovie([selectRecord.movieId])}
        onPass={handleSave}
        centered
        type="sticker"
        management
      />
    </div>
  );
}

export default ShortVideoManagement;
