import { post } from '@/utils/http';

export const getList = (params) =>
  post({
    url: '/manage/customerService/fastReply/get/page',
    data: params,
  });
export const add = (params) =>
  post({
    url: '/manage/customerService/fastReply/insert',
    data: params,
  });
export const edit = (params) =>
  post({
    url: '/manage/customerService/fastReply/update',
    data: params,
  });
export const del = (params) =>
  post({
    url: '/manage/customerService/fastReply/delete',
    data: params,
  });
export const move = (params) =>
  post({
    url: '/manage/customerService/fastReply/order/exchange',
    data: params,
  });
