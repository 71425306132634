import {
  PageHeader,
  Card,
  Space,
  Form,
  Row,
  Col,
  Select,
  Input,
  Button,
  Table,
  message,
} from 'antd';
import { useAntdTable, useMemoizedFn } from 'ahooks';
import { TableOperator } from './components';
import {
  FILTER1_OPTIONS,
  FILTER2_OPTIONS,
  SORT_OPTIONS,
  COLUMNS,
} from './config';
import { EditableRow, EditableCell, DetailModal } from '@/components';
import { useDetailModal, useTableColumns, useModalFormColumns } from '@/hooks';
import {
  getUserList,
  deleteOrDisable,
  addVipOrCoinOrTimes,
  moveAccountCode,
} from './service';
import { getPageUpdate } from '@/utils/commonUtils';
import './index.less';

const { Option } = Select;

const components = {
  body: {
    row: EditableRow,
    cell: EditableCell,
  },
};

const initialValues = {
  source: 0,
  see: 0,
  order: 0,
  search: 0,
  wd: '',
};

const selectBefore = (
  <Form.Item name="search" noStyle>
    <Select>
      <Option value={0}>邀请码</Option>
      <Option value={1}>手机号</Option>
    </Select>
  </Form.Item>
);

function UserManagement() {
  const [form] = Form.useForm();
  const { tableProps, search, params, run, refresh } = useAntdTable(
    getUserList,
    {
      form,
      defaultParams: [{ current: 1, pageSize: 10 }, initialValues],
    }
  );
  const { showDetail, selectRecord, handleShowDetail, handleCloseDetail } =
    useDetailModal();

  const handleDelete = useMemoizedFn(async (user) => {
    const result = await deleteOrDisable(user.userId, 'delete');
    if (result.code === 200) {
      message.success('删除成功');
      const page = getPageUpdate(tableProps, 1);
      run(
        {
          ...params[0],
          current: page,
        },
        params[1]
      );
    }
  });

  const handleAddTimes = async (formData, record) => {
    const KEY_MAP = {
      today: 'addWatchDays',
      every: 'addWatchAllDays',
    };
    const result = await addVipOrCoinOrTimes({
      userId: record.userId,
      [KEY_MAP[formData.type]]: formData.times,
    });
    if (result.code === 200) {
      message.success('次数添加成功');
      refresh();
    }
  };
  const handleAddVip = async (formData, record) => {
    const result = await addVipOrCoinOrTimes({
      userId: record.userId,
      addVipDays: formData.days,
    });
    if (result.code === 200) {
      message.success('会员天数添加成功');
      refresh();
    }
  };
  const handleAddCoin = async (formData, record) => {
    const result = await addVipOrCoinOrTimes({
      userId: record.userId,
      addCoin: formData.coin,
    });
    if (result.code === 200) {
      message.success('金币添加成功');
      refresh();
    }
  };

  const handleMoveAccount = async (params) => {
    const result = await moveAccountCode(params);
    if (result.code === 200) {
      message.success('账号迁移成功');
      refresh();
    }
  };

  const handleSave = async (val, dataIndex) => {
    // maxWatchDays: 100, // 每日最大观影次数
    // todayLeftWatchDays: 100, // 今日剩余观影次数
    // vipFinalDate: '2022-10-21', // VIP到期时间，格式yyyy-MM-dd
    let result;
    if (dataIndex === 'disable') {
      result = await deleteOrDisable(val.userId, 'disable', val.disable);
    } else {
      result = await addVipOrCoinOrTimes({
        userId: val.userId,
        maxWatchDays: val.dayWatchNumber,
        todayLeftWatchDays: val.dayWatchNumberLefted,
        vipFinalDate: val.memberFinalDate,
      });
    }

    if (result.code === 200) {
      message.success('操作成功');
      refresh();
      if (showDetail) {
        handleCloseDetail();
      }
    }
  };

  const { tableColumns, setTableColumns } = useTableColumns(COLUMNS, {
    onSave: handleSave,
    controlColumns: {
      title: '操作',
      dataIndex: 'operation',
      fixed: 'right',
      align: 'center',
      width: 220,
      render: (_, record) => (
        <TableOperator
          record={record}
          onShowDetail={handleShowDetail}
          onDelete={handleDelete}
          onAddTimes={handleAddTimes}
          onAddVip={handleAddVip}
          onAddCoin={handleAddCoin}
          onMoveAccount={handleMoveAccount}
        />
      ),
    },
  });

  return (
    <div className="user-management">
      <PageHeader ghost={false} backIcon={false} title="用户管理" />
      <div className="content-wrap">
        <Space direction="vertical" style={{ width: '100%' }}>
          <Card>
            <Form initialValues={initialValues} form={form}>
              <Row>
                <Col span={6} offset={1}>
                  <Form.Item
                    label="筛选1"
                    name="source"
                    style={{ marginBottom: '8px' }}
                  >
                    <Select>
                      {FILTER1_OPTIONS.map((item) => (
                        <Option value={item.value} key={item.value}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6} offset={1}>
                  <Form.Item
                    label="筛选2"
                    name="see"
                    style={{ marginBottom: '8px' }}
                  >
                    <Select>
                      {FILTER2_OPTIONS.map((item) => (
                        <Option value={item.value} key={item.value}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={6} offset={1}>
                  <Form.Item
                    label="排序方式"
                    name="order"
                    style={{ marginBottom: '8px' }}
                  >
                    <Select>
                      {SORT_OPTIONS.map((item) => (
                        <Option value={item.value} key={item.value}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6} offset={1}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.searchType !== curValues.searchType
                    }
                  >
                    {({ getFieldValue }) => {
                      const type = getFieldValue('searchType');
                      return (
                        <Form.Item
                          label="关键搜索"
                          name="wd"
                          style={{ marginBottom: 0 }}
                        >
                          <Input
                            placeholder={`输入${
                              type === 'code' ? '邀请码' : '手机号'
                            }`}
                            addonBefore={selectBefore}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={2} offset={1}>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Button type="primary" onClick={search.submit}>
                      确认查看
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
          <Card>
            <div className="table-title">查询结果</div>
            <Table
              className="edit-table"
              components={components}
              rowClassName={() => 'editable-row'}
              bordered
              columns={tableColumns}
              scroll={{ x: 'max-content' }}
              {...tableProps}
              pagination={{
                ...tableProps.pagination,
                showTotal: (total) => `共 ${total} 条`,
                // showSizeChanger: true,
                // showQuickJumper: true,
              }}
              rowKey="userId"
            />
          </Card>
        </Space>
      </div>
      <DetailModal
        width={850}
        columns={COLUMNS}
        data={selectRecord}
        destroyOnClose
        visible={showDetail}
        onClose={handleCloseDetail}
        onOk={handleSave}
        title="用户详情"
        modalType="detail"
        inline
      />
    </div>
  );
}

export default UserManagement;
