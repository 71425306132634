import { Button, Popconfirm } from 'antd';
import { AddTimesOperator, AddVipOperator } from '@/components';
import MoveAccount from './move-account';

function TableOperator({
  record,
  onAddTimes,
  onAddVip,
  onAddCoin,
  onShowDetail,
  onDelete,
  onMoveAccount,
}) {
  // const confirm = () => {
  //   console.log(record);
  //   updateTable('delete');
  // };

  return (
    <>
      <MoveAccount record={record} onMove={onMoveAccount} />
      <AddTimesOperator record={record} onAdd={onAddTimes} />
      <AddVipOperator record={record} onAdd={onAddVip} type="vip" />
      <AddVipOperator record={record} onAdd={onAddCoin} type="coin" />
      <Button size="small" type="link" onClick={() => onShowDetail(record)}>
        查看
      </Button>
      <Popconfirm
        title={`确定要删除用户${record.username}吗?`}
        onConfirm={() => onDelete(record)}
        // onCancel={cancel}
        okText="确定"
        cancelText="取消"
      >
        <Button size="small" type="text" danger>
          删除
        </Button>
      </Popconfirm>
    </>
  );
}

export default TableOperator;
