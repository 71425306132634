import { Card, Radio, DatePicker, Button, Space, Table } from 'antd';
import { useState } from 'react';
import moment from 'moment';
import { useRequest } from 'ahooks';
import { columns } from './columns-config';
import { getOverviewData } from '@/services/data';

const getData = async (deviceType, timeRage) => {
  const result = await getOverviewData({
    client: deviceType,
    dateFrom: timeRage[0]?.format('YYYY-MM-DD'),
    dateTo: timeRage[1]?.format('YYYY-MM-DD'),
  });

  return result.data ? [result.data] : [];
};

const historyMonthStart = moment().startOf('month').subtract(1, 'months');
const historyMonthEnd = moment().endOf('month').subtract(1, 'months');

const historyMoment = [historyMonthStart, historyMonthEnd];

function DataOverview() {
  const [time, setTime] = useState(historyMonthStart);
  const [deviceType, setDeviceType] = useState(0);
  const {
    data = [],
    run,
    loading,
    refresh,
    params,
  } = useRequest(getData, {
    defaultParams: [deviceType, historyMoment],
  });

  const disabledDate = (current) =>
    current && current >= moment().startOf('month');
  const handleChangeDate = (date, dateStrings) => {
    setTime(date);
  };
  const handleChangeDevice = (e) => {
    setDeviceType(e.target.value);
    run(e.target.value, params[1]);
  };
  const handleGetData = () => {
    const startTime = time.clone().startOf('month');
    const endTime = time.clone().endOf('month');

    run(deviceType, [startTime, endTime]);
  };

  return (
    <Card
      title="数据概览"
      extra={
        <Space>
          <DatePicker
            disabledDate={disabledDate}
            onChange={handleChangeDate}
            picker="month"
            value={time}
          />
          <Button type="primary" onClick={handleGetData}>
            点击查看
          </Button>
          <Button type="primary" onClick={refresh}>
            更新数据
          </Button>
        </Space>
      }
    >
      <div className="radio-wrap">
        <Radio.Group value={deviceType} onChange={handleChangeDevice}>
          <Radio.Button value={0}>所有端</Radio.Button>
          <Radio.Button value={1}>安卓端</Radio.Button>
          <Radio.Button value={2}>IOS端</Radio.Button>
        </Radio.Group>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey="officialVisit"
        loading={loading}
      />
    </Card>
  );
}

export default DataOverview;
