import { Outlet, useOutletContext } from 'react-router-dom';
import { Badge, Button } from 'antd';
import { useDetailModal } from '@/hooks';
import { CustomerServiceChat } from './components';

import './index.less';

function CustomerServiceLayout() {
  const { unDealCount, refreshCustomService } = useOutletContext();

  const {
    showDetail: showChat,
    selectRecord: selectContact,
    handleShowDetail: handleShowChat,
    handleCloseDetail: handleCloseChat,
  } = useDetailModal();

  const openChat = (record) => {
    handleShowChat(record);
  };

  return (
    <>
      <div className="customer-service-chat" onClick={() => handleShowChat()}>
        <span className="title">客服消息</span>
        <Badge count={unDealCount}>
          <span className="no-read">未处理消息</span>
        </Badge>
        <Button type="primary" shape="round" className="read-all" size="small">
          查看全部
        </Button>
      </div>
      <Outlet
        context={{
          openChat,
          unDealCount,
        }}
      />
      {showChat && (
        <CustomerServiceChat
          // visible={showChat}
          onClose={() => {
            handleCloseChat();
            refreshCustomService();
          }}
          selectData={selectContact}
        />
      )}
    </>
  );
}

export default CustomerServiceLayout;
