import {
  getUserData,
  delOrDisabledUser,
  addTimes,
  moveAccount,
} from '@/services/business-management/userManagement';

export const getUserList = async ({ current, pageSize }, formData) => {
  const result = await getUserData({
    page: current,
    size: pageSize,
    ...formData,
  });

  const data = result.data || {};
  return {
    total: data.total || 0,
    list: data.list || [],
  };
};

export const deleteOrDisable = async (userId, type, disable) => {
  const TYPE_MAP = {
    delete: 0,
    disable: disable ? 1 : 2,
  };
  const result = await delOrDisabledUser({
    userId,
    handle: TYPE_MAP[type],
  });

  return result;
};

export const addVipOrCoinOrTimes = async (params) => {
  // "addVipDays": 1, //增加会员天数
  // "addWatchDays": 1, //增加当日观看天数
  // "addWatchAllDays": 1, //增加每日观看天数
  // "addCoin": 1 //增加金币
  // maxWatchDays: 100, // 每日最大观影次数
  // todayLeftWatchDays: 100, // 今日剩余观影次数
  // vipFinalDate: '2022-10-21', // VIP到期时间，格式yyyy-MM-dd
  const result = await addTimes(params);
  return result;
};
export const moveAccountCode = async (params) => {
  const result = await moveAccount(params);
  return result;
};
