import { Popover, Button, Form, InputNumber } from 'antd';
import { useState } from 'react';

const TYPE_MAP = {
  vip: {
    name: 'days',
    title: '奖励天数',
    poperTitle: '加VIP',
  },
  coin: {
    name: 'coin',
    title: '奖励金币数',
    poperTitle: '加金币',
  },
  times: {
    name: 'times',
    title: '次数',
    poperTitle: '加次数',
  },
};

function AddVipOperator({ onAdd, record, type }) {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const handleFinish = (values) => {
    onAdd(values, record);
    setVisible(false);
  };
  const handleVisibleChnage = (val) => {
    setVisible(val);
    if (val) {
      form.resetFields();
    }
  };

  const vipPopoverForm = (
    <Form onFinish={handleFinish} form={form}>
      <Form.Item
        name={TYPE_MAP[type].name}
        rules={[
          {
            required: true,
            message: `${TYPE_MAP[type].title}是必填的`,
          },
        ]}
      >
        <InputNumber
          placeholder={`输入${TYPE_MAP[type].title}`}
          style={{ width: '100%' }}
        />
      </Form.Item>
      <Form.Item style={{ textAlign: 'center', marginBottom: 0 }}>
        <Button type="primary" htmlType="submit">
          确定
        </Button>
      </Form.Item>
    </Form>
  );

  return (
    <Popover
      content={vipPopoverForm}
      title={TYPE_MAP[type].poperTitle}
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChnage}
    >
      <Button size="small" type="link">
        {TYPE_MAP[type].poperTitle}
      </Button>
    </Popover>
  );
}

export default AddVipOperator;
