import {
  PageHeader,
  Card,
  Space,
  Form,
  Row,
  Col,
  Select,
  Button,
  Table,
  Badge,
  message,
} from 'antd';
import { useState, useEffect } from 'react';
import ClipboardJS from 'clipboard';
import { useAntdTable, useMemoizedFn } from 'ahooks';
import {
  FILTER1_OPTIONS,
  FILTER2_OPTIONS,
  FILTER3_OPTIONS,
  COLUMNS,
  DOMAIN_TYPE_MAP,
} from './config';
import { EditableRow, EditableCell } from '@/components';
import { usePagination, useTableColumns, useDetailModal } from '@/hooks';
import { TestModal } from './components';
import { getList, test } from '@/services/customer-service/domainTest';
import { getOrderParams, getPageUpdate } from '@/utils/commonUtils';

const { Option } = Select;

const components = {
  body: {
    row: EditableRow,
    cell: EditableCell,
  },
};

const initialValues = {
  filter1: '',
  filter2: '',
  filter3: '',
};

const getTableData = async ({ current, pageSize }, formData) => {
  const { search } = getOrderParams(formData);

  const result = await getList({
    pageIndex: current,
    pageSize,
    params: search,
  });

  const data = result.data || {};

  return {
    total: data.total || 0,
    list: data.data || [],
  };
};

function DomainTest() {
  const [form] = Form.useForm();
  const { tableProps, search, params, run, refresh } = useAntdTable(
    getTableData,
    {
      form,
      defaultParams: [{ current: 1, pageSize: 10 }, initialValues],
    }
  );
  const {
    showDetail: showDomainTest,
    selectRecord: selectTestRecord,
    handleShowDetail: handleShowDomainTest,
    handleCloseDetail: handleCloseDomainTest,
  } = useDetailModal();

  const { tableColumns, setTableColumns } = useTableColumns(COLUMNS, {
    controlColumns: {
      title: '操作',
      key: 'action',
      width: 80,
      align: 'center',
      fixed: 'right',
      render: (text, record) => (
        <>
          <Button
            type="link"
            size="small"
            onClick={() => handleShowDomainTest(record)}
          >
            测试域名
          </Button>
          <Button
            type="link"
            size="small"
            id={`copy-${record.domainDetectionId}`}
          >
            复制域名
          </Button>
        </>
      ),
    },
  });

  useEffect(() => {
    const clipboardArr = [];
    const dataSource = tableProps.dataSource || [];
    dataSource.forEach((item) => {
      const clipboard = new ClipboardJS(`#copy-${item.domainDetectionId}`, {
        text(trigger) {
          return item.domain;
        },
      });

      clipboard.on('success', (e) => {
        message.success('复制成功！');
      });

      clipboard.on('error', (e) => {
        message.error('复制失败！');
      });
      clipboardArr.push(clipboard);
    });
    return () => {
      clipboardArr.forEach((clipboard) => {
        clipboard.destroy();
      });
    };
  }, [tableProps.dataSource]);

  return (
    <div className="user-management work-order">
      <PageHeader ghost={false} backIcon={false} title="域名检测" />
      <div className="content-wrap">
        <Space direction="vertical" style={{ width: '100%' }}>
          <Card>
            <Form initialValues={initialValues} form={form}>
              <Row>
                <Col span={6} offset={1}>
                  <Form.Item
                    label="筛选1"
                    name="filter1"
                    style={{ marginBottom: 0 }}
                  >
                    <Select>
                      {FILTER1_OPTIONS.map((item) => (
                        <Option value={item.value} key={item.value}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6} offset={1}>
                  <Form.Item
                    label="筛选2"
                    name="filter2"
                    style={{ marginBottom: 0 }}
                  >
                    <Select>
                      {FILTER2_OPTIONS.map((item) => (
                        <Option value={item.value} key={item.value}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6} offset={1}>
                  <Form.Item
                    label="筛选3"
                    name="filter3"
                    style={{ marginBottom: 0 }}
                  >
                    <Select>
                      {FILTER3_OPTIONS.map((item) => (
                        <Option value={item.value} key={item.value}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={2} offset={1}>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Button type="primary" onClick={search.submit}>
                      搜索
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
          <Card>
            <div className="table-title">查询结果</div>
            <Table
              className="edit-table"
              components={components}
              rowClassName={() => 'editable-row'}
              bordered
              columns={tableColumns}
              // scroll={{ x: 'max-content' }}
              {...tableProps}
              pagination={{
                ...tableProps.pagination,
                showTotal: (total) => `共 ${total} 条`,
                // showSizeChanger: true,
                // showQuickJumper: true,
              }}
              rowKey="domainDetectionId"
            />
          </Card>
        </Space>
      </div>
      <TestModal
        visible={showDomainTest}
        data={selectTestRecord}
        onClose={handleCloseDomainTest}
        title={`测试${DOMAIN_TYPE_MAP[selectTestRecord.type]}:${
          selectTestRecord.domain
        }`}
        refresh={refresh}
      />
    </div>
  );
}

export default DomainTest;
