/* eslint-disable react/jsx-filename-extension */
import { Tag } from 'antd';

export const FILTER1_OPTIONS = [
  { value: '', name: '域名全部状态' },
  { value: 'accessStatus_1', name: '健康' },
  { value: 'accessStatus_0', name: '故障' },
];
export const FILTER2_OPTIONS = [
  { value: '', name: '全部下载状态' },
  { value: 'downloadStatus_1', name: '正常下载' },
  { value: 'downloadStatus_0', name: '无法下载' },
];
export const FILTER3_OPTIONS = [
  { value: '', name: '全部域名' },
  { value: 'type_1', name: '国外域名' },
  { value: 'type_0', name: '国内域名' },
];

export const STANDARD_MAP = {
  inside: '国内域名，应该支持国外和国内网络均可访问，否则视作出错',
  outside: '国外域名，应该支持国外网络访问，否则视作出错',
};

export const DOMAIN_TYPE_MAP = {
  0: '国内域名',
  1: '国外域名',
};

export const COLUMNS = [
  {
    title: '域名',
    dataIndex: 'domain',
    align: 'center',
    ellipsis: true,
    width: 240,
    fixed: 'left',
  },
  {
    title: '域名类型',
    dataIndex: 'type',
    align: 'center',
    width: 70,
    render: (text) => DOMAIN_TYPE_MAP[text],
  },
  {
    title: '正常标准',
    dataIndex: 'normalStandard',
    align: 'center',
    // width: 150,
    // render: (text, record) => STANDARD_MAP[text],
  },
  {
    title: '域名是否健康',
    dataIndex: 'accessStatus',
    align: 'center',
    width: 120,
    render: (text) =>
      text ? (
        <Tag color="success" style={{ marginRight: 0 }}>
          健康
        </Tag>
      ) : (
        <Tag color="error" style={{ marginRight: 0 }}>
          故障,请通知技术
        </Tag>
      ),
  },
  {
    title: '是否可下载软件',
    dataIndex: 'downloadStatus',
    align: 'center',
    width: 145,
    // ellipsis: true,
    render: (text) =>
      text ? (
        <Tag color="success" style={{ marginRight: 0 }}>
          正常下载
        </Tag>
      ) : (
        <Tag color="error" style={{ marginRight: 0 }}>
          无法下载,请通知技术
        </Tag>
      ),
  },
  {
    title: '国内网络访问状态',
    dataIndex: 'domesticAccessStatus',
    align: 'center',
    width: 120,
    render: (text) =>
      text ? (
        <Tag color="success" style={{ marginRight: 0 }}>
          正常访问
        </Tag>
      ) : (
        <Tag color="error" style={{ marginRight: 0 }}>
          无法访问
        </Tag>
      ),
  },
  {
    title: '国外网络访问状态',
    dataIndex: 'foreignAccessStatus',
    align: 'center',
    width: 120,
    render: (text) =>
      text ? (
        <Tag color="success" style={{ marginRight: 0 }}>
          正常访问
        </Tag>
      ) : (
        <Tag color="error" style={{ marginRight: 0 }}>
          无法访问
        </Tag>
      ),
  },
  {
    title: '上次测试时间',
    dataIndex: 'latestCheckTime',
    align: 'center',
    width: 90,
    render: (text) => text?.replace('T', ' ') || '',
  },
];
