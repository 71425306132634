import {
  Modal,
  Form,
  Select,
  DatePicker,
  Switch,
  Input,
  InputNumber,
  Space,
} from 'antd';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { CUT_TYPE } from '../config';

const { Option } = Select;

const range = (start, end) => {
  const result = [];
  for (let i = start; i < end; i += 1) {
    result.push(i);
  }
  return result;
};

function AddCutModal({ visible, onClose, onOk }) {
  const [form] = Form.useForm();

  const handleOk = async () => {
    const values = await form.validateFields();
    values.state = values.enable ? 1 : 2; // 1启用，2不启用
    values.startTime = values.startTime.format('YYYY-MM-DD');
    values.always = values.always ? 1 : 0; // 1永久生效，默认0不是
    if (values.endTime) {
      values.endTime = values.endTime.format('YYYY-MM-DD');
    }
    values.config = 1; // 默认0渠道砍量，1砍量配置
    delete values.enable;
    onOk(values);
  };

  // eslint-disable-next-line consistent-return
  const disabledStartDate = (current) => {
    // eslint-disable-next-line no-shadow
    const type = form.getFieldValue('recedeType');
    const endTime = form.getFieldValue('endTime');
    if (!endTime) {
      if (type === 1) {
        // 历史解绑1，新增解绑2
        return current && current.isAfter(moment().subtract(2, 'days'), 'day');
      }
      if (type === 2) {
        return current && current.isBefore(moment(), 'day');
      }
    }
    const endDay = endTime.format('YYYY-MM-DD');
    if (type === 1) {
      return (
        current && current.isAfter(moment(endDay).subtract(1, 'days'), 'day')
      );
    }
    if (type === 2) {
      return (
        current &&
        (current.isBefore(moment(), 'day') ||
          current.isAfter(moment(endDay).subtract(1, 'days'), 'day'))
      );
    }
  };
  // eslint-disable-next-line consistent-return
  const disabledEndDate = (current) => {
    // eslint-disable-next-line no-shadow
    const type = form.getFieldValue('recedeType');
    const startTime = form.getFieldValue('startTime');
    if (!startTime) {
      if (type === 1) {
        return current && current.isAfter(moment().subtract(1, 'days'), 'day');
      }
      if (type === 2) {
        return current && current.isBefore(moment().add(1, 'days'), 'day');
      }
    }
    const startDay = startTime.format('YYYY-MM-DD');

    if (type === 1) {
      return (
        current &&
        (current.isAfter(moment().subtract(1, 'days'), 'day') ||
          current.isBefore(moment(startDay).add(1, 'days'), 'day'))
      );
    }
    if (type === 2) {
      return (
        current && current.isBefore(moment(startDay).add(1, 'days'), 'day')
      );
    }
  };

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible]);

  return (
    <Modal
      title="新建砍量任务"
      okText="发布任务"
      visible={visible}
      onOk={handleOk}
      onCancel={onClose}
      className="add-cut-modal"
      width={600}
      bodyStyle={{
        maxHeight: '500px',
        overflow: 'auto',
      }}
    >
      <Form layout="vertical" autoComplete="off" form={form}>
        <div className="tips" style={{ padding: 0 }}>
          *砍量无法作用到渠道邀请到用户，如若要对渠道用户进行砍量，请在渠道管理-渠道砍量中进行操作
        </div>
        <Form.Item
          label="选择砍量类型"
          name="recedeType"
          rules={[{ required: true, message: '请选择砍量类型!' }]}
        >
          <Select>
            {CUT_TYPE.map((item) => (
              <Option value={item.value} key={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, curValues) =>
            prevValues.recedeType !== curValues.recedeType ||
            prevValues.always !== curValues.always
          }
        >
          {({ getFieldValue }) => {
            const type = getFieldValue('recedeType');
            const forever = getFieldValue('always');
            return (
              type && (
                <>
                  <div className="tips-title">
                    选择一个时间段，对这个时间段内被邀请到app的用户进行砍量操作
                  </div>
                  <div className="tips">
                    *在此期间，只要未对砍量任务进行停用，则砍量一直生效
                  </div>

                  <Form.Item
                    label="开始时间"
                    name="startTime"
                    rules={[{ required: true, message: '请选择开始时间!' }]}
                    tooltip="新增解绑无法早于当前时间"
                  >
                    <DatePicker
                      allowClear={false}
                      placeholder="请选择开始时间"
                      disabledDate={disabledStartDate}
                    />
                  </Form.Item>
                  {type === 2 && (
                    <Form.Item
                      label="是否永久生效"
                      name="always"
                      valuePropName="checked"
                      // rules={[
                      //   { required: true, message: '请选择是否永久有效!' },
                      // ]}
                    >
                      <Switch />
                    </Form.Item>
                  )}
                  {(type === 1 || (type === 2 && !forever)) && (
                    <Form.Item
                      label="结束时间"
                      name="endTime"
                      rules={[{ required: true, message: '请选择结束时间!' }]}
                      tooltip="历史解绑无法晚于当前时间"
                    >
                      <DatePicker
                        allowClear={false}
                        placeholder="请选择开始时间"
                        disabledDate={disabledEndDate}
                      />
                    </Form.Item>
                  )}
                  <div className="tips-title">设置砍量力度</div>
                  <div className="tips">
                    *历史解绑的砍量方式是在固定的范围中进行随机百分比解绑，新增解绑的砍量方式是概率性解绑
                  </div>
                  <Space align="top">
                    {type === 2 && (
                      <div style={{ lineHeight: '34px' }}>被邀请用户</div>
                    )}
                    <Form.Item
                      label=""
                      name="percent"
                      rules={[{ required: true, message: '请设置砍量力度!' }]}
                    >
                      <InputNumber />
                    </Form.Item>
                    <div style={{ lineHeight: '34px' }}>
                      {type === 1
                        ? '%的被邀请用户，将会解绑上级'
                        : '%概率无法绑定上级'}
                    </div>
                  </Space>
                  <div className="tips-title">是否启用</div>
                  <div className="tips">
                    *请仔细核对任务参数，特别是历史解绑任务，一旦启用并发布，瞬间生效！
                  </div>
                  <Form.Item
                    label=""
                    name="enable"
                    valuePropName="checked"
                    // rules={[{ required: true, message: '请选择是否启用!' }]}
                  >
                    <Switch />
                  </Form.Item>
                </>
              )
            );
          }}
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AddCutModal;
