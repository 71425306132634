import { post } from '@/utils/http';

export const getList = (params) =>
  post({
    url: '/manage/config/base/get/order',
    data: params,
  });

export const compensateOrder = (params) =>
  post({
    url: '/manage/chal/compensateOrder',
    data: params,
  });
