import { Table, PageHeader, Card, message } from 'antd';
import { useRequest } from 'ahooks';
import { EditableRow, EditableCell, DetailModal } from '@/components';
import { useDetailModal, useTableColumns } from '@/hooks';
import { COLUMNS } from './config';
import {
  getList,
  edit,
} from '@/services/business-management/config-management/announcementConfig';

import './index.less';

const components = {
  body: {
    row: EditableRow,
    cell: EditableCell,
  },
};

const getConfigList = async () => {
  const result = await getList();
  const list = result.data || [];
  list.forEach((item) => {
    if (item.contentType === 2) {
      // 1文本 2.富文本
      item.contentRich = true;
    }
  });
  return list;
};

function AnnouncementConfig() {
  const { data: dataSource, loading, refresh } = useRequest(getConfigList);
  const { showDetail, selectRecord, handleShowDetail, handleCloseDetail } =
    useDetailModal();

  const handleSave = async (val) => {
    const result = await edit(val);
    if (result.code === 200) {
      message.success('编辑成功');
      refresh();
      if (showDetail) {
        handleCloseDetail();
      }
    }
  };

  const { tableColumns, setTableColumns } = useTableColumns(COLUMNS, {
    onSave: handleSave,
    onDetail: (record) => handleShowDetail(record),
    noDelete: true,
  });

  return (
    <div className="announcement-config">
      <PageHeader ghost={false} backIcon={false} title="公告配置" />
      <div className="announcement-table">
        <Card>
          <Table
            className="edit-table"
            components={components}
            rowClassName={() => 'editable-row'}
            bordered
            dataSource={dataSource}
            columns={tableColumns}
            pagination={false}
            loading={loading}
            rowKey="noticeConfigId"
          />
        </Card>
      </div>
      <DetailModal
        title="配置详情"
        columns={COLUMNS}
        data={selectRecord}
        visible={showDetail}
        onClose={handleCloseDetail}
        onOk={handleSave}
        labelCol={3}
        wrapperCol={21}
        width={selectRecord.configRich ? '1000px' : '800px'}
        centered
      />
    </div>
  );
}

export default AnnouncementConfig;
