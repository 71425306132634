import { Modal, Table, Button } from 'antd';
import { useState, useEffect } from 'react';
import { useAntdTable } from 'ahooks';
import { usePagination, useTableColumns } from '@/hooks';
import { columns } from './config';
import { getList } from '@/services/business-management/orderManagement';

const getTableData = async ({ current, pageSize }, formData) => {
  const result = await getList({
    page: current,
    size: pageSize,
    date: formData.date,
    status: formData.status,
    condition: formData.condition,
  });

  const data = result.data || {};
  const list = data.list || [];

  return {
    total: data.totalCount || 0,
    list,
  };
};

function OrderModal({ visible, onClose, contact, onShowAddOrder }) {
  const { tableProps, search, params, refresh, run } = useAntdTable(
    getTableData,
    {
      defaultParams: [
        { current: 1, pageSize: 10 },
        {
          date: 1,
          status: 1,
          condition: contact.userId,
        },
      ],
      manual: true,
    }
  );

  useEffect(() => {
    if (visible) {
      run(
        {
          current: 1,
          pageSize: 10,
        },
        {
          date: 1,
          status: 1,
          condition: contact.userId,
        }
      );
    }
  }, [contact, visible]);

  const { tableColumns, setTableColumns } = useTableColumns(columns, {
    controlColumns: {
      title: '操作',
      dataIndex: 'operation',
      fixed: 'right',
      align: 'center',
      width: 60,
      render: (_, record) => (
        <Button type="link" size="small" onClick={() => onShowAddOrder(record)}>
          补单
        </Button>
      ),
    },
  });

  return (
    <Modal
      title=""
      visible={visible}
      width={900}
      footer=""
      bodyStyle={{ paddingTop: '40px' }}
      onCancel={onClose}
    >
      <Table
        columns={tableColumns}
        // dataSource={dataSource}
        size="small"
        {...tableProps}
        pagination={{
          ...tableProps.pagination,
          showTotal: (total) => `共 ${total} 条`,
          // showSizeChanger: true,
          // showQuickJumper: true,
        }}
        rowKey="orderId"
      />
    </Modal>
  );
}

export default OrderModal;
