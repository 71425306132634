import { Avatar, Badge } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import './index.less';

function ChatItem({ contact, onSelect, selected }) {
  return (
    <div
      className={`chat-item ${selected ? 'selected' : ''}`}
      onClick={() => onSelect(contact)}
    >
      <Badge count={contact.unreadNum} size="small">
        <Avatar
          shape="square"
          src={contact.photo}
          icon={<UserOutlined />}
          size={40}
        />
      </Badge>
      <div className="message-name">
        <div className="name">{contact.nickname}</div>
        <div className="message">{contact.latestChatContent}</div>
      </div>
      <div className="date-wrap">
        {contact.status === 0 && (
          <Badge.Ribbon
            text="未处理"
            size="small"
            color="volcano"
            className="un-do"
          />
        )}

        <div className="message-time undo">{contact.latestChatTime}</div>
      </div>
    </div>
  );
}
export default ChatItem;
