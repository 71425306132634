import { post } from '@/utils/http';

export const getList = (params) =>
  post({
    url: '/manage/customerService/workOrder/get/page',
    data: params,
  });
export const reply = (params) =>
  post({
    url: '/manage/customerService/workOrder/reply',
    data: params,
  });
export const addTimes = (params) =>
  post({
    url: '/manage/customerService/workOrder/num/increase',
    data: params,
  });
export const addVip = (params) =>
  post({
    url: '/manage/customerService/workOrder/vip/add',
    data: params,
  });
export const suspend = (params) =>
  post({
    url: '/manage/customerService/workOrder/account/suspend',
    data: params,
  });
export const getMessageList = (params) =>
  post({
    url: '/manage/customerService/workOrder/chatRecord/get/page',
    data: params,
  });
export const sendMessage = (params) =>
  post({
    url: '/manage/customerService/workOrder/reply',
    data: params,
  });
export const read = (params) =>
  post({
    url: '/manage/customerService/workOrder/chat/read',
    data: params,
  });
export const getUserInfo = (params) =>
  post({
    url: '/manage/customerService/workOrder/get/userInfo',
    data: params,
  });
export const resetPassword = (params) =>
  post({
    url: '/manage/customerService/workOrder/user/password/update',
    data: params,
  });
export const changePhone = (params) =>
  post({
    url: '/manage/user/manage/changePhone',
    data: params,
  });
