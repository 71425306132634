/* eslint-disable prefer-destructuring */
import {
  PageHeader,
  Card,
  Space,
  Form,
  Row,
  Col,
  Select,
  Input,
  Button,
  Table,
  message,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useAntdTable, useMemoizedFn } from 'ahooks';
import { SORT1_OPTIONS, SORT2_OPTIONS, COLUMNS, STATUS_MAP } from './config';
import { EditableRow, EditableCell, DetailModal } from '@/components';
import {
  usePagination,
  useDetailModal,
  useTableColumns,
  useModalFormColumns,
} from '@/hooks';

import {
  getCompanyList,
  add,
  edit,
  del,
} from '@/services/business-management/filmCompanyManagement';
import { getOrderParams, getPageUpdate } from '@/utils/commonUtils';

const { Option } = Select;

const components = {
  body: {
    row: EditableRow,
    cell: EditableCell,
  },
};

const initialValues = {
  sort1: '',
  sort2: '',
  searchType: 'factoryId',
  search: '',
};

const selectBefore = (
  <Form.Item name="searchType" noStyle>
    <Select>
      <Option value="factoryId">片商id</Option>
      <Option value="factoryName">片商名</Option>
    </Select>
  </Form.Item>
);

const getTableData = async ({ current, pageSize }, formData) => {
  const { orders } = getOrderParams(formData);
  if (!orders.length) {
    orders.push({
      field: 'createTime',
      direction: 'DESC',
    });
  }
  const result = await getCompanyList({
    pageIndex: current,
    pageSize,
    params: formData.search
      ? { [formData.searchType]: formData.search }
      : undefined,
    orders,
  });
  const data = result.data || {};
  const list = data.data || [];
  list.forEach((item) => {
    item.disable = STATUS_MAP[item.status];
  });

  return {
    total: data.total || 0,
    list,
  };
};

function FilmCompanyManagement() {
  const [form] = Form.useForm();
  const { tableProps, search, params, refresh, run } = useAntdTable(
    getTableData,
    {
      form,
      defaultParams: [{ current: 1, pageSize: 10 }, initialValues],
    }
  );
  const { showDetail, selectRecord, handleShowDetail, handleCloseDetail } =
    useDetailModal();
  const { formColumns, formType, setFormColumns, getModalFormColumns } =
    useModalFormColumns(COLUMNS);

  const handleSave = useMemoizedFn(async (val) => {
    // console.log(val);
    const saveParams = {
      factoryName: val.factoryName, // 片商密码
      factoryPwd: val.factoryPwd, // 片商名称
      pictureUrl:
        val.pictureUrl === selectRecord.pictureUrl ? undefined : val.pictureUrl, // 片商图片
      factoryGrade: val.factoryGrade, // 片商等级
      factoryIntroduction: val.factoryIntroduction, // 厂牌介绍
      // inviteCode: val.inviteCode, // 邀请码
      status: val.disable ? 0 : 1, // 状态，启用1，禁用0
      freeConversion: val.freeConversion, // 发布条件-免费视频数量
      shortsConversion: val.shortsConversion, // 发布条件-免费视频数量
      actionsConversion: val.actionsConversion, // 发布条件-免费视频数量
      titbitsConversion: val.titbitsConversion, // 发布条件-免费视频数量
      businessMax: val.businessMax, // 商业视频最大
      shortsMax: val.shortsMax, // 短视频发布上限
      actionsMax: val.actionsMax, // 动态发布上限
      titbitsMax: val.titbitsMax, // 花絮发布上限
      withdrawMin: val.withdrawMin, // 提现门槛
      withdrawMax: val.withdrawMax, // 提现上限
    };

    delete val.disable;
    if (showDetail && formType === 'add') {
      const result = await add(saveParams);
      if (result.code === 200) {
        message.success('添加成功');
        handleCloseDetail();
        run(
          {
            ...params[0],
            current: 1,
          },
          params[1]
        );
      }
    } else {
      saveParams.factoryId = val.factoryId;
      const result = await edit(saveParams);
      if (result.code === 200) {
        message.success('编辑成功');
        refresh();
        if (showDetail) {
          handleCloseDetail();
        }
      }
    }
  });

  const handleDel = useMemoizedFn(async (record) => {
    console.log(record);
    const result = await del({
      factoryId: record.factoryId,
    });
    if (result.code === 200) {
      message.success('删除成功');
      const page = getPageUpdate(tableProps, 1);
      run(
        {
          ...params[0],
          current: page,
        },
        params[1]
      );
    }
  });

  const { tableColumns, setTableColumns } = useTableColumns(COLUMNS, {
    onSave: handleSave,
    delTitle: (record) => `确定要删除${record.factoryName}吗?`,
    onDelete: handleDel,
    onDetail: (record) => {
      getModalFormColumns(record, 'detail');
      handleShowDetail(record);
    },
  });

  const handleShowAdd = () => {
    getModalFormColumns({}, 'add');
    handleShowDetail({ disable: false });
  };

  return (
    <div className="user-management">
      <PageHeader ghost={false} backIcon={false} title="片商管理" />
      <div className="content-wrap">
        <Space direction="vertical" style={{ width: '100%' }}>
          <Card>
            <Form initialValues={initialValues} form={form}>
              <Row>
                <Col span={2}>
                  <Button
                    type="primary"
                    style={{ marginBottom: '10px' }}
                    onClick={handleShowAdd}
                  >
                    <PlusOutlined />
                    新建片商
                  </Button>
                </Col>{' '}
                <Col span={5} offset={1}>
                  <Form.Item
                    label="排序1"
                    name="sort1"
                    style={{ marginBottom: 0 }}
                  >
                    <Select>
                      {SORT1_OPTIONS.map((item) => (
                        <Option value={item.value} key={item.value}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={5} offset={1}>
                  <Form.Item
                    label="排序2"
                    name="sort2"
                    style={{ marginBottom: 0 }}
                  >
                    <Select>
                      {SORT2_OPTIONS.map((item) => (
                        <Option value={item.value} key={item.value}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6} offset={1}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.searchType !== curValues.searchType
                    }
                  >
                    {({ getFieldValue }) => {
                      const type = getFieldValue('searchType');
                      return (
                        <Form.Item
                          label="关键搜索"
                          name="search"
                          style={{ marginBottom: 0 }}
                        >
                          <Input
                            placeholder={`输入${
                              type === 'id' ? '片商id' : '片商名'
                            }`}
                            addonBefore={selectBefore}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={2} offset={1}>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Button type="primary" onClick={search.submit}>
                      确认查看
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
          <Card>
            <div className="table-title">查询结果</div>
            <Table
              className="edit-table"
              components={components}
              rowClassName={() => 'editable-row'}
              bordered
              columns={tableColumns}
              scroll={{ x: 'max-content' }}
              {...tableProps}
              pagination={{
                ...tableProps.pagination,
                showTotal: (total) => `共 ${total} 条`,
                // showSizeChanger: true,
                // showQuickJumper: true,
              }}
              rowKey="factoryId"
            />
          </Card>
        </Space>
      </div>
      <DetailModal
        width={1000}
        labelCol={8}
        wrapperCol={16}
        columns={formColumns}
        data={selectRecord}
        destroyOnClose
        visible={showDetail}
        onClose={handleCloseDetail}
        onOk={handleSave}
        modalType={formType}
        title={
          formType === 'add'
            ? '新建片商'
            : `片商：${
                selectRecord.id
              } / 创建时间：${selectRecord.createTime?.replace('T', ' ')}`
        }
        inline
        centered
      />
    </div>
  );
}

export default FilmCompanyManagement;
