import { post } from '@/utils/http';

export const getList = (params) =>
  post({
    url: '/manage/video/short/examine/get/page',
    data: params,
  });
export const pass = (params) =>
  post({
    url: '/manage/video/short/examine/approve',
    data: params,
  });
export const batchReject = (params) =>
  post({
    url: '/manage/video/short/examine/reject/batch',
    data: params,
  });
