/* eslint-disable eqeqeq */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-filename-extension */
import { Image, Space } from 'antd';
import { useState } from 'react';

export const FILTER1_OPTIONS = [
  { value: '', name: '不限' },
  { value: 'actionContent_textOnly', name: '只看文字贴' },
  { value: 'actionContent_containsPicture', name: '只看有图片的贴' },
];
export const FILTER2_OPTIONS = [
  { value: '', name: '不限用户' },
  { value: 'invite', name: '只看片商作品' },
  { value: 'recharge', name: '只看普通用户作品' },
];

export const SORT_OPTIONS = [
  { value: '', name: '不限排序' },
  { value: 'passRate-DESC', name: '按通过率高低' },
  { value: 'contributionRate-DESC', name: '按利润率高低' },
  { value: 'createTime-ASC', name: '最旧提审' },
  { value: 'createTime-DESC', name: '最近提审' },
  { value: 'factoryGrade-DESC', name: '按片商等级高低' },
  // { value: 'bug', name: '按用户等级高低' },
];

function MultiImg({ text }) {
  const [visible, setVisible] = useState(false);
  return text ? (
    <>
      <Image
        preview={{ visible: false }}
        src={text[0]}
        width="60%"
        onClick={() => setVisible(true)}
      />
      <span>共{text.length}张</span>
      <div style={{ display: 'none' }}>
        <Image.PreviewGroup
          preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}
        >
          {text.map((url) => (
            <Image src={url} key={url} />
          ))}
        </Image.PreviewGroup>
      </div>
    </>
  ) : (
    ''
  );
}

export const COLUMNS = [
  {
    title: '动态贴id',
    dataIndex: 'movieId',
    align: 'center',
    // ellipsis: true,
    width: 80,
    fixed: 'left',
    notShowForm: true,
  },
  {
    title: '片商名/等级',
    dataIndex: 'factory',
    align: 'center',
    notShowForm: true,
    render: (text) =>
      text ? `${text?.factoryName}/${text?.factoryGrade}` : '',
  },
  // {
  //   title: '用户名/等级',
  //   dataIndex: 'name',
  //   align: 'center',
  //   notShowForm: true,
  // },
  {
    title: '通过率',
    dataIndex: ['factory', 'passRate'],
    align: 'center',
    width: 60,
    notShowForm: true,
    render: (text) => (text || text === 0 ? `${text}%` : ''),
  },
  {
    title: '贡献利润率',
    dataIndex: ['factory', 'contributionRate'],
    align: 'center',
    width: 60,
    notShowForm: true,
    render: (text) => (text || text === 0 ? `${text}%` : ''),
  },
  {
    title: '动态文案',
    dataIndex: 'des',
    align: 'center',
    width: 120,
    render: (text) => <div className="des">{text}</div>,
    formate: (text) => <div className="des">{text || '无'}</div>,
  },

  {
    title: '图片',
    dataIndex: 'verCoverList',
    align: 'center',
    width: 120,
    type: 'image',
    render: (text) => <MultiImg text={text} />,

    // notShowForm: true,
  },
  // {
  //   title: '图片',
  //   dataIndex: 'verticalCover',
  //   type: 'image',
  //   notShowTable: true,
  // },
  {
    title: '相关导演',
    dataIndex: 'actors',
    key: 'directors',
    align: 'center',
    width: 120,
    // ellipsisRow: 2,
    notShowForm: true,
    render: (text) => {
      const directors = (text || []).filter((item) => item.type === 2);
      const names = directors.map((item) => item.actorName);
      return names.join('、');
    },
  },
  {
    title: '相关演员',
    dataIndex: 'actors',
    key: 'actors',
    align: 'center',
    width: 120,
    // ellipsisRow: 2,
    notShowForm: true,
    render: (text) => {
      const actors = (text || []).filter((item) => item.type === 1);
      const names = actors.map((item) => item.actorName);
      return names.join('、');
    },
  },
  {
    title: '动态对外类型',
    dataIndex: 'videoAttribute',
    align: 'center',
    width: 120,
    notShowForm: true,
    render: (text, record) => {
      if (text == 1) {
        // 1vip视频，2金币视频 3免费 4.限免
        return '会员可见';
      }
      if (text == 2) {
        return `金币动态/${record.moviePrice || 0}金币`;
      }
      if (text == 3) {
        return '永久免费';
      }
      return '未设置';
    },
  },
  {
    title: '提审时间',
    dataIndex: 'createTime',
    align: 'center',
    width: 90,
    notShowForm: true,
    render: (text) => text.replace('T', ' '),
  },
  {
    title: '关联导演/演员',
    dataIndex: 'actors',
    notShowTable: true,
    type: 'avatar',
  },
  // {
  //   title: '关联作品',
  //   dataIndex: 'works',
  //   notShowTable: true,
  //   formate: (text, data) =>
  //     (text || []).length ? (
  //       <Space wrap>
  //         {text.map((item) => (
  //           <span>《{item.name}》</span>
  //         ))}
  //       </Space>
  //     ) : (
  //       '无'
  //     ),
  // },
];

export const TURN_DOWN_LABELS = [
  { value: '1', label: '广告传播' },
  { value: '2', label: '内容档次太低' },
  { value: '11', label: '恶意上传刷量，请尽快整改，否则封号处理' },
];
