/* eslint-disable prefer-destructuring */
import {
  PageHeader,
  Card,
  Space,
  Form,
  Row,
  Col,
  Select,
  Input,
  Button,
  Table,
  message,
} from 'antd';
import { useAntdTable, useDeepCompareEffect } from 'ahooks';
import ClipboardJS from 'clipboard';
import {
  FILTER1_OPTIONS,
  FILTER2_OPTIONS,
  COLUMNS,
  orderColumns,
} from './confg';
import { EditableRow, EditableCell, DetailModal } from '@/components';
import { useDetailModal, useTableColumns } from '@/hooks';

import {
  getList,
  compensateOrder,
} from '@/services/business-management/orderManagement';

const { Option } = Select;

const components = {
  body: {
    row: EditableRow,
    cell: EditableCell,
  },
};

const initialValues = {
  date: 1,
  status: 1,
  searchType: 'inviteCode',
  condition: '',
};

const selectBefore = (
  <Form.Item name="searchType" noStyle>
    <Select>
      <Option value="inviteCode">邀请码</Option>
      <Option value="orderId">订单号</Option>
    </Select>
  </Form.Item>
);

const getTableData = async ({ current, pageSize }, formData) => {
  const result = await getList({
    page: current,
    size: pageSize,
    date: formData.date,
    status: formData.status,
    condition: formData.condition,
  });

  const data = result.data || {};
  const list = data.list || [];

  return {
    total: data.totalCount || 0,
    list,
  };
};

function FilmCompanyManagement() {
  const [form] = Form.useForm();
  const { tableProps, search, params, refresh, run } = useAntdTable(
    getTableData,
    {
      form,
      defaultParams: [{ current: 1, pageSize: 10 }, initialValues],
    }
  );
  const { showDetail, selectRecord, handleShowDetail, handleCloseDetail } =
    useDetailModal();
  const {
    showDetail: showAddOrder,
    selectRecord: selectAddOrder,
    handleShowDetail: handleShowAddOrder,
    handleCloseDetail: handleCloseAddOrder,
  } = useDetailModal();
  // const { formColumns, formType, setFormColumns, getModalFormColumns } =
  //   useModalFormColumns(COLUMNS);

  const { tableColumns, setTableColumns } = useTableColumns(COLUMNS, {
    controlColumns: {
      title: '操作',
      dataIndex: 'operation',
      fixed: 'right',
      align: 'center',
      width: 110,
      render: (_, record) => (
        <>
          <Button
            type="link"
            size="small"
            onClick={() => handleShowDetail(record, 'detail')}
          >
            查看
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => handleShowAddOrder(record)}
          >
            补单
          </Button>
        </>
      ),
    },
  });

  const handleAddOrder = async (values) => {
    const result = await compensateOrder({
      code: values.code,
      orderId: values.orderId,
    });
    if (result.data?.status) {
      message.success('补单成功');
      handleCloseAddOrder();
    } else {
      message.error(result.data?.msg || '补单失败');
    }
  };

  useDeepCompareEffect(() => {
    const copyDataIndex = ['orderId', 'busOrderId'];
    const clipboardArr = [];
    tableProps.dataSource.forEach((item) => {
      copyDataIndex.forEach((dataIndex) => {
        if (item[dataIndex]) {
          const clipboard = new ClipboardJS(
            `#${dataIndex}-${item.orderId}-copy`,
            {
              text(trigger) {
                return item[dataIndex];
              },
            }
          );

          clipboard.on('success', (e) => {
            message.success('复制成功！');
          });

          clipboard.on('error', (e) => {
            message.error('复制失败！');
          });
          clipboardArr.push(clipboard);
        }
      });
    });
    return () => {
      clipboardArr.forEach((clipboard) => {
        clipboard.destroy();
      });
    };
  }, [tableProps.dataSource]);

  return (
    <div className="user-management">
      <PageHeader ghost={false} backIcon={false} title="订单管理" />
      <div className="content-wrap">
        <Space direction="vertical" style={{ width: '100%' }}>
          <Card>
            <Form initialValues={initialValues} form={form}>
              <Row>
                <Col span={5}>
                  <Form.Item
                    label="筛选1"
                    name="date"
                    style={{ marginBottom: 0 }}
                  >
                    <Select>
                      {FILTER1_OPTIONS.map((item) => (
                        <Option value={item.value} key={item.value}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={5} offset={1}>
                  <Form.Item
                    label="筛选2"
                    name="status"
                    style={{ marginBottom: 0 }}
                  >
                    <Select>
                      {FILTER2_OPTIONS.map((item) => (
                        <Option value={item.value} key={item.value}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8} offset={1}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.searchType !== curValues.searchType
                    }
                  >
                    {({ getFieldValue }) => {
                      const type = getFieldValue('searchType');
                      return (
                        <Form.Item
                          label="关键搜索"
                          name="condition"
                          style={{ marginBottom: 0 }}
                        >
                          <Input
                            placeholder={`输入${
                              type === 'inviteCode' ? '邀请码' : '订单号'
                            }`}
                            addonBefore={selectBefore}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={2} offset={1}>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Button type="primary" onClick={search.submit}>
                      确认查看
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
          <Card>
            <div className="table-title">查询结果</div>
            <Table
              className="edit-table"
              components={components}
              rowClassName={() => 'editable-row'}
              bordered
              columns={tableColumns}
              scroll={{ x: 'max-content' }}
              {...tableProps}
              pagination={{
                ...tableProps.pagination,
                showTotal: (total) => `共 ${total} 条`,
                // showSizeChanger: true,
                // showQuickJumper: true,
              }}
              rowKey="orderId"
            />
          </Card>
        </Space>
      </div>
      <DetailModal
        width={820}
        columns={COLUMNS}
        data={selectRecord}
        destroyOnClose
        visible={showDetail}
        onClose={handleCloseDetail}
        onOk={handleCloseDetail}
        modalType="detail"
        inline
        centered
      />
      <DetailModal
        columns={orderColumns}
        data={selectAddOrder}
        destroyOnClose
        visible={showAddOrder}
        onClose={handleCloseAddOrder}
        onOk={handleAddOrder}
        centered
        title="补单"
      />
    </div>
  );
}

export default FilmCompanyManagement;
