import { Button, Tag, Space, Divider, Modal, message } from 'antd';
import {
  PlusOutlined,
  MenuOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import ClipboardJS from 'clipboard';
import './index.less';
import { useEffect, useRef } from 'react';

const DragHandle = SortableHandle(() => <MenuOutlined />);

const { confirm } = Modal;

const ATTR_MAP = {
  1: 'VIP',
  2: '金币',
  3: '免费',
  4: '限免',
};

function FilmCard({
  divider,
  type,
  dragable,
  value = {},
  onPreview,
  onDelete,
  onAdd,
  isAdd,
}) {
  const copyRef = useRef(null);
  const handleDelete = () => {
    confirm({
      title: `确定要删除${value.title}吗?`,
      icon: <ExclamationCircleOutlined />,
      content: '',
      onOk() {
        onDelete(value);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  useEffect(() => {
    const clipboard = new ClipboardJS(copyRef.current, {
      text(trigger) {
        return value.movieId;
      },
    });

    clipboard.on('success', (e) => {
      message.success('复制成功！');
    });

    clipboard.on('error', (e) => {
      message.error('复制失败！');
    });

    return () => {
      clipboard.destroy();
    };
  }, [value.movieId]);

  return (
    <div className="film-content-wrap">
      <div className="film-card">
        <div className="drag-icon">{dragable && <DragHandle />}</div>

        <div className="img-wrap">
          <img src={value.hurl} className="film-img" alt="" />
          {!!value.published && (
            <Tag color="rgba(0,0,0,0.5)" className="film-status">
              已发布
            </Tag>
          )}
        </div>

        <div className="film-content">
          <div className="title">{value.title}</div>
          <div className="film-id">
            视频id:{value.movieId} <span ref={copyRef}>（点击复制）</span>
          </div>
          <div className="film-label">
            {ATTR_MAP[value.attr]}视频
            {value.attr === 2 && `（${value.price || 0}金币）`}
          </div>
          <div className="film-tag">
            {[value.factoryName, ...value.tags].map((item, index) => (
              <Tag key={item}>#{item}</Tag>
            ))}
          </div>
          <div className="film-num">
            <span>播放次数：{value.watchNum}</span>
            <span>收藏：{value.collectNum}</span>
          </div>
        </div>
        <Space className="film-btn" direction="vertical">
          {type === 'added' ? (
            <Button type="primary" onClick={handleDelete}>
              删除
            </Button>
          ) : (
            <Button
              icon={!isAdd && <PlusOutlined />}
              type="primary"
              onClick={onAdd}
              disabled={isAdd}
            >
              {isAdd ? '已添加到当日精选' : '添加到当日精选'}
            </Button>
          )}
          <Button type="primary" onClick={() => onPreview(value)}>
            {value.type === 1 ? '预览视频/预告' : '预览视频'}
          </Button>
        </Space>
      </div>
      {divider && <Divider />}
    </div>
  );
}

export default FilmCard;
