import {
  PageHeader,
  Space,
  DatePicker,
  Calendar,
  Card,
  Button,
  Input,
  Select,
  Spin,
  Empty,
  message,
} from 'antd';
import { ForwardOutlined } from '@ant-design/icons';
import { useState } from 'react';
import moment from 'moment';
import { useRequest } from 'ahooks';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { FilmCard } from './components';
import { useDetailModal } from '@/hooks';
import { PlayerModal } from '@/components';
import {
  getArrageByMonth,
  getArrageVideoByDate,
  searchVideoData,
  addVideo,
  delVideoFromArrage,
  moveVideo,
} from './service';
import './index.less';

const { Search } = Input;
const { Option } = Select;

const SortableItem = SortableElement(
  ({ divider, value, onPreview, onDelete }) => (
    <FilmCard
      divider={divider}
      type="added"
      dragable
      value={value}
      onPreview={onPreview}
      onDelete={onDelete}
    />
  )
);

const SortableList = SortableContainer(({ children }) => <div>{children}</div>);

function FilmingManagement() {
  const [selectMonth, setSelectMonth] = useState(moment());
  const [selectDate, setSelectDate] = useState(moment());
  const {
    data: arrageData = {},
    loading,
    run: getArrageData,
    refresh: refreshArrageData,
  } = useRequest(getArrageByMonth, {
    defaultParams: [selectMonth],
  });
  const {
    data: arrageVideo = {},
    refresh: refreshVideoData,
    mutate: mutateArrageVideo,
    run: getArrageVideo,
  } = useRequest(getArrageVideoByDate, {
    defaultParams: [selectDate],
  });
  const { data: searchData = [], run: searchVideo } = useRequest(
    searchVideoData,
    {
      manual: true,
    }
  );

  const {
    showDetail: showPreview,
    selectRecord: selectVideo,
    handleShowDetail: handleShowPreview,
    handleCloseDetail: handleClosepreview,
  } = useDetailModal();
  const [searchType, setSearchType] = useState(1);

  const calenderHeader = ({ value, type, onChange, onTypeChange }) => {
    const handleChangeDate = (dateValue) => {
      const newValue = value.clone();
      newValue.month(dateValue.month());
      if (moment().month() === dateValue.month()) {
        newValue.date(moment().date());
      } else {
        newValue.date(1);
      }

      onChange(newValue);
      setSelectMonth(dateValue);
    };
    return (
      <div className="calender-header">
        <DatePicker
          picker="month"
          onChange={handleChangeDate}
          allowClear={false}
          value={selectMonth}
        />
        <span className="total">
          本月已备精选视频：<span>{arrageData.total || 0}部</span>
        </span>
      </div>
    );
  };

  const dateCellRender = (value) => {
    const month = value.get('month') + 1;
    if (month === arrageData.m) {
      const day = value.get('date');
      const dateValue = arrageData.list.find((item) => item.d === day) || {};

      return dateValue.num ? (
        <div className="film-count">{dateValue.num}部</div>
      ) : (
        ''
      );
    }
    return '';
  };

  const handleSearch = async (val) => {
    if (val) {
      searchVideo(searchType, val);
    } else {
      message.warning('请输入搜索内容');
    }
  };

  const handleSortEnd = async ({ oldIndex, newIndex }) => {
    const newList = arrayMoveImmutable(arrageVideo.data, oldIndex, newIndex);
    mutateArrageVideo({
      ...arrageVideo,
      data: newList,
    });
    const list = newList.map((item) => item.movieId);
    const result = await moveVideo(selectDate, list);
    if (result.code === 200) {
      message.success('操作成功');
      refreshVideoData();
    }
  };

  const handleSelectDate = (value) => {
    const newMonth = value.get('month') + 1;
    const nowMonth = selectMonth.get('month') + 1;
    setSelectDate(value);
    getArrageVideo(value);
    if (newMonth !== nowMonth) {
      const monthStr = value.format('YYYY-MM');
      const newSelectMonth = moment(`${monthStr}-01`);
      setSelectMonth(newSelectMonth);
      getArrageData(newSelectMonth);
    }
  };
  const hanldeDelFilm = async (video) => {
    const result = await delVideoFromArrage(selectDate, video.movieId);
    if (result.code === 200) {
      message.success('删除成功');
      refreshArrageData();
      refreshVideoData();
    }
  };

  const handleAdd = async (video) => {
    const result = await addVideo(selectDate, video.movieId);
    if (result.code === 200) {
      message.success('添加成功');
      refreshArrageData();
      refreshVideoData();
    }
  };

  return (
    <div className="filming-management">
      <PageHeader ghost={false} backIcon={false} title="拍片管理" />
      <div className="content-wrap">
        <div className="filming-content-wrap content">
          <Spin spinning={loading} delay={500}>
            <Calendar
              // onPanelChange={onPanelChange}
              headerRender={calenderHeader}
              dateCellRender={dateCellRender}
              onSelect={handleSelectDate}
            />
          </Spin>

          <div className="arrow-wrap">
            <ForwardOutlined rotate={90} style={{ fontSize: '50px' }} />
          </div>
          <Space direction="vertical" style={{ width: '100%' }} size="middle">
            <Card
              title={`正在为${selectDate.format('YYYY年M月D日')}选择排片视频`}
              extra={
                <Search
                  placeholder="输入视频id 或 视频标题，对视频库进行搜索"
                  allowClear
                  enterButton="搜索"
                  addonBefore={
                    <Select
                      value={searchType}
                      onChange={(val) => setSearchType(val)}
                    >
                      <Option value={1}>视频id</Option>
                      <Option value={2}>视频名</Option>
                    </Select>
                  }
                  onSearch={handleSearch}
                />
              }
            >
              {searchData?.length ? (
                searchData.map((item, index) => (
                  <FilmCard
                    value={item}
                    key={item.movieId}
                    divider={index !== searchData.length - 1}
                    onAdd={() => handleAdd(item)}
                    isAdd={arrageVideo.data?.find(
                      (video) => video.movieId === item.movieId
                    )}
                    onPreview={handleShowPreview}
                  />
                ))
              ) : (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="没有搜索到相关视频"
                />
              )}
            </Card>
            <Card
              title={`${selectDate.format('YYYY年M月D日')}已储备${
                arrageVideo.total || 0
              }部精选视频`}
            >
              {arrageVideo.data?.length ? (
                <SortableList
                  useDragHandle
                  onSortEnd={handleSortEnd}
                  onPreview={handleShowPreview}
                >
                  {arrageVideo.data.map((item, index) => (
                    <SortableItem
                      key={item.movieId}
                      index={index}
                      value={item}
                      divider={index !== arrageVideo.data.length - 1}
                      onPreview={handleShowPreview}
                      onDelete={hanldeDelFilm}
                    />
                  ))}
                </SortableList>
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </Card>
          </Space>
        </div>
      </div>
      {showPreview && (
        <PlayerModal
          onClose={handleClosepreview}
          previewUrl={selectVideo.previewUrl}
          url={selectVideo.url}
          videoType="mp4"
          noTab={selectVideo.type !== 1}
        />
      )}
    </div>
  );
}

export default FilmingManagement;
