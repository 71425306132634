import { Card, Radio, DatePicker, Button, Space, Spin } from 'antd';
import ReactECharts from 'echarts-for-react';
import { useState } from 'react';
import moment from 'moment';
import { useRequest } from 'ahooks';
import { getBossChart } from '@/services/data';

const { RangePicker } = DatePicker;

const DEFUALT_VALUE = {
  grid: { top: 50, right: 36, bottom: 24, left: 36 },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: [],
  },
  yAxis: {
    type: 'value',
  },
  legend: {
    icon: 'rect',
  },
  series: [],
  tooltip: {
    trigger: 'axis',
  },
};

const KEY_TYPE_MAP = {
  turnover: '营业额',
  addUser: '新增用户',
  activeUser: '活跃用户',
  ordersNum: '订单数',
  dealNum: '交易数',
  addVideo: '新增视频',
  loginNum: '登录数',
  buyOut: '买断支出',
  dividePay: '分成支出',
};

const genOptions = (data) => {
  if (data) {
    return {
      ...DEFUALT_VALUE,
      series: data.series,
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: data.dateArr,
      },
    };
  }
  return DEFUALT_VALUE;
};

const getData = async (timeRage, deviceType) => {
  const result = await getBossChart({
    client: deviceType,
    dateFrom: timeRage[0].format('YYYY-MM-DD'),
    dateTo: timeRage[1].format('YYYY-MM-DD'),
  });

  const data = result.data || {};

  let dateArr = [];
  const series = Object.keys(data).map((key, index) => {
    const Dates = Object.keys(data[key]).sort((a, b) => {
      if (moment(a).isBefore(b, 'day')) {
        return -1;
      }
      return 1;
    });
    if (index === 0) {
      dateArr = Dates;
    }
    const value = Dates.map((date) => data[key][date] || 0);

    return {
      name: KEY_TYPE_MAP[key],
      type: 'line',
      smooth: true,
      showSymbol: false,
      data: value,
    };
  });

  return {
    series,
    dateArr,
  };
};

function BossChart() {
  const [timeRage, setTimeRage] = useState([
    moment().subtract(1, 'month'),
    moment(),
  ]);
  const [deviceType, setDeviceType] = useState(0);
  const [dateType, setDateType] = useState('month');

  const { data, run, loading, refresh } = useRequest(getData, {
    defaultParams: [timeRage, deviceType],
  });

  const handleSelectDate = (type) => {
    let timeRage;
    if (type === 'month') {
      timeRage = [moment().subtract(1, 'month'), moment()];
      setTimeRage(timeRage);
    }
    if (type === 'week') {
      timeRage = [moment().subtract(6, 'day'), moment()];
      setTimeRage(timeRage);
    }
    setDateType(type);
    run(timeRage, deviceType);
  };
  const handleChangeDate = (dates, dateStrings) => {
    setTimeRage(dates);
    if (
      moment().subtract(1, 'month').isSame(dateStrings[0], 'day') &&
      moment().isSame(dateStrings[1], 'day')
    ) {
      setDateType('month');
    } else if (
      moment().subtract(6, 'day').isSame(dateStrings[0], 'day') &&
      moment().isSame(dateStrings[1], 'day')
    ) {
      setDateType('week');
    } else {
      setDateType('');
    }
  };

  const disabledDate = (current) => current && current > moment().endOf('day');

  const handleChangeDevice = (e) => {
    setDeviceType(e.target.value);
    run(timeRage, e.target.value);
  };

  return (
    <div className="boss-chart">
      <Card
        title="BOSS图表"
        extra={
          <Space>
            <span
              className={`time-label ${dateType === 'month' ? 'selected' : ''}`}
              onClick={() => handleSelectDate('month')}
            >
              近一月
            </span>
            <span
              className={`time-label ${dateType === 'week' ? 'selected' : ''}`}
              onClick={() => handleSelectDate('week')}
            >
              近一周
            </span>
            <RangePicker
              disabledDate={disabledDate}
              value={timeRage}
              onChange={handleChangeDate}
              allowClear={false}
            />
            <Button type="primary" onClick={() => run(timeRage, deviceType)}>
              点击筛选
            </Button>
            <Button type="primary" onClick={refresh}>
              更新数据
            </Button>
          </Space>
        }
      >
        <div className="radio-wrap">
          <Radio.Group value={deviceType} onChange={handleChangeDevice}>
            <Radio.Button value={0}>所有端</Radio.Button>
            <Radio.Button value={1}>安卓端</Radio.Button>
            <Radio.Button value={2}>IOS端</Radio.Button>
          </Radio.Group>
        </div>
        <Spin spinning={loading} delay={500}>
          <ReactECharts option={genOptions(data)} style={{ height: '300px' }} />
        </Spin>
      </Card>
    </div>
  );
}

export default BossChart;
