export const allColumns = [
  {
    title: '日活跃',
    dataIndex: 'dayActive',
    key: 'dayActive',
    fixed: 'left',
    align: 'center',
  },
  { title: '日订单', dataIndex: 'dayOrder', key: 'dayOrder', align: 'center' },
  { title: '日交易数', dataIndex: 'dayDeal', key: 'dayDeal', align: 'center' },
  {
    title: '日充值',
    dataIndex: 'dayRecharge',
    key: 'dayRecharge',
    align: 'center',
  },
  {
    title: '充值成功率',
    dataIndex: 'rechargeFinishRate',
    key: 'rechargeFinishRate',
    align: 'center',
    render: (text) => `${text}%`,
  },
  {
    title: '付费率',
    dataIndex: 'payRate',
    key: 'payRate',
    align: 'center',
    render: (text) => `${text}%`,
  },
  {
    title: '客单价',
    dataIndex: 'customerPrice',
    key: 'customerPrice',
    align: 'center',
  },
];
export const androidColumns = [
  {
    title: '日活跃',
    dataIndex: 'dayActive',
    key: 'dayActive',
    fixed: 'left',
    align: 'center',
  },
  { title: '日订单', dataIndex: 'dayOrder', key: 'dayOrder', align: 'center' },
  { title: '日交易数', dataIndex: 'dayDeal', key: 'dayDeal', align: 'center' },
  {
    title: '日充值',
    dataIndex: 'dayRecharge',
    key: 'dayRecharge',
    align: 'center',
  },
  {
    title: '充值成功率',
    dataIndex: 'rechargeFinishRate',
    key: 'rechargeFinishRate',
    align: 'center',
    render: (text) => `${text}%`,
  },
  {
    title: '付费率',
    dataIndex: 'payRate',
    key: 'payRate',
    align: 'center',
    render: (text) => `${text}%`,
  },
  {
    title: '客单价',
    dataIndex: 'customerPrice',
    key: 'customerPrice',
    align: 'center',
  },
];
export const iosColumns = [
  {
    title: '日活跃',
    dataIndex: 'dayActive',
    key: 'dayActive',
    fixed: 'left',
    align: 'center',
  },
  { title: '日订单', dataIndex: 'dayOrder', key: 'dayOrder', align: 'center' },
  { title: '日交易数', dataIndex: 'dayDeal', key: 'dayDeal', align: 'center' },
  {
    title: '日充值',
    dataIndex: 'dayRecharge',
    key: 'dayRecharge',
    align: 'center',
  },
  {
    title: '充值成功率',
    dataIndex: 'rechargeFinishRate',
    key: 'rechargeFinishRate',
    align: 'center',
    render: (text) => `${text}%`,
  },
  {
    title: '付费率',
    dataIndex: 'payRate',
    key: 'payRate',
    align: 'center',
    render: (text) => `${text}%`,
  },
  {
    title: '客单价',
    dataIndex: 'customerPrice',
    key: 'customerPrice',
    align: 'center',
  },
];
