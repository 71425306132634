export const columns = [
  {
    title: '日期',
    dataIndex: 'date',
    key: 'date',
    fixed: 'left',
    align: 'center',
  },
  {
    title: '总充值',
    dataIndex: 'totalRecharge',
    key: 'totalRecharge',
    align: 'center',
  },
  { title: '总日活', dataIndex: 'dau', key: 'dau', align: 'center' },
  { title: '总新增', dataIndex: 'totalAdd', key: 'totalAdd', align: 'center' },
  {
    title: '官方新增',
    dataIndex: 'officialAdd',
    key: 'officialAdd',
    align: 'center',
  },
  {
    title: '渠道新增',
    dataIndex: 'placeAdd',
    key: 'placeAdd',
    align: 'center',
  },
  {
    title: '裂变新增',
    dataIndex: 'fissionAdd',
    key: 'fissionAdd',
    align: 'center',
  },
  {
    title: '裂变率',
    dataIndex: 'fissionRate',
    key: 'fissionRate',
    align: 'center',
    render: (text) => `${text || 0}%`,
  },
  {
    title: '买断支出',
    dataIndex: 'buyOutPay',
    key: 'buyOutPay',
    align: 'center',
  },
  {
    title: '分成支出',
    dataIndex: 'dividePay',
    key: 'dividePay',
    align: 'center',
  },
  {
    title: 'VIP总充值',
    dataIndex: 'vipRecharge',
    key: 'vipRecharge',
    align: 'center',
  },
  {
    title: 'VIP官方充值',
    dataIndex: 'vipOfficialRecharge',
    key: 'vipOfficialRecharge',
    align: 'center',
  },
  {
    title: 'VIP渠道充值',
    dataIndex: 'vipPlaceRecharge',
    key: 'vipPlaceRecharge',
    align: 'center',
  },
  {
    title: 'VIP邀请充值',
    dataIndex: 'vipInviteRecharge',
    key: 'vipInviteRecharge',
    align: 'center',
  },
  {
    title: '金币总充值',
    dataIndex: 'coinRecharge',
    key: 'coinRecharge',
    align: 'center',
  },
  {
    title: '金币官方充值',
    dataIndex: 'coinOfficialRecharge',
    key: 'coinOfficialRecharge',
    align: 'center',
  },
  {
    title: '金币渠道充值',
    dataIndex: 'coinPlaceRecharge',
    key: 'coinPlaceRecharge',
    align: 'center',
  },
  {
    title: '金币邀请充值',
    dataIndex: 'coinInviteRecharge',
    key: 'coinInviteRecharge',
    align: 'center',
  },
];
