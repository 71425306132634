import { Card, Radio, DatePicker, Button, Space, Table, Spin } from 'antd';
import { useState } from 'react';
import moment from 'moment';
import { useRequest } from 'ahooks';
import { allColumns, androidColumns, iosColumns } from './columns-config';
import { getBusinessData } from '@/services/data';

const getData = async (date) => {
  const result = await getBusinessData({
    date: date.format('YYYY-MM-DD'),
  });

  const data = result.data || {};
  Object.keys(data).forEach((key) => {
    data[key] = data[key] ? [data[key]] : [];
  });

  return data;
};

function BusinessData() {
  const [selectDate, setSelectDate] = useState(moment());
  const [dateType, setDateType] = useState('today');
  const {
    data = [],
    run,
    loading,
    refresh,
  } = useRequest(getData, {
    defaultParams: [selectDate],
  });
  const handleChangeDate = (date, dateString) => {
    setSelectDate(date);
    if (moment().isSame(dateString, 'day')) {
      setDateType('today');
    } else {
      setDateType('all');
    }
  };

  const disabledDate = (current) => current && current > moment().endOf('day');
  const handleSelectToday = () => {
    setSelectDate(moment());
    setDateType('today');
    run(moment());
  };
  return (
    <Card
      title="商业数据"
      extra={
        <Space>
          <span
            className={`time-label ${dateType === 'today' ? 'selected' : ''}`}
            onClick={handleSelectToday}
          >
            今天
          </span>
          <DatePicker
            disabledDate={disabledDate}
            value={selectDate}
            onChange={handleChangeDate}
          />
          <Button type="primary" onClick={() => run(selectDate)}>
            点击查看
          </Button>
          <Button type="primary" onClick={refresh}>
            更新数据
          </Button>
        </Space>
      }
      style={{ lineHeight: 0 }}
    >
      <Spin spinning={loading} delay={500}>
        <Space direction="vertical">
          <Card type="inner" title="所有端">
            <Table
              columns={allColumns}
              dataSource={data.all}
              pagination={false}
              rowKey="date"
            />
          </Card>
          <Card type="inner" title="安卓端">
            <Table
              columns={androidColumns}
              dataSource={data.android}
              pagination={false}
              rowKey="date"
            />
          </Card>
          <Card type="inner" title="IOS端">
            <Table
              columns={iosColumns}
              dataSource={data.ios}
              pagination={false}
              rowKey="date"
            />
          </Card>
        </Space>
      </Spin>
    </Card>
  );
}

export default BusinessData;
