import { PageHeader, Card, Button, Table, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { useAntdTable, useMemoizedFn } from 'ahooks';
import { useDetailModal, useModalFormColumns, useTableColumns } from '@/hooks';
import { EditableRow, EditableCell, DetailModal } from '@/components';
import { COLUMNS } from './config';
import {
  getList,
  add,
  edit,
  del,
} from '@/services/business-management/config-management/thematicConfig';
import { getPageUpdate } from '@/utils/commonUtils';

import './index.less';

const components = {
  body: {
    row: EditableRow,
    cell: EditableCell,
  },
};

const getTableData = async ({ current, pageSize }) => {
  const result = await getList({
    pageIndex: current,
    pageSize,
  });

  const data = result.data || {};

  return {
    total: data.total || 0,
    list: data.data || [],
  };
};

function ThematicConfig() {
  const { tableProps, params, refresh, run } = useAntdTable(getTableData, {
    defaultParams: [{ current: 1, pageSize: 10 }],
  });
  const { showDetail, selectRecord, handleShowDetail, handleCloseDetail } =
    useDetailModal();
  const { formColumns, formType, getModalFormColumns } =
    useModalFormColumns(COLUMNS);

  const handleSave = async (val) => {
    val.tags = JSON.stringify(val.tagsList);
    val.status = val.status ? 1 : 0;
    delete val.tagsList;
    if (showDetail && formType === 'add') {
      const result = await add(val);
      if (result.code === 200) {
        message.success('添加成功');
        run({
          ...params[0],
          current: 1,
        });
        handleCloseDetail();
      }
    } else {
      const result = await edit(val);
      if (result.code === 200) {
        message.success('编辑成功');
        refresh();
        if (showDetail) {
          handleCloseDetail();
        }
      }
    }
  };

  const handleDel = useMemoizedFn(async (record) => {
    const result = await del({
      subjectConfigId: record.subjectConfigId,
    });
    if (result.code === 200) {
      const page = getPageUpdate(tableProps, 1);
      run({
        ...params[0],
        current: page,
      });
      message.success('删除成功！');
    }
  });

  const { tableColumns, setTableColumns } = useTableColumns(COLUMNS, {
    onSave: handleSave,
    delTitle: (record) => `确定要删除${record.name}吗?`,
    onDelete: handleDel,
    onDetail: (record) => {
      getModalFormColumns(record, 'detail');
      handleShowDetail(record);
    },
  });

  const handleShowAdd = () => {
    getModalFormColumns({}, 'add');
    handleShowDetail({});
  };

  return (
    <div className="thematic-config-wrap">
      <PageHeader ghost={false} backIcon={false} title="专题配置" />
      <div className="thematic-config-content">
        <Card>
          <Button
            type="primary"
            style={{ marginBottom: '10px' }}
            onClick={handleShowAdd}
          >
            <PlusOutlined />
            新建专题
          </Button>
          <Table
            className="edit-table"
            components={components}
            rowClassName={() => 'editable-row'}
            bordered
            columns={tableColumns}
            {...tableProps}
            pagination={{
              ...tableProps.pagination,
              showTotal: (total) => `共 ${total} 条`,
              // showSizeChanger: true,
              // showQuickJumper: true,
            }}
            rowKey="subjectConfigId"
          />
        </Card>
      </div>
      <DetailModal
        title={formType === 'add' ? '新建专题' : '专题详情'}
        modalType={formType}
        columns={formColumns}
        data={selectRecord}
        visible={showDetail}
        onClose={handleCloseDetail}
        onOk={handleSave}
        centered
      />
    </div>
  );
}

export default ThematicConfig;
