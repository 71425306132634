import { Form, Input, Select, InputNumber } from 'antd';

const { Option } = Select;

const FORM_ITEM_MAP = {
  cooperationType: {
    3: (
      <div style={{ marginTop: '-16px', marginBottom: '16px' }}>
        选择与片商免费合作，最好先和片商沟通好。找一些理由贬低该片价值，免费合作，代表我们要白嫖片商的片
      </div>
    ),
    1: (
      <Form.Item
        name="royaltyRate"
        label="分成比例"
        rules={[
          {
            required: true,
            message: '请输入分成比例',
          },
        ]}
      >
        <InputNumber
          style={{ width: '100%' }}
          placeholder="请输入分成比例，如30=30%。代表该片每盈利1元，片商就会获得0.3元分成"
          disabled
        />
      </Form.Item>
    ),
    2: (
      <Form.Item
        name="buyout"
        label="买断价格"
        rules={[
          {
            required: true,
            message: '请输入买断价格',
          },
        ]}
      >
        <InputNumber
          style={{ width: '100%' }}
          placeholder="请输入买断价格，1=1元。买断后，该片属于平台，之后所有收益与片商无关"
        />
      </Form.Item>
    ),
  },
  videoAttribute: {
    2: (
      <Form.Item
        name="moviePrice"
        label="金币售价"
        rules={[
          {
            required: true,
            message: '请输入金币售价',
          },
        ]}
      >
        <InputNumber
          style={{ width: '100%' }}
          placeholder="设置该片金币售价，1=1金币"
        />
      </Form.Item>
    ),
    1: (
      <div style={{ marginTop: '-16px', marginBottom: '16px' }}>
        设置该片为会员片，用户必须是VIP才可播放
      </div>
    ),
    3: (
      <>
        <div style={{ marginTop: '-16px', marginBottom: '16px' }}>
          设置该片为免费片，所有用户都可以观看
        </div>
        {/* <Form.Item
          name="freeSgin"
          label="免费类型"
          rules={[
            {
              required: true,
              message: '请选择免费类型',
            },
          ]}
        >
          <Select placeholder="请选择免费类型">
            <Option value={1}>永久免费</Option>
            <Option value={2}>限时免费（免费期过后，自动转为VIP可看）</Option>
          </Select>
        </Form.Item>
        <ConditionForm name="freeType" /> */}
      </>
    ),
    4: (
      <>
        <div style={{ marginTop: '-16px', marginBottom: '16px' }}>
          设置该片为免费片，所有用户都可以观看
        </div>
        <Form.Item
          name="freeDays"
          label="免费天数"
          rules={[
            {
              required: true,
              message: '请输入免费天数',
            },
          ]}
        >
          <InputNumber
            style={{ width: '100%' }}
            placeholder="输入限时免费天数"
          />
        </Form.Item>
      </>
    ),
  },
  stickerType: {
    2: (
      <Form.Item
        name="moviePrice"
        label="金币售价"
        rules={[
          {
            required: true,
            message: '请输入金币售价',
          },
        ]}
      >
        <InputNumber
          style={{ width: '100%' }}
          placeholder="设置该动态金币售价，1=1金币"
        />
      </Form.Item>
    ),
    1: (
      <div style={{ marginTop: '-16px', marginBottom: '16px' }}>
        设置该动态为会员可见，用户必须是VIP才可点击详情和查看大图
      </div>
    ),
    3: (
      <>
        <div style={{ marginTop: '-16px', marginBottom: '16px' }}>
          设置该动态免费看，所有用户都可以观看
        </div>
        {/* <Form.Item
          name="freeType"
          label="免费类型"
          rules={[
            {
              required: true,
              message: '请选择免费类型',
            },
          ]}
        >
          <Select placeholder="请选择免费类型">
            <Option value="foreverFree">永久免费</Option>
            <Option value="limitTimeFree">
              限时免费（免费期过后，自动转为VIP可看）
            </Option>
          </Select>
        </Form.Item>
        <ConditionForm name="freeType" /> */}
      </>
    ),
  },
  // freeType: {
  //   limitTimeFree: (
  //     <Form.Item
  //       name="timeLimitFree"
  //       label="免费天数"
  //       rules={[
  //         {
  //           required: true,
  //           message: '请输入免费天数',
  //         },
  //       ]}
  //     >
  //       <InputNumber style={{ width: '100%' }} placeholder="输入限时免费天数" />
  //     </Form.Item>
  //   ),
  // },
};

function ConditionForm({ name, type }) {
  let newName = name;
  if (type === 'sticker') {
    newName = 'stickerType';
  }
  return (
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, currentValues) =>
        prevValues[name] !== currentValues[name]
      }
    >
      {({ getFieldValue }) => FORM_ITEM_MAP[newName][getFieldValue(name)]}
    </Form.Item>
  );
}

export default ConditionForm;
