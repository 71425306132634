import {
  Modal,
  Input,
  Radio,
  Spin,
  Empty,
  Button,
  Tooltip,
  message,
} from 'antd';
import { LoadingOutlined, RedoOutlined } from '@ant-design/icons';
import Avatar from 'antd/lib/avatar/avatar';
import { useState, useEffect, useRef } from 'react';
import { useInfiniteScroll, useRequest } from 'ahooks';
import { useDetailModal } from '@/hooks';
import { DetailModal } from '@/components';
import { ChatItem, ChatWindow, UserDetail, OrderModal } from './components';
import { columns, orderColumns } from './config';
import {
  getList,
  getUserInfo,
  changePhone,
} from '@/services/customer-service/workOrder';
import { compensateOrder } from '@/services/business-management/orderManagement';
import { getList as getQuickList } from '@/services/customer-service/quickMessage';

import './index.less';

const { Search } = Input;

const getContactList = async ({ pageIndex, pageSize, status, inviteCode }) => {
  const result = await getList({
    pageIndex,
    pageSize,
    params: {
      status: status || undefined,
      inviteCode: inviteCode || undefined,
    },
  });

  const data = result.data || {};
  const list = data.data || [];

  return {
    list,
    noMore: list.length < pageSize,
    nextPage: pageIndex + 1,
  };
};

const getQuickData = async () => {
  const result = await getQuickList({
    pageIndex: 1,
    pageSize: 100000,
  });
  return result.data?.data || [];
};

function CustomerServiceChat({ visible, onClose, selectData }) {
  const ref = useRef(null);
  const [status, setStatus] = useState('');
  const [inviteCode, setInviteCode] = useState('');
  const { data, loading, loadMore, loadingMore, noMore, reload, mutate } =
    useInfiniteScroll(
      (d) =>
        getContactList({
          pageIndex: d?.nextPage || 1,
          pageSize: 30,
          status,
          inviteCode,
        }),
      {
        target: ref,
        isNoMore: (d) => d?.noMore,
        reloadDeps: [status, inviteCode],
        manual: selectData,
      }
    );
  const { data: quickList } = useRequest(getQuickData);
  const [fold, setFold] = useState(false);
  const { showDetail, selectRecord, handleShowDetail, handleCloseDetail } =
    useDetailModal();
  const {
    showDetail: showAddOrderModal,
    selectRecord: addOrderRecord,
    handleShowDetail: handleShowAddOrderModal,
    handleCloseDetail: handleCloseAddOrderModal,
  } = useDetailModal();
  const [showAll, setShowAll] = useState(false);
  const {
    showDetail: showOrder,
    // selectRecord: selectTestRecord,
    handleShowDetail: handleShowOrder,
    handleCloseDetail: handleCloseOrder,
  } = useDetailModal();
  const [selectContact, setSelectContact] = useState({});
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    if (selectData) {
      setSelectContact({ ...selectData });
      mutate({
        list: [selectData],
      });
      setShowAll(true);
    }
  }, [selectData]);

  useEffect(() => {
    if (!selectContact.userId && data?.list) {
      setSelectContact(data.list[0]);
    }
  }, [data, selectContact]);

  const changeSelectReadStatus = () => {
    let needUpdate = false;
    data.list.forEach((item) => {
      if (item.userId === selectContact.userId && item.unreadNum) {
        item.unreadNum = 0;
        needUpdate = true;
      }
    });
    if (needUpdate) {
      mutate({
        ...data,
      });
    }
  };

  const handleShowUserDetail = async () => {
    handleShowDetail();
    const result = await getUserInfo({
      userId: selectContact.userId,
    });
    console.log(result);
    setUserInfo(result.data || {});
  };

  const handleAddOrder = async (values) => {
    const result = await compensateOrder({
      code: values.code,
      orderId: values.orderId,
    });
    if (result.data?.status) {
      message.success('补单成功');
      handleCloseAddOrderModal();
    } else {
      message.error(result.data?.msg || '补单失败');
    }
  };

  const handleChangePhone = async (val) => {
    const result = await changePhone({
      userId: val.userId,
      phone: val.phone,
    });
    if (result.code === 200) {
      message.success('操作成功');
      handleCloseDetail();
      setUserInfo({});
    }
  };

  return (
    <>
      <Modal
        visible
        footer=""
        width={1200}
        className="custom-service-chat"
        centered
        onCancel={onClose}
      >
        <div className="chat-wrap">
          <div className="chat-list">
            <div className="top-search">
              <Search
                placeholder="输入邀请码查找最近消息"
                onSearch={(value) => {
                  setInviteCode(value);
                  setShowAll(false);
                }}
                enterButton
              />
            </div>
            <div className="status-wrap">
              <Radio.Group
                defaultValue=""
                buttonStyle="solid"
                onChange={(e) => {
                  setStatus(e.target.value);
                  setShowAll(false);
                }}
              >
                <Radio.Button value="">全部</Radio.Button>
                <Radio.Button value="0">未处理</Radio.Button>
                <Radio.Button value="1">已处理</Radio.Button>
              </Radio.Group>
              <Tooltip placement="top" title="刷新订单列表">
                <RedoOutlined
                  style={{
                    color: 'red',
                    fontSize: '20px',
                    cursor: 'pointer',
                    marginLeft: '10px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onClick={reload}
                  spin={loading}
                />
              </Tooltip>
            </div>
            <div className="contact-list" ref={ref}>
              <Spin spinning={loading} delay={500}>
                {data?.list.length ? (
                  <>
                    {data.list.map((item) => (
                      <ChatItem
                        key={item.userId}
                        contact={item}
                        onSelect={setSelectContact}
                        selected={selectContact.userId === item.userId}
                      />
                    ))}
                    {showAll && (
                      <Button
                        type="link"
                        block
                        onClick={() => {
                          reload();
                          setShowAll(false);
                        }}
                      >
                        查看全部工单
                      </Button>
                    )}
                  </>
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </Spin>
              {loadingMore && (
                <div className="loading-more">
                  <LoadingOutlined />
                  <span>正在加载...</span>
                </div>
              )}
              {noMore && data?.list.length > 10 && (
                <div className="no-more">----已经到底啦----</div>
              )}
            </div>
          </div>
          {selectContact.userId ? (
            <>
              <div className={`chat-window-wrap ${fold ? 'grow-window' : ''}`}>
                <ChatWindow
                  contact={selectContact}
                  onShowOrder={handleShowOrder}
                  onShowDetail={handleShowUserDetail}
                  quickList={quickList}
                  refreshOrder={changeSelectReadStatus}
                />
              </div>
              <div className={`user-detail-wrap ${fold ? 'hidden' : ''}`}>
                <UserDetail
                  contact={selectContact}
                  onFold={() => setFold(!fold)}
                  fold={fold}
                />
              </div>
            </>
          ) : (
            <div />
          )}
        </div>
      </Modal>
      <OrderModal
        visible={showOrder}
        onClose={handleCloseOrder}
        contact={selectContact}
        onShowAddOrder={handleShowAddOrderModal}
      />
      <DetailModal
        columns={columns}
        // data={selectContact}
        data={userInfo}
        visible={showDetail}
        onClose={() => {
          handleCloseDetail();
          setUserInfo({});
        }}
        modalType="detail"
        title="用户详情"
        inline
        centered
        onOk={handleChangePhone}
      />
      <DetailModal
        columns={orderColumns}
        data={addOrderRecord}
        visible={showAddOrderModal}
        onClose={handleCloseAddOrderModal}
        title="补单"
        // inline
        centered
        onOk={handleAddOrder}
      />
    </>
  );
}

export default CustomerServiceChat;
