/* eslint-disable react/jsx-filename-extension */
import { Button, Popconfirm } from 'antd';
import { useEffect, useState } from 'react';
import { useDeepCompareEffect } from 'ahooks';

const useTableColumns = (
  COLUMNS,
  { onSave, controlColumns, delTitle, onDelete, onDetail, noDelete, noDetail }
) => {
  const [tableColumns, setTableColumns] = useState([]);

  useDeepCompareEffect(() => {
    const columns = COLUMNS.filter((item) => !item.notShowTable).map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          ...col,
          handleSave: onSave,
        }),
      };
    });

    columns.push(
      controlColumns || {
        title: '操作',
        key: 'action',
        width: 110,
        align: 'center',
        fixed: 'right',
        className: 'drag-visible',
        render: (text, record) => (
          <>
            {!noDetail && (
              <Button type="link" size="small" onClick={() => onDetail(record)}>
                查看
              </Button>
            )}
            {!noDelete && (
              <Popconfirm
                title={delTitle(record)}
                onConfirm={() => onDelete(record)}
                okText="确定"
                cancelText="取消"
              >
                <Button size="small" type="text" danger>
                  删除
                </Button>
              </Popconfirm>
            )}
          </>
        ),
      }
    );
    setTableColumns(columns);
  }, []);

  return {
    tableColumns,
    setTableColumns,
  };
};

export default useTableColumns;
