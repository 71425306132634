export const columns = [
  {
    title: '官网访问',
    dataIndex: 'officialVisit',
    key: 'officialVisit',
    fixed: 'left',
    align: 'center',
  },
  {
    title: '装机总数',
    dataIndex: 'totalInstall',
    key: 'totalInstall',
    align: 'center',
  },
  {
    title: '总活跃',
    dataIndex: 'totalActive',
    key: 'totalActive',
    align: 'center',
  },
  {
    title: '总充值',
    dataIndex: 'totalRecharge',
    key: 'totalRecharge',
    align: 'center',
  },
  {
    title: '总订单',
    dataIndex: 'totalOrder',
    key: 'totalOrder',
    align: 'center',
  },
  {
    title: '交易数',
    dataIndex: 'dealNumber',
    key: 'dealNumber',
    align: 'center',
  },
  {
    title: '平均付费率',
    dataIndex: 'averagePayRate',
    key: 'averagePayRate',
    align: 'center',
  },
  {
    title: '平均付费成功率',
    dataIndex: 'averagePayFinishRate',
    key: 'averagePayFinishRate',
    align: 'center',
  },
];
