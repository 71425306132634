import { Card, Spin, Empty } from 'antd';
import ReactECharts from 'echarts-for-react';
import { useRequest } from 'ahooks';
import { getInstallData } from '@/services/data';

const DEFUALT_VALUE = {
  tooltip: {
    trigger: 'item',
  },
  legend: {
    icon: 'rect',
  },
  series: [
    {
      type: 'pie',
      radius: '50%',
      data: [],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
    },
  ],
};

const genOption = (data) => {
  if (data) {
    return {
      ...DEFUALT_VALUE,
      series: [
        {
          ...DEFUALT_VALUE.series[0],
          data,
        },
      ],
    };
  }
  return DEFUALT_VALUE;
};

const getData = async () => {
  const result = await getInstallData();
  const data = result.data || {};
  const list = Object.keys(data).map((key) => ({
    value: data[key],
    name: key,
  }));
  return list;
};

function InstalledCapacity() {
  const { data, loading } = useRequest(getData);

  return (
    <Card title="装机分布">
      <Spin spinning={loading} delay={500}>
        {data?.length ? (
          <ReactECharts option={genOption(data)} style={{ height: '300px' }} />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Spin>
    </Card>
  );
}

export default InstalledCapacity;
