import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import './index.less';

function UserAvatars({ data = [] }) {
  return (
    <div className="user-avatars">
      {data.length
        ? data.map((item, index) => (
            <div key={item.actorId} className="avatar-item">
              <Avatar src={item.pictureUrl} icon={<UserOutlined />} />
              <span>{item.actorName}</span>
            </div>
          ))
        : '无'}
    </div>
  );
}

export default UserAvatars;
