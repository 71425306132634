/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-filename-extension */
import { Image, Space } from 'antd';

export const FILTER1_OPTIONS = [
  { value: 'all', name: '不限' },
  { value: 'invite', name: '只看文字贴' },
  { value: 'recharge', name: '只看有图片的贴' },
];
export const FILTER2_OPTIONS = [
  { value: 'all', name: '不限用户' },
  { value: 'invite', name: '只看片商作品' },
  { value: 'recharge', name: '只看普通用户作品' },
];

export const SORT_OPTIONS = [
  { value: '', name: '不限排序' },
  { value: 'passRate-DESC', name: '按通过率高低' },
  { value: 'contributionRate-DESC', name: '按利润率高低' },
  { value: 'createTime-ASC', name: '最旧提审' },
  { value: 'createTime-DESC', name: '最近提审' },
  { value: 'factoryGrade-DESC', name: '按片商等级高低' },
  // { value: 'bug', name: '按用户等级高低' },
];

export const COLUMNS = [
  {
    title: '花絮id',
    dataIndex: 'movieId',
    align: 'center',
    // ellipsis: true,
    width: 80,
    fixed: 'left',
    notShowForm: true,
  },
  {
    title: '片商名/等级',
    dataIndex: 'factory',
    align: 'center',
    notShowForm: true,
    render: (text) =>
      text ? `${text?.factoryName}/${text?.factoryGrade}` : '',
  },
  // {
  //   title: '用户名/等级',
  //   dataIndex: 'name',
  //   align: 'center',
  //   notShowForm: true,
  // },
  {
    title: '通过率',
    dataIndex: ['factory', 'passRate'],
    align: 'center',
    width: 60,
    notShowForm: true,
    render: (text) => (text || text === 0 ? `${text}%` : ''),
  },
  {
    title: '贡献利润率',
    dataIndex: ['factory', 'contributionRate'],
    align: 'center',
    width: 60,
    notShowForm: true,
    render: (text) => (text || text === 0 ? `${text}%` : ''),
  },
  {
    title: '花絮标题',
    dataIndex: 'movieName',
    align: 'center',
    width: 120,
    // ellipsisRow: 2,
    ellipsis: true,
    type: 'input',
    formEditable: true,
    rules: [
      { required: true, message: '请输入花絮标题' },
      { type: 'string', max: 27, message: '花絮标题长度不能超过27个字符' },
    ],
  },

  {
    title: '花絮封面',
    dataIndex: 'horCoverUrl',
    align: 'center',
    width: 110,
    type: 'image',
    render: (text) => <Image src={text} width="70%" />,
    valuePropName: 'fileList',
    formEditable: true,
    rules: [{ required: true, message: '请上传花絮封面' }],
  },
  {
    title: '相关作品',
    dataIndex: 'works',
    align: 'center',
    render: (text) =>
      (text || []).length ? (
        <Space wrap>
          {text.map((item) => (
            <span>《{item.name}》</span>
          ))}
        </Space>
      ) : (
        '无'
      ),
    formate: (text) =>
      (text || []).length ? (
        <Space wrap>
          {text.map((item) => (
            <span>《{item.name}》</span>
          ))}
        </Space>
      ) : (
        '无'
      ),
  },
  {
    title: '提审时间',
    dataIndex: 'createTime',
    align: 'center',
    width: 90,
    notShowForm: true,
    render: (text) => text.replace('T', ' '),
  },
];

export const TURN_DOWN_LABELS = [
  { value: '1', label: '广告传播' },
  { value: '2', label: '内容档次太低' },
  { value: '11', label: '恶意上传刷量，请尽快整改，否则封号处理' },
];
