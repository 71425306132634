/* eslint-disable react/jsx-filename-extension */
import { Image, Space, Button } from 'antd';

export const STATUS_MAP = {
  1: '申请中',
  2: '已处理',
  0: '已驳回',
};

export const SORT1_OPTIONS = [
  { value: '', name: '不限排序' },
  { value: 'createTime-ASC', name: '最旧提审' },
  { value: 'createTime-DESC', name: '最近提审' },
  { value: 'withdrawMoney-DESC', name: '按提现额高低' },
  { value: 'withdrawMoneyTotal-DESC', name: '按历史提现额高低' },
];
export const FILTER1_OPTIONS = [
  { value: '', name: '不限类型' },
  { value: 'type_1', name: '用户提现' },
  { value: 'type_2', name: '片商提现' },
  { value: 'type_3', name: '渠道提现' },
];
export const FILTER2_OPTIONS = [
  { value: '', name: '所有状态' },
  { value: 'status_1', name: '申请中' },
  { value: 'status_2', name: '已处理' },
  { value: 'status_0', name: '已驳回' },
];

const TYPE_MAP = {
  1: '用户提现',
  2: '片商提现',
  3: '渠道提现',
};

export const COLUMNS = [
  {
    title: '提现id',
    dataIndex: 'withdrawId',
    align: 'center',
    // ellipsis: true,
    width: 80,
    fixed: 'left',
  },
  {
    title: '片商名/等级',
    dataIndex: 'factory',
    align: 'center',
    render: (text, record) =>
      record ? `${record?.factoryName}/${record?.factoryGrade}` : '',
    format: (text, record) =>
      text ? `${text?.factoryName}/${text?.factoryGrade}` : '',
  },
  {
    title: '渠道名',
    dataIndex: 'channelName',
    align: 'center',
    // notShowForm: true,
    // render: (text,record) => record?.channelName || '',
    // format: (text,record) => record?.channelName || '',
  },
  {
    title: '用户名/等级',
    dataIndex: 'userName',
    align: 'center',
    // notShowForm: true,
    render: (text, record) =>
      record
        ? `${record?.userName || ''}/${
            record?.userMemberLevel ? `v${record?.userMemberLevel}` : ''
          }`
        : '',
    format: (text, record) =>
      record
        ? `${record?.userName || ''}/${
            record?.userMemberLevel ? `v${record?.userMemberLevel}` : ''
          }`
        : '',
  },
  {
    title: '类型',
    dataIndex: 'type',
    align: 'center',
    width: 70,
    render: (text) => TYPE_MAP[text] || '',
    format: (text) => TYPE_MAP[text] || '',
  },
  {
    title: '申请提现金额',
    dataIndex: 'withdrawMoney',
    align: 'center',
  },
  {
    title: '提现手续费',
    dataIndex: 'poundage',
    align: 'center',
  },

  {
    title: '提现账户',
    dataIndex: 'accountNum',
    align: 'center',
    width: 205,
    render: (text, record) => (
      <>
        <span>{text}</span>
        <Button
          type="link"
          size="small"
          style={{ fontSize: '12px' }}
          id={`accountNum-${record.withdrawId}-copy`}
        >
          复制
        </Button>
      </>
    ),
    suffix: '复制',
    suffixType: 'copy',
  },
  {
    title: '提现人',
    dataIndex: 'payee',
    align: 'center',
    width: 120,
    render: (text, record) => (
      <>
        <span>{text}</span>
        <Button
          type="link"
          size="small"
          style={{ fontSize: '12px' }}
          id={`payee-${record.withdrawId}-copy`}
        >
          复制
        </Button>
      </>
    ),
    suffix: '复制',
    suffixType: 'copy',
  },
  {
    title: '历史提现额',
    dataIndex: 'withdrawMoneyTotal',
    align: 'center',
    width: 80,
  },
  {
    title: '历史提现数',
    dataIndex: 'withdrawCount',
    align: 'center',
    width: 80,
  },
  {
    title: '提现前余额',
    dataIndex: 'wallet',
    align: 'center',
    width: 80,
  },
  {
    title: '提现创建时间',
    dataIndex: 'createTime',
    align: 'center',
    width: 90,
  },
  {
    title: '状态',
    dataIndex: 'status',
    align: 'center',
    width: 60,
    render: (text, record) => STATUS_MAP[text],
    format: (text, record) => STATUS_MAP[text],
  },
];

export const TURN_DOWN_LABELS = [
  { value: '1', label: '提现不符合规则' },
  { value: '2', label: '提现信息错误' },
];
