export const allColumns = [
  {
    title: '官网访问',
    dataIndex: 'officialVisit',
    key: 'officialVisit',
    fixed: 'left',
    align: 'center',
  },
  {
    title: '装机总数',
    dataIndex: 'totalInstall',
    key: 'totalInstall',
    align: 'center',
  },
  {
    title: '官方装机',
    dataIndex: 'officialInstall',
    key: 'officialInstall',
    align: 'center',
  },
  {
    title: '渠道装机',
    dataIndex: 'placeInstall',
    key: 'placeInstall',
    align: 'center',
  },
  {
    title: '裂变装机',
    dataIndex: 'fissionInstall',
    key: 'fissionInstall',
    align: 'center',
  },
  {
    title: '装机转化率',
    dataIndex: 'installConvertRate',
    key: 'installConvertRate',
    align: 'center',
    render: (text) => `${text}%`,
  },
  {
    title: '装机成功率',
    dataIndex: 'installFinishRate',
    key: 'installFinishRate',
    align: 'center',
    render: (text) => `${text}%`,
  },
  {
    title: '裂变新增占比',
    dataIndex: 'fissionAddRate',
    key: 'fissionAddRate',
    align: 'center',
    render: (text) => `${text}%`,
  },
  {
    title: '裂变活跃占比',
    dataIndex: 'fissionActiveRate',
    key: 'fissionActiveRate',
    align: 'center',
    render: (text) => `${text}%`,
  },
];
export const androidColumns = [
  {
    title: '装机总数',
    dataIndex: 'totalInstall',
    key: 'totalInstall',
    fixed: 'left',
    align: 'center',
  },
  {
    title: '官方装机',
    dataIndex: 'officialInstall',
    key: 'officialInstall',
    align: 'center',
  },
  {
    title: '渠道装机',
    dataIndex: 'placeInstall',
    key: 'placeInstall',
    align: 'center',
  },
  {
    title: '裂变装机',
    dataIndex: 'fissionInstall',
    key: 'fissionInstall',
    align: 'center',
  },
  {
    title: '日活跃',
    dataIndex: 'dayActive',
    key: 'dayActive',
    align: 'center',
  },
  {
    title: '装机成功率',
    dataIndex: 'installFinishRate',
    key: 'installFinishRate',
    align: 'center',
    render: (text) => `${text}%`,
  },
  {
    title: '裂变新增占比',
    dataIndex: 'fissionAddRate',
    key: 'fissionAddRate',
    align: 'center',
    render: (text) => `${text}%`,
  },
  {
    title: '分享次数/分享率',
    dataIndex: 'share',
    key: 'share',
    align: 'center',
    render: (text, record) => `${record.shareNum}/${record.shareRate}%`,
  },
];
export const iosColumns = [
  {
    title: '装机总数',
    dataIndex: 'totalInstall',
    key: 'totalInstall',
    fixed: 'left',
    align: 'center',
  },
  {
    title: '官方装机',
    dataIndex: 'officialInstall',
    key: 'officialInstall',
    align: 'center',
  },
  {
    title: '渠道装机',
    dataIndex: 'placeInstall',
    key: 'placeInstall',
    align: 'center',
  },
  {
    title: '裂变装机',
    dataIndex: 'fissionInstall',
    key: 'fissionInstall',
    align: 'center',
  },
  {
    title: '日活跃',
    dataIndex: 'dayActive',
    key: 'dayActive',
    align: 'center',
  },
  {
    title: '装机成功率',
    dataIndex: 'installFinishRate',
    key: 'installFinishRate',
    align: 'center',
    render: (text) => `${text}%`,
  },
  {
    title: '裂变新增占比',
    dataIndex: 'fissionAddRate',
    key: 'fissionAddRate',
    align: 'center',
    render: (text) => `${text}%`,
  },
  {
    title: '分享次数/分享率',
    dataIndex: 'share',
    key: 'share',
    align: 'center',
    render: (text, record) => `${record.shareNum}/${record.shareRate}%`,
  },
];
