export const columns = [
  {
    title: '主题',
    dataIndex: 'theme',
    width: 200,
    align: 'center',
  },
  {
    title: '回复话术',
    dataIndex: 'content',
    align: 'center',
  },
];
