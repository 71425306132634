/* eslint-disable react/jsx-filename-extension */
import { Button, Switch, Space } from 'antd';
import { ResetPassword } from '@/components';

/**
 * 0-默认全部用户，默认
 * 1-只看VIP用户
 * 2-只看官方邀请用户
 * 3-只看渠道邀请用户
 * 4-只看好友邀请用户
 */
export const FILTER1_OPTIONS = [
  { value: 0, name: '全部用户' },
  { value: 1, name: '只看VIP用户' },
  { value: 2, name: '只看官方邀请用户' },
  { value: 3, name: '只看渠道邀请用户' },
  { value: 4, name: '只看好友邀请用户' },
];

/**
 * 0-不限用户，默认
 * 1-只看今日看过片
 * 2-只看3日看过片
 * 3-只看7日看过片
 * 4-只看超7日未看过片
 * 5-只看超1月未看过片
 */
export const FILTER2_OPTIONS = [
  { value: 0, name: '不限用户' },
  { value: 1, name: '只看今日看过片' },
  { value: 2, name: '只看3日看过片' },
  { value: 3, name: '只看7日看过片' },
  { value: 4, name: '只看超7日未看过片' },
  { value: 5, name: '只看超1月未看过片' },
];

/**
 * 0-不限排序，默认
 * 1-邀请最多
 * 2-充值最多
 * 3-VIP最久
 * 4-金币最多
 * 5-余额最多
 * 6-提现最多
 */
export const SORT_OPTIONS = [
  { value: 0, name: '不限排序' },
  { value: 1, name: '邀请最多' },
  { value: 2, name: '充值最多' },
  { value: 3, name: 'VIP最久' },
  { value: 4, name: '金币最多' },
  { value: 5, name: '余额最多' },
  { value: 6, name: '提现最多' },
];

const USER_SOURCE = {
  1: '官方装机',
  2: '好友邀请',
  3: '渠道邀请',
  4: '片商邀请',
};

const OS_MAP = {
  1: '安卓',
  2: '苹果',
};

const TYPE_MAP = {
  '-1': '游客',
  0: '临时会员',
};

export const COLUMNS = [
  // {
  //   title: '用户id',
  //   dataIndex: 'userId',
  //   fixed: 'left',
  //   align: 'center',
  // },
  {
    title: '邀请码',
    width: 60,
    dataIndex: 'inviteCode',
    fixed: 'left',
    align: 'center',
  },
  {
    title: '用户名',
    dataIndex: 'username',
    align: 'center',
    ellipsis: true,
  },
  {
    title: '重置密码',
    dataIndex: 'inviteCode',
    notShowTable: true,
    format: (text, record) => <ResetPassword userId={record.userId} />,
  },
  {
    title: '用户身份',
    dataIndex: 'memberLevel',
    width: 80,
    align: 'center',
    format: (text) => TYPE_MAP[text] || `V${text}`,
    render: (text) => TYPE_MAP[text] || `V${text}`,
  },
  {
    title: '手机号',
    dataIndex: 'phone',
    align: 'center',
    width: 110,
  },
  {
    title: '邀请人数',
    width: 70,
    dataIndex: 'inviteNumber',
    align: 'center',
  },

  // {
  //   title: '等级',
  //   width: 50,
  //   dataIndex: 'memberLevel',
  //   align: 'center',
  //   render: (text) => (text === -1 ? '游客' : `V${text}`),
  //   format: (text) => (text === -1 ? '游客' : `V${text}`),
  // },

  {
    title: '每日最大观影次数',
    dataIndex: 'dayWatchNumber',
    width: 120,
    editable: true,
    align: 'center',
    type: 'inputNumber',
    // rules: [{ required: true, message: '请输入每日最大观影次数' }],
  },
  {
    title: '今日剩余观影次数',
    dataIndex: 'dayWatchNumberLefted',
    width: 120,
    editable: true,
    align: 'center',
    type: 'inputNumber',
    // rules: [{ required: true, message: '请输入今日剩余观影次数' }],
  },
  {
    title: 'vip剩余天数',
    dataIndex: 'memberLeftDays',
    align: 'center',
    width: 90,
  },
  // {
  //   title: 'VIP到期时间',
  //   dataIndex: 'memberFinalDate',
  //   width: 170,
  //   editable: true,
  //   align: 'center',
  //   type: 'date',
  //   notShowTime: true,
  //   // rules: [{ required: true, message: '请选择VIP到期时间' }],
  // },
  {
    title: '临时VIP到期时间',
    dataIndex: 'v0Time',
    notShowTable: true,
    format: (text, record) => {
      const data = record.memberInfo?.find((item) => item.level === 0);
      return data?.finalDate || '无';
    },
  },
  {
    title: 'VIP1到期时间',
    dataIndex: 'v1Time',
    notShowTable: true,
    format: (text, record) => {
      const data = record.memberInfo?.find((item) => item.level === 1);
      return data?.finalDate || '无';
    },
  },
  {
    title: 'VIP2到期时间',
    dataIndex: 'v2Time',
    notShowTable: true,
    format: (text, record) => {
      const data = record.memberInfo?.find((item) => item.level === 2);
      return data?.finalDate || '无';
    },
  },
  {
    title: 'VIP3到期时间',
    dataIndex: 'v3Time',
    notShowTable: true,
    format: (text, record) => {
      const data = record.memberInfo?.find((item) => item.level === 3);
      return data?.finalDate || '无';
    },
  },

  {
    title: '金币/余额/已提现',
    dataIndex: 'money',
    align: 'center',
    width: 120,
    render: (text, record) =>
      `${record.coin || 0}/${record.money || 0}/${record.withdraw || 0}`,
    format: (text, record) =>
      `${record.coin || 0}/${record.money || 0}/${record.withdraw || 0}`,
  },
  {
    title: '装机时间',
    width: 150,
    align: 'center',
    dataIndex: 'installDate',
  },
  {
    title: '装机来源',
    dataIndex: 'joinSource',
    align: 'center',
    width: 70,
    render: (text) => USER_SOURCE[text],
    format: (text) => USER_SOURCE[text],
  },
  {
    title: '运行系统',
    dataIndex: 'os',
    align: 'center',
    width: 70,
    render: (text) => OS_MAP[text],
    format: (text) => OS_MAP[text],
  },

  {
    title: '上次登录时间',
    width: 150,
    align: 'center',
    dataIndex: 'lastLoginDate',
  },
  {
    title: '签名',
    notShowTable: true,
    dataIndex: 'sign',
  },
  {
    title: '是否禁用',
    width: 70,
    align: 'center',
    dataIndex: 'disable',
    editable: true,
    type: 'switch',
    valuePropName: 'checked',
    // render: (text) => <Switch checked={!!text}  />,
    detailNotEditable: true,
    format: (text) => (text ? '是' : '否'),
  },
];
