import { Form, Input, InputNumber, Select, Image, Button } from 'antd';

const COIN_DISCOUNT_LIST = [
  {
    value: 0,
    label: '0折',
  },
  {
    value: 1,
    label: '1折',
  },
  {
    value: 2,
    label: '2折',
  },
  {
    value: 3,
    label: '3折',
  },
  {
    value: 4,
    label: '4折',
  },
  {
    value: 5,
    label: '5折',
  },
  {
    value: 7,
    label: '7折',
  },
  {
    value: 8,
    label: '8折',
  },
  {
    value: 9,
    label: '9折',
  },
  {
    value: 10,
    label: '10折',
  },
];

const PRODUCT_TYPE_LIST = [
  {
    value: 1,
    label: 'VIP',
  },
  {
    value: 2,
    label: '金币',
  },
];

const { Option } = Select;

export const COLUMNS = [
  {
    title: '商品名称',
    dataIndex: 'name',
    align: 'center',
    width: 120,
    ellipsis: true,
    editable: true,
    type: 'input',
    rules: [{ required: true, message: '请输入商品名称' }],
  },
  {
    title: '商品图片',
    dataIndex: 'pictureUrl',
    align: 'center',
    width: 150,
    editable: true,
    type: 'image',
    valuePropName: 'fileList',
    rules: [{ required: true, message: '请上传商品图片' }],
    uploadUrl: '/manage/config/commodity/image/upload',
  },
  {
    title: '商品排序',
    dataIndex: 'order',
    align: 'center',
    editable: true,
    type: 'inputNumber',
    width: 110,
    rules: [{ required: true, message: '请输入商品排序' }],
  },
  {
    title: '商品类型',
    dataIndex: 'type',
    align: 'center',
    width: 80,
    formEditable: true,
    detailNotEditable: true,
    type: 'select',
    rules: [{ required: true, message: '请选择商品类型' }],
    selectList: PRODUCT_TYPE_LIST,
    render: (text, record, index) => {
      const type = PRODUCT_TYPE_LIST.find((item) => item.value === text);
      return type ? type.label : '';
    },
    format: (text, record, index) => {
      const type = PRODUCT_TYPE_LIST.find((item) => item.value === text);
      return type ? type.label : '';
    },
  },
  {
    title: '商品描述',
    dataIndex: 'des',
    align: 'center',
    ellipsisRow: 2,
    editable: true,
    type: 'textarea',
    rules: [{ required: true, message: '请输入商品描述' }],
  },
  {
    title: '商品价格',
    dataIndex: 'price',
    align: 'center',
    width: 110,
    editable: true,
    type: 'inputNumber',
    rules: [{ required: true, message: '请输入商品价格' }],
    render: (text, record) => `${text}元`,
  },
  {
    title: 'usdt价格',
    dataIndex: 'usdtPrice',
    align: 'center',
    width: 110,
    editable: true,
    type: 'inputNumber',
    rules: [{ required: true, message: '请输入usdt价格' }],
  },
  {
    title: '会员天数',
    dataIndex: 'days',
    align: 'center',
    width: 100,
    editable: true,
    type: 'inputNumber',
    rules: [{ required: true, message: '请输入会员天数,永久请输入-1' }],
    placeholder: '请输入会员天数,永久请输入-1',
    min: -1,
    render: (text) => (text === -1 ? '永久' : `${text}天`),
  },
  {
    title: '金币数量',
    dataIndex: 'goldCoin',
    align: 'center',
    width: 110,
    editable: true,
    type: 'inputNumber',
    rules: [{ required: true, message: '请输入金币数量' }],
  },
  {
    title: '金币消耗折扣',
    dataIndex: 'goldCoinDiscount',
    align: 'center',
    width: 100,
    // ellipsis: true,
    render: (text, record, index) => {
      const discount = COIN_DISCOUNT_LIST.find((item) => item.value === text);
      return discount ? discount.label : '';
    },
    editable: true,
    type: 'select',
    rules: [{ required: true, message: '请选择金币消耗折扣' }],
    selectList: COIN_DISCOUNT_LIST,
    notShowForm: true,
  },
  {
    title: '金币消耗折扣',
    dataIndex: 'goldCoinDiscount',
    notShowTable: true,
    customForm: true,
    conditionForm: true,
    format: (text, record) => (
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.type !== currentValues.type
        }
      >
        {({ getFieldValue }) => {
          const type = getFieldValue('type');
          return (
            type === 1 && ( // 1VIP 2金币
              <Form.Item
                label="金币消耗折扣"
                name="goldCoinDiscount"
                rules={[{ required: true, message: '请选择金币消耗折扣' }]}
              >
                <Select placeholder="请选择金币消耗折扣">
                  {COIN_DISCOUNT_LIST.map((item) => (
                    <Option value={item.value} key={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )
          );
        }}
      </Form.Item>
    ),
  },
  {
    title: '是否启用',
    dataIndex: 'status',
    align: 'center',
    editable: true,
    type: 'switch',
    valuePropName: 'checked',
    width: 70,
    rules: [{ required: true, message: '请选择是否启用' }],
  },
];
