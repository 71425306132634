import { Modal, Form, Select, DatePicker, Switch } from 'antd';
import moment from 'moment';
import { useRef, useEffect, useState } from 'react';
import { getVideoByDate } from '@/services/business-management/filmingManagement';

import { ConditionForm } from './components';

import './index.less';

const royaltyRateMap = {
  0: 15,
  1: 18,
  2: 25,
  3: 30,
};

const { Option } = Select;

function ApprovalPassModal({
  topContent = '',
  visible,
  data,
  onClose,
  onOk,
  type,
}) {
  const [form] = Form.useForm();
  const init = useRef(null);
  const [total, setTotal] = useState(0);
  const customFormat = (value) =>
    value ? `${value.format('YYYY-MM-DD')}（当日已排片${total}部）` : '';

  useEffect(() => {
    if (visible) {
      console.log(data);
      const formData = { ...data };
      formData.publish = false;
      formData.royaltyRate = royaltyRateMap[data.factory?.factoryGrade || 0];
      if (formData.onlineTime) {
        formData.onlineTime = moment(formData.onlineTime);
      }
      form.setFieldsValue(formData);
    } else if (init.current) {
      form.resetFields();
      const ele = document.querySelector(
        '.approval-pass-modal .ant-modal-body'
      );
      ele.scrollTo(0, 0);
    }
    init.current = true;
  }, [visible, data]);

  const handleOk = async () => {
    const values = await form.validateFields();
    console.log(values);
    if (values.onlineTime) {
      values.onlineTime = values.onlineTime.format('YYYY-MM-DD');
    }

    onOk(values);
    // onClose();
  };

  const handleChangedate = async (date) => {
    const result = await getVideoByDate({
      y: date.get('year'), // 年
      m: date.get('month') + 1, // 月
      d: date.get('date'), // 日
    });
    const data = result.data || {};
    setTotal(data.total || 0);
  };

  return (
    <Modal
      title="通过确认"
      visible={visible}
      className="approval-pass-modal"
      width={580}
      onOk={handleOk}
      onCancel={onClose}
      zIndex={1001}
    >
      <div className="top-content">{topContent}</div>
      <Form layout="vertical" form={form}>
        {(type === 'long-video' || type === 'short-video') && (
          <>
            <Form.Item
              name="cooperationType"
              label="合作类型"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select placeholder="请选择合作类型">
                <Option value={1}>分成合作</Option>
                <Option value={2}>买断合作</Option>
                <Option value={3}>免费合作</Option>
              </Select>
            </Form.Item>
            <ConditionForm name="cooperationType" />
          </>
        )}

        {(type === 'long-video' || type === 'short-video') && (
          <>
            <Form.Item
              name="videoAttribute"
              label="设置该片面向用户的类型与售价"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select placeholder="请选择影片类型">
                <Option value={2}>金币片</Option>
                <Option value={1}>会员片</Option>
                <Option value={3}>永久免费</Option>
                <Option value={4}>限时免费</Option>
              </Select>
            </Form.Item>
            <ConditionForm name="videoAttribute" />
          </>
        )}
        {/* {type === 'sticker' && (
          <>
            <Form.Item
              name="videoAttribute"
              label="设置该动态贴向用户的类型与售价"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select placeholder="请选择动态贴类型">
                <Option value={2}>消耗金币</Option>
                <Option value={1}>会员可见</Option>
                <Option value={3}>免费</Option>
              </Select>
            </Form.Item>
            <ConditionForm name="videoAttribute" type="sticker" />
          </>
        )} */}

        {/* {type === 'long-video' && (
          <>
            <Form.Item
              name="onlineTime"
              label="设置排片日期"
              rules={[
                {
                  required: true,
                  message: '请设置排片日期',
                },
              ]}
            >
              <DatePicker
                style={{ width: '100%' }}
                format={customFormat}
                onChange={handleChangedate}
              />
            </Form.Item>
            <Form.Item
              name="timeStep"
              label="选择上映时间段"
              rules={[
                {
                  required: true,
                  message: '请选择上映时间段',
                },
              ]}
            >
              <Select placeholder="请选择上映时间段">
                <Option value={12}>白天上映</Option>
                <Option value={20}>晚间上映</Option>
                <Option value={0}>凌晨上映</Option>
              </Select>
            </Form.Item>
          </>
        )} */}

        {/* <Form.Item
          name="publish"
          label="是否直接发布"
          rules={[
            {
              required: true,
              message: '请选择是否直接发布',
            },
          ]}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item> */}
      </Form>
    </Modal>
  );
}

export default ApprovalPassModal;
