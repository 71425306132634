/* eslint-disable eqeqeq */
import { useState } from 'react';
import { DatePicker, Form, InputNumber, Tag } from 'antd';

import moment from 'moment';

import { FormatPainterOutlined } from '@ant-design/icons';
import { getVideoByDate } from '@/services/business-management/filmingManagement';
import { COOPERATION_MAP, getTagList } from '../commonConfig';

export const FILTER1_OPTIONS = [
  { value: '', name: '不限类型' },
  { value: 'videoAttribute_4_number', name: '只看限免' },
  { value: 'videoAttribute_3', name: '只看永久免费' },
  { value: 'videoAttribute_2', name: '只看金币' },
  { value: 'videoAttribute_1', name: '只看VIP' },
];
export const FILTER2_OPTIONS = [
  { value: '', name: '全部作品' },
  { value: 'putaway_false', name: '只看未上架作品' },
  { value: 'putaway_true', name: '只看已上架作品' },
  { value: 'online_true', name: '只看公映作品' },
  { value: 'online_false', name: '只看未公映作品' },
  // { value: 'videoAttribute_1', name: '只看片商作品' },
  // { value: 'videoAttribute_1', name: '只看普通用户作品' },
];
export const FILTER3_OPTIONS = [
  { value: '', name: '不限合作类型' },
  { value: 'cooperationType_3', name: '免费' },
  { value: 'cooperationType_1', name: '分成' },
  { value: 'cooperationType_2', name: '买断' },
];
export const SORT1_OPTIONS = [
  { value: '', name: '不限排序' },
  { value: 'watchs-DESC', name: '按播放量高低' },
  { value: 'collect-DESC', name: '按收藏量高低' },
  { value: 'goldNumTotal-DESC', name: '按已赚金币高低' },
  { value: 'factoryGrade-DESC', name: '按片商等级高低' },
  // { value: 'cooperationType_2', name: '按用户等级高低' },
];

// export const COOPERATION_MAP = {
//   1: '分成',
//   2: '买断',
//   3: '免费',
// };

const COOPERATION_PRICE_KEY = {
  1: {
    key: 'royaltyRate',
    unit: '%',
  },
  2: {
    key: 'buyout',
    unit: '元',
  },
};

const TIME_STEP_LIST = [
  { value: 12, label: '白天上映' },
  { value: 20, label: '晚间上映' },
  { value: 0, label: '凌晨上映' },
];

export const COLUMNS = [
  {
    title: '影片id',
    dataIndex: 'movieId',
    align: 'center',
    // ellipsis: true,
    width: 80,
    fixed: 'left',
    notShowForm: true,
    render: (text, record) =>
      record.examineAgain ? <span style={{ color: 'red' }}>{text}</span> : text,
  },
  {
    title: '片商名/等级',
    dataIndex: 'factory',
    align: 'center',
    notShowForm: true,
    render: (text) =>
      text ? `${text?.factoryName}/${text?.factoryGrade}` : '',
  },
  // {
  //   title: '用户名/等级',
  //   dataIndex: 'user',
  //   align: 'center',
  //   notShowForm: true,
  //   render: (text) => '',
  // },
  {
    title: '影片标题',
    dataIndex: 'movieName',
    align: 'center',
    width: 120,
    // ellipsisRow: 2,
    // formEditable: true,
    editable: true,
    type: 'input',
    rules: [
      { required: true, message: '请输入作品名' },
      { type: 'string', max: 27, message: '作品名长度不能超过27个字符' },
    ],
    formSort: 1,
  },
  {
    title: '影片介绍',
    dataIndex: 'des',
    align: 'center',
    width: 120,
    ellipsisRow: 2,
    // formEditable: true,
    editable: true,
    type: 'textarea',
    rules: [
      { required: true, message: '请输入影片介绍' },
      { type: 'string', max: 100, message: '影片介绍长度不能超过100个字符' },
    ],
    formSort: 2,
  },
  {
    title: '所属专题',
    dataIndex: 'subjectId',
    align: 'center',
    render: (_, record) => record.subjectName,
    formEditable: true,
    type: 'labelSelect',
    labelList: getTagList(1),
    labelSelectMode: 'single',
    formSort: 12,
  },
  {
    title: '是否全网首发',
    dataIndex: 'publish',
    align: 'center',
    render: (text) => (text ? '是' : '否'),
    notShowForm: true,
  },
  // {
  //   title: '排片日期',
  //   dataIndex: 'onlineTime',
  //   align: 'center',
  //   width: 115,
  //   // ellipsisRow: 2,
  //   // formEditable: true,
  //   editable: true,
  //   notShowForm: true,
  //   type: 'date',
  //   rules: [{ required: true, message: '请选择排片日期' }],
  //   notShowTime: true,
  //   render: (text) => {
  //     const isBefore = moment().isBefore(text, 'day');

  //     return text ? (
  //       <span>
  //         {text}
  //         <br />

  //         {isBefore ? (
  //           <Tag color="default">未公映</Tag>
  //         ) : (
  //           <Tag color="success">已公映</Tag>
  //         )}
  //       </span>
  //     ) : (
  //       ''
  //     );
  //   },
  // },
  // {
  //   title: '排片日期',
  //   dataIndex: 'onlineTime',
  //   notShowTable: true,
  //   customForm: true,
  //   type: 'date',
  //   formate: (text, record) => {
  //     const [total, setTotal] = useState(0);
  //     const customFormat = (value) =>
  //       value ? `${value.format('YYYY-MM-DD')}（当日已排片${total}部）` : '';
  //     const handleChangedate = async (date) => {
  //       const result = await getVideoByDate({
  //         y: date.get('year'), // 年
  //         m: date.get('month') + 1, // 月
  //         d: date.get('date'), // 日
  //       });
  //       const data = result.data || {};
  //       setTotal(data.total || 0);
  //     };
  //     return (
  //       <Form.Item noStyle>
  //         <Form.Item
  //           noStyle
  //           name="onlineTime"
  //           rules={[{ required: true, message: '请选择排片日期' }]}
  //         >
  //           <DatePicker
  //             format={customFormat}
  //             allowClear={false}
  //             onChange={handleChangedate}
  //             style={{ width: '260px' }}
  //           />
  //         </Form.Item>
  //         {!!record.onlineTime &&
  //           (moment().isBefore(record.onlineTime, 'day') ? (
  //             <Tag color="default" style={{ marginLeft: '10px' }}>
  //               未公映
  //             </Tag>
  //           ) : (
  //             <Tag color="success" style={{ marginLeft: '10px' }}>
  //               已公映
  //             </Tag>
  //           ))}
  //       </Form.Item>
  //     );
  //   },
  // },
  // {
  //   title: '上映时间段',
  //   dataIndex: 'timeStep',
  //   type: 'select',
  //   editable: true,
  //   selectList: TIME_STEP_LIST,
  //   rules: [{ required: true, message: '请选择上映时间段' }],
  //   render: (text) => {
  //     const data = TIME_STEP_LIST.find((item) => item.value === text);
  //     return data?.label || '';
  //   },
  // },
  {
    title: '视频对外类型',
    dataIndex: 'videoAttribute',
    align: 'center',
    width: 170,
    formEditable: true,
    // editable: true,
    type: 'select',
    selectList: [
      { value: 1, label: '会员片' },
      { value: 2, label: '金币片' },
      { value: 3, label: '永久免费' },
      { value: 4, label: '限时免费' },
    ],
    rules: [{ required: true, message: '请选择视频对外类型' }],
    render: (text, record) => {
      if (text == 1) {
        // 1vip视频，2金币视频 3免费
        return '会员片';
      }
      if (text == 2) {
        return `金币片/${record.moviePrice || 0}金币`;
      }
      if (text == 3) {
        return '永久免费';
      }
      if (text == 4) {
        return `限免${record.freeDays}天/${record.freeDateTime}到期`;
      }
      return '未设置';
    },
    formSort: 7,
  },
  {
    title: '限时免费天数',
    dataIndex: 'freeDays',
    notShowTable: true,
    conditionForm: true,
    formate: (text, record) => (
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.videoAttribute !== currentValues.videoAttribute
        }
      >
        {({ getFieldValue }) => {
          const videoAttribute = getFieldValue('videoAttribute');
          return (
            videoAttribute === 4 && (
              <Form.Item
                label="限时免费天数"
                name="freeDays"
                rules={[{ required: true, message: '请设置限时免费天数' }]}
              >
                <InputNumber min={0} />
              </Form.Item>
            )
          );
        }}
      </Form.Item>
    ),
    formSort: 8,
  },
  {
    title: '金币售价',
    dataIndex: 'moviePrice',
    notShowTable: true,
    conditionForm: true,
    formate: () => (
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.videoAttribute !== currentValues.videoAttribute
        }
      >
        {({ getFieldValue }) => {
          const videoAttribute = getFieldValue('videoAttribute');
          return (
            videoAttribute === 2 && (
              <Form.Item
                label="金币售价"
                name="moviePrice"
                rules={[{ required: true, message: '请选择限时免费到期时间' }]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            )
          );
        }}
      </Form.Item>
    ),
    formSort: 9,
  },
  {
    title: '播放量',
    dataIndex: 'watchs',
    align: 'center',
    width: 70,
    // formEditable: true,
    editable: true,
    type: 'inputNumber',
    rules: [{ required: true, message: '请输入播放量' }],
    formSort: 10,
  },
  {
    title: '收藏量',
    dataIndex: 'collect',
    align: 'center',
    width: 70,
    // formEditable: true,
    editable: true,
    type: 'inputNumber',
    rules: [{ required: true, message: '请输入收藏量' }],
    formSort: 11,
  },
  {
    title: '已赚金币',
    dataIndex: 'goldNumTotal',
    align: 'center',
    width: 70,
    notShowForm: true,
  },
  {
    title: '是否推荐到每日主打',
    width: 90,
    dataIndex: 'recommend',
    align: 'center',
    editable: true,
    required: true,
    type: 'switch',
    valuePropName: 'checked',
    switchText: ['已推荐', '未推荐'],
    formSort: 5,
  },
  {
    title: '是否上架',
    width: 90,
    align: 'center',
    dataIndex: 'onShelf',
    editable: true,
    type: 'switch',
    valuePropName: 'checked',
    switchText: ['已上架', '未上架'],
    formSort: 6,
  },
  {
    title: '是否审批失败',
    width: 70,
    align: 'center',
    dataIndex: 'examineAgain',
    notShowForm: true,
    render: (text) => (text ? '是' : '否'),
  },
  {
    title: '影片时长/大小',
    dataIndex: 'movieDuration',
    align: 'center',
    width: 120,
    render: (text, record) =>
      `${Math.ceil(record.movieDuration / 60)}分钟/${Math.ceil(
        record.movieSize / 1024
      )}mb`,
    notShowForm: true,
  },
  {
    title: '横封面',
    dataIndex: 'horCoverUrl',
    align: 'center',
    width: 140,
    type: 'image',
    editable: true,
    // render: (text) => <Image src={text} width="70%" />,
    valuePropName: 'fileList',
    // formEditable: true,
    rules: [{ required: true, message: '请上传横封面' }],
    uploadUrl: '/manage/file/local/upload',
    formSort: 3,
  },
  {
    title: '竖封面',
    dataIndex: 'verCoverUrl',
    align: 'center',
    width: 120,
    editable: true,
    type: 'image',
    // render: (text) => <Image src={text} width="60%" />,
    imageType: 'vertical',
    valuePropName: 'fileList',
    // formEditable: true,
    // formDetail
    rules: [{ required: true, message: '请上传竖封面' }],
    uploadUrl: '/manage/file/local/upload',
    formSort: 4,
  },
  {
    title: '合作类型',
    dataIndex: 'cooperationType',
    align: 'center',
    width: 70,
    render: (text) => COOPERATION_MAP[text],
    formate: (text, data) => {
      let type = COOPERATION_MAP[text];
      const price = COOPERATION_PRICE_KEY[text];
      if (price) {
        type = `${type}/${data[price.key]}${price.unit}`;
      }
      return <div>与上传方的合作类型为{type}</div>;
    },
    formSort: 16,
  },
  {
    title: '买断价格',
    dataIndex: 'buyout',
    align: 'center',
    width: 70,
    render: (text) => (text ? `${text}元` : ''),
    notShowForm: true,
  },
  {
    title: '分成比例',
    dataIndex: 'royaltyRate',
    align: 'center',
    width: 70,
    render: (text) => (text ? `${text}%` : ''),
    notShowForm: true,
  },
  {
    title: '审批通过时间',
    dataIndex: 'examineTime',
    align: 'center',
    width: 100,
    render: (text) => (text ? text.replace('T', ' ') : ''),
    notShowForm: true,
  },
  {
    title: '导演/演员',
    dataIndex: 'actors',
    align: 'center',
    width: 120,
    ellipsisRow: 2,
    type: 'avatar',
    notShowTable: true,
    formSort: 13,
  },
  {
    title: '影片标签',
    dataIndex: 'tagsList',
    notShowTable: true,
    type: 'tag',
    render: (text) => <div className="des">{(text || []).join('、')}</div>,
    formSort: 15,
  },
  {
    title: '地区标签',
    dataIndex: 'address',
    notShowTable: true,
    formEditable: false,
    type: 'tag',
    // type: 'labelSelect',
    // labelList: [
    //   { value: 1, label: '地区1' },
    //   { value: 2, label: '地区2' },
    //   { value: 3, label: '地区3' },
    //   { value: 4, label: '地区4' },
    // ],
    // rules: [{ required: true, message: '请选择地区标签' }],
    formSort: 14,
  },
];
