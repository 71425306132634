import { PageHeader, Card, Button, Table, Alert, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useAntdTable, useMemoizedFn } from 'ahooks';
import {
  usePagination,
  useDetailModal,
  useModalFormColumns,
  useTableColumns,
} from '@/hooks';
import { EditableRow, EditableCell, DetailModal } from '@/components';
import { COLUMNS } from './config';
import {
  getList,
  add,
  del,
  edit,
} from '@/services/business-management/updateManagement';
import { getOrderParams, getPageUpdate } from '@/utils/commonUtils';

const components = {
  body: {
    row: EditableRow,
    cell: EditableCell,
  },
};

const getTableData = async ({ current, pageSize }) => {
  const result = await getList({
    pageIndex: current,
    pageSize,
  });

  const data = result.data || {};

  return {
    total: data.total || 0,
    list: data.data || [],
  };
};

function UpdateManagement() {
  const { tableProps, search, params, run, refresh } = useAntdTable(
    getTableData,
    {
      defaultParams: [{ current: 1, pageSize: 10 }],
    }
  );
  const { showDetail, selectRecord, handleShowDetail, handleCloseDetail } =
    useDetailModal();
  const { formColumns, formType, setFormColumns, getModalFormColumns } =
    useModalFormColumns(COLUMNS);

  const handleSave = async (val) => {
    if (showDetail && formType === 'add') {
      // val.createTime = `${val.createTime}:00`;
      const result = await add(val);
      if (result.code === 200) {
        message.success('添加成功');
        handleCloseDetail();
        run({
          ...params[0],
          current: 1,
        });
      }
    } else {
      const result = await edit(val);
      if (result.code === 200) {
        message.success('编辑成功');
        refresh();
        if (showDetail) {
          handleCloseDetail();
        }
      }
    }
  };

  const handleDel = useMemoizedFn(async (record) => {
    const result = await del({
      versionId: record.versionId,
    });
    if (result.code === 200) {
      const page = getPageUpdate(tableProps, 1);
      run(
        {
          ...params[0],
          current: page,
        },
        params[1]
      );
      message.success('删除成功！');
    }
  });

  const { tableColumns, setTableColumns } = useTableColumns(COLUMNS, {
    onSave: handleSave,
    delTitle: (record) => `确定要删除${record.versionNum}版本的更新记录吗?`,
    onDelete: handleDel,
    onDetail: (record) => {
      getModalFormColumns(record, 'detail');
      handleShowDetail(record);
    },
  });

  const handleShowAdd = () => {
    getModalFormColumns({}, 'add');
    handleShowDetail({ status: false });
  };

  return (
    <div className="update-management">
      <PageHeader ghost={false} backIcon={false} title="更新管理" />
      <div className="content-wrap">
        <Card>
          <Button
            type="primary"
            style={{ marginBottom: '10px' }}
            onClick={handleShowAdd}
          >
            <PlusOutlined />
            发布更新
          </Button>
          <Alert
            style={{ marginBottom: '10px' }}
            message={
              <>
                <div>灰更：不提示用户更新，只更换下载地址</div>
                <div>软更：提示用户更新，但是用户可以跳过</div>
                <div>
                  强更：用户无法跳过，打死都要更启动app时，检测客户端版本号，若低于最新发布的更新版本，且更新版本的更新方式为软更/强更。则需要弹出更新提示
                </div>
              </>
            }
            type="error"
          />

          <Table
            className="edit-table"
            components={components}
            rowClassName={() => 'editable-row'}
            bordered
            columns={tableColumns}
            scroll={{ x: 'max-content' }}
            {...tableProps}
            pagination={{
              ...tableProps.pagination,
              showTotal: (total) => `共 ${total} 条`,
              // showSizeChanger: true,
              // showQuickJumper: true,
            }}
            rowKey="id"
          />
        </Card>
      </div>
      <DetailModal
        modalType={formType}
        title={formType === 'add' ? '新建商品' : '商品详情'}
        columns={formColumns}
        data={selectRecord}
        visible={showDetail}
        onClose={handleCloseDetail}
        onOk={handleSave}
        centered
      />
    </div>
  );
}

export default UpdateManagement;
