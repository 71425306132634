/* eslint-disable react/jsx-filename-extension */
import { Image, Form, Input, Space, InputNumber } from 'antd';
// import Form from 'antd/lib/form/Form';

export const SORT1_OPTIONS = [
  { value: '', name: '不限' },
  { value: 'publishTodayCount-DESC', name: '今日发布最多' },
  { value: 'earningTodayTotal-DESC', name: '今日收益最多' },
];
export const SORT2_OPTIONS = [
  { value: '', name: '不限' },
  { value: 'publishHistoryCount-DESC', name: '历史发布最多' },
  { value: 'earningHistoryTotal-DESC', name: '历史收益最多' },
  { value: 'wallet-DESC', name: '余额最多' },
  { value: 'withdrawTotal-DESC', name: '提现最多' },
];

export const STATUS_MAP = {
  0: true, // 启用1，禁用0
  1: false,
};

export const COLUMNS = [
  {
    title: '片商id',
    dataIndex: 'factoryId',
    align: 'center',
    ellipsis: true,
    width: 80,
    fixed: 'left',
    notShowForm: true,
  },
  {
    title: '片商名',
    dataIndex: 'factoryName',
    align: 'center',
    formEditable: true,
    type: 'input',
    rules: [{ required: true, message: '请输入片商名' }],
    formSort: 1,
  },
  {
    title: '片商邀请链接',
    dataIndex: 'inviteUrl',
    align: 'center',
    ellipsis: true,
    width: 120,
    render: (text) => (
      <a href={text} target="_blank" rel="noreferrer">
        {text}
      </a>
    ),
    // formEditable: true,
    // detailNotEditable: true,
    type: 'input',
    rules: [
      { required: true, message: '请输入片商邀请链接' },
      { type: 'url', message: '请输入正确的邀请链接' },
    ],
    formSort: 7,
    suffix: '复制',
    suffixType: 'copy',
  },
  {
    title: '片商logo',
    dataIndex: 'pictureUrl',
    align: 'center',
    width: 100,
    formEditable: true,
    type: 'image',
    valuePropName: 'fileList',
    rules: [{ required: true, message: '请上传片商logo' }],
    render: (text) => <Image src={text} width="70%" />,
    formSort: 5,
    uploadUrl: '/manage/factoryConfig/image/upload',
  },
  {
    title: '片商等级',
    dataIndex: 'factoryGrade',
    align: 'center',
    width: 70,
    formEditable: true,
    type: 'inputNumber',
    rules: [{ required: true, message: '请输入片商等级' }],
    formSort: 3,
    suffix: '级',
  },
  {
    title: '今日发布 / 历史发布',
    dataIndex: 'publish',
    align: 'center',
    width: 130,
    notShowForm: true,
    render: (text, record, index) =>
      `${record.publishTodayCount}/${record.publishHistoryCount}`,
  },
  {
    title: '今日收益 / 历史收益',
    dataIndex: 'income',
    align: 'center',
    width: 140,
    notShowForm: true,
    render: (text, record, index) =>
      `${record.earningTodayTotal}/${record.earningHistoryTotal}`,
  },
  {
    title: '每月商业作品发布上限',
    dataIndex: 'businessMax',
    align: 'center',
    width: 140,
    notShowForm: true,
  },
  {
    title: '长视频',
    dataIndex: 'factoryLongVideoTotalCount',
    align: 'center',
    width: 60,
    formSort: 18,
    colOffset: 0,
  },
  {
    title: '短视频',
    dataIndex: 'factoryShortVideoTotalCount',
    align: 'center',
    width: 60,
    formSort: 19,
    colOffset: 2,
  },
  {
    title: '动态贴',
    dataIndex: 'factoryActionTotalCount',
    align: 'center',
    width: 60,
    formSort: 20,
    colOffset: 0,
  },
  {
    title: '花絮',
    dataIndex: 'factoryTitbitTotalCount',
    align: 'center',
    width: 50,
    formSort: 21,
    colOffset: 2,
  },
  {
    title: '账户余额',
    dataIndex: 'wallet',
    align: 'center',
    width: 70,
    formSort: 24,
    colOffset: 0,
  },
  {
    title: '已提现',
    dataIndex: 'withdrawTotal',
    align: 'center',
    width: 70,
    formSort: 25,
    colOffset: 2,
  },
  {
    title: '提现门槛',
    dataIndex: 'withdrawMin',
    align: 'center',
    width: 70,
    // formEditable: true,
    // type: 'inputNumber',
    // rules: [{ required: true, message: '请输入提现门槛' }],
    formSort: 14,
    // suffix: '元',
    colOffset: 0,
    customForm: true,
    format: () => (
      <Space>
        <Form.Item
          name="withdrawMin"
          noStyle
          rules={[{ required: true, message: '' }]}
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
        <span>元</span>
      </Space>
    ),
  },
  {
    title: '是否禁用',
    dataIndex: 'disable',
    align: 'center',
    editable: true,
    type: 'switch',
    valuePropName: 'checked',
    width: 70,
    rules: [{ required: true, message: '请选择是否禁用' }],
    formSort: 4,
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    align: 'center',
    width: 90,
    notShowForm: true,
    render: (text) => text.replace('T', ' '),
  },
  {
    title: '登录密码',
    dataIndex: 'factoryPwd',
    notShowTable: true,
    formEditable: true,
    type: 'password',
    rules: [{ required: true, message: '请输入登录密码' }],
    formSort: 2,
  },
  {
    title: '片商介绍',
    dataIndex: 'factoryIntroduction',
    notShowTable: true,
    formEditable: true,
    type: 'textarea',
    autoSize: { minRows: 4, maxRows: 4 },
    rules: [{ required: true, message: '请输入片商介绍' }],
    formSort: 6,
  },
  {
    title: '今日发布',
    dataIndex: 'publishTodayCount',
    notShowTable: true,
    colOffset: 0,
    formSort: 16,
  },
  {
    title: '历史发布',
    dataIndex: 'publishHistoryCount',
    notShowTable: true,
    colOffset: 2,
    formSort: 17,
  },
  {
    title: '今日收益',
    dataIndex: 'earningTodayTotal',
    notShowTable: true,
    colOffset: 0,
    formSort: 22,
  },
  {
    title: '历史收益',
    dataIndex: 'earningHistoryTotal',
    notShowTable: true,
    formSort: 23,
    colOffset: 2,
  },
  {
    title: '发片条件1',
    dataIndex: 'condition1',
    notShowTable: true,
    customForm: true,
    formSort: 8,
    format: () => (
      <Space>
        <span>每发布</span>
        <Form.Item
          name="freeConversion"
          noStyle
          rules={[{ required: true, message: '' }]}
        >
          <InputNumber style={{ width: '70px' }} />
        </Form.Item>
        <span>部免费GV即可发布</span>
      </Space>
    ),
  },
  {
    title: <span style={{ marginLeft: '-105px' }}>发片条件2</span>,
    dataIndex: 'condition2',
    notShowTable: true,
    formSort: 9,
    colSpan: 24,
    colOffset: 0,
    labelCol: 4,
    wrapperCol: 20,
    customForm: true,
    format: () => (
      <Space style={{ marginLeft: '-26px' }}>
        <span>每发布</span>
        <Form.Item
          name="shortsConversion"
          noStyle
          rules={[{ required: true, message: '' }]}
        >
          <InputNumber style={{ width: '60px' }} />
        </Form.Item>
        <span>部短视频+</span>
        <Form.Item
          name="actionsConversion"
          noStyle
          rules={[{ required: true, message: '' }]}
        >
          <InputNumber style={{ width: '60px' }} />
        </Form.Item>
        <span>个动态</span>
        <Form.Item
          name="titbitsConversion"
          noStyle
          rules={[{ required: true, message: '' }]}
        >
          <InputNumber style={{ width: '60px' }} />
        </Form.Item>
        <span>部花絮即可发布</span>
        <Form.Item noStyle>
          <InputNumber style={{ width: '60px' }} disabled value={1} />
        </Form.Item>
        <span>部商业GV</span>
      </Space>
    ),
  },
  {
    title: '商业作品发布上限',
    dataIndex: 'businessMax',
    notShowTable: true,
    formSort: 10,
    colOffset: 0,
    customForm: true,
    format: () => (
      <Space>
        <span>每月</span>
        <Form.Item
          name="businessMax"
          noStyle
          rules={[{ required: true, message: '' }]}
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
        <span>部</span>
      </Space>
    ),
  },
  {
    title: '随拍短片发布上限',
    dataIndex: 'shortsMax',
    notShowTable: true,
    formSort: 11,
    colOffset: 2,
    customForm: true,
    format: () => (
      <Space>
        <span>每周</span>
        <Form.Item
          name="shortsMax"
          noStyle
          rules={[{ required: true, message: '' }]}
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
        <span>部</span>
      </Space>
    ),
  },
  {
    title: '厂牌动态发布上限',
    dataIndex: 'actionsMax',
    notShowTable: true,
    formSort: 12,
    colOffset: 0,
    customForm: true,
    format: () => (
      <Space>
        <span>每日</span>
        <Form.Item
          name="actionsMax"
          noStyle
          rules={[{ required: true, message: '' }]}
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
        <span>条</span>
      </Space>
    ),
  },
  {
    title: '花絮视频发布上限',
    dataIndex: 'titbitsMax',
    notShowTable: true,
    formSort: 13,
    colOffset: 2,
    customForm: true,
    format: () => (
      <Space>
        <span>每周</span>
        <Form.Item
          name="titbitsMax"
          noStyle
          rules={[{ required: true, message: '' }]}
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
        <span>部</span>
      </Space>
    ),
  },
  {
    title: '单笔提现上限',
    dataIndex: 'withdrawMax',
    notShowTable: true,
    formSort: 15,
    colOffset: 2,
    customForm: true,
    format: () => (
      <Space>
        <Form.Item
          name="withdrawMax"
          noStyle
          rules={[{ required: true, message: '' }]}
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
        <span>元</span>
      </Space>
    ),
  },
];
