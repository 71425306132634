/* eslint-disable react/jsx-filename-extension */
import { Image } from 'antd';

export const FILTER1_OPTIONS = [
  { value: '', name: '历史所有' },
  { value: 'recentDays_3', name: '近3天' },
  { value: 'recentDays_7', name: '近一周' },
  { value: 'recentDays_15', name: '近半个月' },
  { value: 'recentDays_30', name: '近一个月' },
];
export const FILTER2_OPTIONS = [
  { value: '', name: '全部状态' },
  { value: 'status_0', name: '未处理' },
  { value: 'status_1', name: '已处理' },
];

const STATUS_MAP = {
  0: '未处理',
  1: '已处理',
};

export const COLUMNS = [
  {
    title: 'id',
    dataIndex: 'id',
    align: 'center',
    ellipsis: true,
    width: 50,
    fixed: 'left',
    notShowForm: true,
  },
  {
    title: '用户昵称',
    dataIndex: 'nickname',
    align: 'center',
    width: 110,
    ellipsis: true,
  },
  {
    title: '用户ID',
    dataIndex: 'userId',
    align: 'center',
    ellipsis: true,
    width: 100,
  },
  {
    title: '手机号',
    dataIndex: 'phone',
    align: 'center',
    width: 130,
    type: 'input',
    editable: true,
  },
  {
    title: '最新消息',
    dataIndex: 'latestChatContent',
    align: 'center',
    // width: 120,
    ellipsis: true,
  },
  {
    title: '最新消息时间',
    dataIndex: 'latestChatTime',
    align: 'center',
    width: 100,
  },
  {
    title: '状态',
    dataIndex: 'status',
    align: 'center',
    width: 60,
    render: (text) => STATUS_MAP[text] || '',
  },
  {
    title: '邀请码',
    dataIndex: 'inviteCode',
    align: 'center',
    width: 60,
  },
  {
    title: '邀请人数',
    dataIndex: 'inviteNumber',
    align: 'center',
    width: 70,
  },
];
