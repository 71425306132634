import { Button, Popover, Input, message } from 'antd';
import { useEffect, useState } from 'react';
import { resetPassword } from '@/services/customer-service/workOrder';

function ResetPassword({ userId }) {
  const [visible, setVisible] = useState(false);
  const [password, setPassword] = useState('');
  const handleOk = async () => {
    setVisible(false);
    const result = await resetPassword({
      userId,
      password,
    });
    if (result.code === 200) {
      message.success('重置成功');
    }
  };
  useEffect(() => {
    setPassword('');
  }, [visible]);

  return (
    <Popover
      content={
        <>
          <Input.Password
            placeholder="请输入新密码"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div style={{ textAlign: 'center', marginTop: '10px' }}>
            <Button type="primary" size="small" onClick={handleOk}>
              确定
            </Button>
          </div>
        </>
      }
      title="重置密码"
      trigger="click"
      visible={visible}
      onVisibleChange={(visible) => setVisible(visible)}
    >
      <Button type="link">点击重设密码</Button>
    </Popover>
  );
}

export default ResetPassword;
