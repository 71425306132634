import {
  getArrangeData,
  getVideoByDate,
  searchVideo,
  addToday,
  delVideo,
  sortVideo,
} from '@/services/business-management/filmingManagement';

export const getArrageByMonth = async (date) => {
  const result = await getArrangeData({
    y: date.get('year'), // 年
    m: date.get('month') + 1, // 月
  });

  return result.data || {};
};

export const getArrageVideoByDate = async (date) => {
  const result = await getVideoByDate({
    y: date.get('year'), // 年
    m: date.get('month') + 1, // 月
    d: date.get('date'), // 日
  });

  (result.data?.data || []).sort((a, b) => a.order - b.order);

  return result.data || {};
};

export const searchVideoData = async (searchType, text) => {
  const result = await searchVideo({
    search: searchType, // 1-Id，2-名称
    wd: text,
  });

  return result.data || [];
};

export const addVideo = async (date, movieId) => {
  const result = await addToday({
    y: date.get('year'), // 年
    m: date.get('month') + 1, // 月
    d: date.get('date'), // 日
    movieId, // 视频Id
  });

  return result;
};

export const delVideoFromArrage = async (date, movieId) => {
  const result = await delVideo({
    y: date.get('year'), // 年
    m: date.get('month') + 1, // 月
    d: date.get('date'), // 日
    movieId, // 视频Id
  });
  return result;
};

export const moveVideo = async (date, list) => {
  const result = await sortVideo({
    y: date.get('year'), // 年
    m: date.get('month') + 1, // 月
    d: date.get('date'), // 日
    list,
  });
  return result;
};
