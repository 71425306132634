import { post } from '@/utils/http';

export const getList = (params) =>
  post({
    url: '/manage/config/award/list',
    data: params,
  });
export const edit = (params) =>
  post({
    url: '/manage/config/award/update',
    data: params,
  });
