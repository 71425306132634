import { post } from '@/utils/http';

export const getList = (params) =>
  post({
    url: '/manage/video/long/examine/get/page',
    data: params,
  });
export const pass = (params) =>
  post({
    url: '/manage/video/long/examine/approve',
    data: params,
  });
export const batchReject = (params) =>
  post({
    url: '/manage/video/long/examine/reject/batch',
    data: params,
  });
export const getTags = (params) =>
  post({
    url: '/manage/video/long/querySubject',
    data: params,
  });
export const recommend = (params) =>
  post({
    url: '/manage/video/long/recommend',
    data: params,
  });
