import { post, get } from '@/utils/http';

export const login = (params) =>
  post({
    url: '/manage/auth/login',
    data: params,
  });
export const getCaptcha = () =>
  get({
    url: '/manage/auth/captcha',
    config: {
      responseType: 'blob',
    },
  });

export const logout = (params) =>
  post({
    url: '/manage/auth/logout',
    data: params,
  });
