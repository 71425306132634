/* eslint-disable no-nested-ternary */
import {
  Button,
  Modal,
  Form,
  Input,
  Upload,
  Space,
  Image,
  Tag,
  message,
  DatePicker,
  InputNumber,
  Switch,
  Select,
  Popconfirm,
} from 'antd';
import { useState, useEffect, useRef } from 'react';
import { EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import ClipboardJS from 'clipboard';
import { formatSecond, getLockrValue } from '@/utils/commonUtils';
import { UserAvatars } from './components';
import LabelSelect from '../label-select';

import './index.less';

const { Option } = Select;

const getValueFromEvent = (e) => {
  console.log('Upload event:', e);

  if (Array.isArray(e)) {
    return e;
  }

  if (e.fileList) {
    // return e && e.fileList;
    // e.file.status = 'success';
    return e && e.fileList;
  }

  return e && e.target.value;
};

function ApprovalDetailModal({
  columns,
  visible,
  onClose,
  data = {},
  centered,
  onTurnDown = () => {},
  onPass = () => {},
  onPreview = () => {},
  onDel = () => {},
  type,
  title,
  management,
}) {
  const [form] = Form.useForm();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState('');
  const [previewImage, setPreviewImage] = useState('');
  const init = useRef(false);

  const handlePreview = (file) => {
    console.log(file);
    setPreviewVisible(true);
    setPreviewImage(file.url || file.thumbUrl);
  };

  const getFormItem = ({
    type,
    labelList = [],
    dataIndex,
    placeholder,
    // eslint-disable-next-line no-shadow
    title,
    min,
    selectList = [],
    labelSelectMode,
  }) => {
    const onChange = ({ file }) => {
      const { response } = file;
      if (!response) {
        return;
      }
      if (response.code === 200) {
        message.success('上传成功');
      } else {
        message.error(response.msg || '上传失败');
        // const fileData = form.getFieldValue(dataIndex);
        // fileData[0].status = 'error';
        // form.setFields([{ name: dataIndex, value: fileData }]);
        let formateValue;
        if (data[dataIndex]) {
          const imageData = Array.isArray(data[dataIndex])
            ? data[dataIndex]
            : [data[dataIndex]];
          formateValue = imageData.map((url) => ({ url }));
        }
        form.setFields([{ name: dataIndex, value: formateValue }]); // 上传失败展示之前的数据
      }
    };
    // const beforeUpload = (file) => {
    //   const size = file.size / 1024 / 1024;
    //   if (size > 2) {
    //     message.error('文件大小不能超过2M，请重新上传');
    //     return false;
    //   }
    //   return true;
    // };
    const FORM_MAP = {
      input: <Input placeholder={placeholder || `请输入${title}`} />,
      image: (
        <Upload
          action="/manage/file/local/upload"
          data={{ request: JSON.stringify({ filePath: data[dataIndex] }) }}
          listType="picture-card"
          showUploadList={{
            showPreviewIcon: true,
            showRemoveIcon: false,
          }}
          onPreview={handlePreview}
          onChange={onChange}
          // beforeUpload={beforeUpload}
          accept="image/*"
          headers={{
            'remind-me': getLockrValue('remindMe') || '',
          }}
          maxCount={1}
        >
          <EditOutlined />
        </Upload>
      ),
      textarea: (
        <Input.TextArea
          placeholder={placeholder || `请输入${title}`}
          showCount
          autoSize
        />
      ),
      labelSelect: <LabelSelect labelList={labelList} mode={labelSelectMode} />,
      date: <DatePicker format="YYYY-MM-DD" allowClear={false} />,
      inputNumber: (
        <InputNumber
          style={{ width: '100%' }}
          placeholder={placeholder || `请输入${title}`}
          min={min || 0}
        />
      ),
      switch: <Switch />,
      select: (
        <Select placeholder={placeholder || `请选择${title}`}>
          {selectList.map((item) => (
            <Option value={item.value} key={item.value}>
              {item.label}
            </Option>
          ))}
        </Select>
      ),
    };
    return FORM_MAP[type];
  };

  const getValueContent = ({ type, dataIndex, formate }) => {
    const getVal = (valType, defaultValue) => {
      const val =
        type === valType ? data[dataIndex] || defaultValue : defaultValue;
      return Array.isArray(val) ? val : [val];
    };

    const VALUE_CONTENT_MAP = {
      avatar: <UserAvatars data={getVal('avatar', [])} />,
      image: getVal('image', []).length ? (
        <Space wrap>
          {getVal('image', []).map((item, index) => (
            <Image src={item} key={item} width={100} />
          ))}
        </Space>
      ) : (
        '无'
      ),
      tag: getVal('tag', []).length ? (
        <Space wrap>
          {getVal('tag', []).map((item, index) => (
            <Tag color="processing" key={item}>
              {item}
            </Tag>
          ))}
        </Space>
      ) : (
        '无'
      ),
      default: <span>{data[dataIndex]}</span>,
    };
    return formate
      ? formate(data[dataIndex], data)
      : VALUE_CONTENT_MAP[type] || VALUE_CONTENT_MAP.default;
  };

  useEffect(() => {
    if (visible) {
      console.log(data);
      const formData = { ...data };
      columns.forEach((item) => {
        if (item.editable || item.formEditable || item.customForm) {
          if (item.type === 'image') {
            let formateValue;
            if (formData[item.dataIndex]) {
              const imageData = Array.isArray(formData[item.dataIndex])
                ? formData[item.dataIndex]
                : [formData[item.dataIndex]];
              formateValue = imageData.map((url) => ({ url }));
            }
            formData[item.dataIndex] = formateValue;
          }
          if (item.type === 'date') {
            formData[item.dataIndex] = formData[item.dataIndex]
              ? moment(formData[item.dataIndex])
              : null;
          }
        }
      });
      form.setFieldsValue(formData);
    } else if (init.current) {
      form.resetFields();
      const ele = document.querySelector(
        '.long-video-detail-modal .ant-modal-body'
      );
      ele.scrollTo(0, 0);
    }
    init.current = true;
  }, [visible, data]);

  useEffect(() => {
    const clipboardPre = new ClipboardJS('#copyPreUrl');
    const clipboard = new ClipboardJS('#copyUrl');

    clipboard.on('success', (e) => {
      message.success('复制成功');
    });

    clipboard.on('error', (e) => {
      message.success('复制失败');
    });

    clipboardPre.on('success', () => {
      message.success('复制成功');
    });

    clipboardPre.on('error', () => {
      message.success('复制失败');
    });
    return () => {
      clipboardPre.destroy();
      clipboard.destroy();
    };
  }, []);

  const handleTurnDown = () => {
    // onClose();
    onTurnDown();
  };
  const handlePass = async () => {
    // onClose();
    const values = await form.validateFields();
    const newValue = { ...data, ...values };
    columns.forEach((item) => {
      if (item.editable || item.formEditable) {
        if (item.type === 'image') {
          newValue[item.dataIndex] = data[item.dataIndex];
        }
      }
    });
    Object.keys(newValue).forEach((key) => {
      if (moment.isMoment(newValue[key])) {
        newValue[key] = newValue[key].format('YYYY-MM-DD');
      }
    });
    onPass(newValue);
  };
  const handlePreviewVideo = (type) => {
    // onClose();
    onPreview(type);
  };

  return (
    <Modal
      title={title || '编辑影片'}
      visible={visible}
      width={800}
      className="long-video-detail-modal"
      // onOk={handleOk}
      onCancel={onClose}
      centered={centered}
      footer={
        management
          ? [
              <Popconfirm
                key="del"
                title="确定要删除该影片吗？"
                okText="是"
                cancelText="否"
                onConfirm={() => onDel(data)}
              >
                <Button type="primary" danger>
                  删除
                </Button>
              </Popconfirm>,
              <Button key="save" type="primary" onClick={handlePass}>
                保存
              </Button>,
            ]
          : [
              <Button key="close" onClick={onClose}>
                关闭
              </Button>,
              <Button
                key="return"
                danger
                type="parimay"
                onClick={handleTurnDown}
              >
                驳回
              </Button>,
              <Button key="pass" type="primary" onClick={handlePass}>
                通过
              </Button>,
            ]
      }
    >
      <div className="detail-top-wrap">
        <div className="film-componey-info">
          <img
            src={data.factory?.factoryPictureUrl}
            className="logo"
            alt="logo"
          />
          <div className="info-wrap">
            {/* <div className="name">用户名/片商名</div> */}
            {data.examineAgain && management && (
              <Tag color="red">*该视频审核失败，请重新审核</Tag>
            )}

            <div className="name">
              {data.factory?.factoryName}
              {data.publish && (
                <Tag color="blue" style={{ marginLeft: '20px' }}>
                  全网首发
                </Tag>
              )}
            </div>
            {/* <div className="level">用户等级/片商等级</div> */}
            <div className="level">{data.factory?.factoryGrade}</div>
            <div className="level">
              通过率：{data.factory?.passRate || 0}%{' '}
              <span>贡献利润率：{data.factory?.contributionRate || 0}%</span>
            </div>
            <div className="count">
              <span>
                长视频:{data.factory?.factoryExaminePassLongVideoCount}部
              </span>
              <span>
                短视频:{data.factory?.factoryExaminePassShortVideoCount}部
              </span>
              <span>
                动态贴:{data.factory?.factoryExaminePassActionVideoCount}
              </span>
              <span>
                花絮:{data.factory?.factoryExaminePassTitbitVideoCount}部
              </span>
            </div>
          </div>
        </div>
        <div className="play-btns">
          {type === 'long-video' &&
            data.videoType === 1 && ( // 长视频属性上有商业和免费,免费视频，没有预告片  1商业长视频  3免费
              <Space>
                <Button
                  type="primary"
                  onClick={() => handlePreviewVideo('trailer')}
                >
                  播放预告片
                </Button>
                <Button
                  type="primary"
                  id="copyPreUrl"
                  data-clipboard-text={data.preMovieUrl}
                >
                  复制链接
                </Button>
              </Space>
            )}
          {(type === 'short-video' ||
            type === 'long-video' ||
            type === 'trivia') && (
            <Space>
              <Button
                type="primary"
                onClick={() => handlePreviewVideo('whole')}
              >
                播放正片（{formatSecond(data.movieDuration)}{' '}
                {Math.ceil(data.movieSize / 1024)}mb）
              </Button>
              <Button
                type="primary"
                id="copyUrl"
                data-clipboard-text={data.movieUrl}
              >
                复制链接
              </Button>
            </Space>
          )}
        </div>
      </div>
      <Form layout="vertical" form={form}>
        {columns.map((item, index) =>
          item.editable || item.formEditable ? (
            <Form.Item
              key={item.dataIndex}
              label={item.title}
              name={item.dataIndex}
              rules={item.rules || []}
              valuePropName={item.valuePropName || 'value'}
              getValueFromEvent={
                item.type === 'image' ? getValueFromEvent : undefined
              }
            >
              {getFormItem(item)}
            </Form.Item>
          ) : (
            <Form.Item
              label={item.title}
              key={item.dataIndex}
              noStyle={item.conditionForm}
            >
              {getValueContent(item)}
              {/* <span>{data[item.dataIndex] || ''}</span> */}
            </Form.Item>
          )
        )}
      </Form>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        <div style={{ textAlign: 'center' }}>
          <img alt="example" style={{ width: '90%' }} src={previewImage} />
        </div>
      </Modal>
    </Modal>
  );
}
export default ApprovalDetailModal;
