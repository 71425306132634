import { useCallback, useState } from 'react';

const useModalFormColumns = (columns) => {
  const [formColumns, setFormColumns] = useState(
    columns.filter((item) => !item.notShowForm)
  );
  const [formType, setFormType] = useState('detail');

  const getModalFormColumns = useCallback((formData, type = 'detail') => {
    setFormType(type);
    let newColumns = columns.filter((item) => !item.notShowForm);
    if (type === 'add') {
      newColumns = newColumns.filter(
        (item) => item.editable || item.formEditable || item.customForm
      );
    }
    // const finalColumns = [];
    // newColumns.forEach((item) => {
    //   if (item.condition) {
    //     if (item.condition(formData)) {
    //       finalColumns.push(item);
    //     }
    //   } else {
    //     finalColumns.push(item);
    //   }
    // });
    if (newColumns[0].formSort) {
      newColumns.sort((a, b) => a.formSort - b.formSort);
    }

    setFormColumns(newColumns);
  }, []);

  return {
    formColumns,
    formType,
    setFormColumns,
    getModalFormColumns,
  };
};

export default useModalFormColumns;
