import { Popover, Button, Form, InputNumber, Radio } from 'antd';
import { useState } from 'react';

function AddTimesOperator({ onAdd, record }) {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const handleFinish = (values) => {
    onAdd(values, record);
    setVisible(false);
  };
  const handleVisibleChange = (val) => {
    setVisible(val);
    if (val) {
      form.resetFields();
    }
  };

  const timesPopoverForm = (
    <Form onFinish={handleFinish} form={form}>
      <Form.Item
        name="type"
        rules={[
          {
            required: true,
            message: `观影次数是必填的`,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="today">今日有效</Radio>
          <Radio value="every">每日有效</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="times"
        rules={[
          {
            required: true,
            message: `观影次数是必填的`,
          },
        ]}
      >
        <InputNumber
          placeholder="输入奖励观影次数，对收费片无效"
          style={{ width: '100%' }}
        />
      </Form.Item>
      <Form.Item style={{ textAlign: 'center', marginBottom: 0 }}>
        <Button type="primary" htmlType="submit">
          确定
        </Button>
      </Form.Item>
    </Form>
  );

  return (
    <Popover
      content={timesPopoverForm}
      title="加次数"
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
    >
      <Button size="small" type="link">
        加次数
      </Button>
    </Popover>
  );
}

export default AddTimesOperator;
