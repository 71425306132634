import {
  PageHeader,
  Card,
  Space,
  Form,
  Row,
  Col,
  Select,
  Input,
  Button,
  Table,
  message,
} from 'antd';
import { useAntdTable } from 'ahooks';
import { useOutletContext } from 'react-router-dom';
import {
  FILTER1_OPTIONS,
  FILTER2_OPTIONS,
  SORT_OPTIONS,
  COLUMNS,
  TURN_DOWN_LABELS,
} from './config';
import {
  EditableRow,
  EditableCell,
  // PlayerModal,
  ApprovalDetailModal,
  TurnDownModal,
  ApprovalPassModal,
} from '@/components';
import {
  useDetailModal,
  useTableColumns,
  useModalFormColumns,
  useTableRowSelection,
} from '@/hooks';
import { SEARCH_TYPE_LIST, selectBefore } from '../commonConfig';
import {
  getList,
  pass,
  batchReject,
} from '@/services/approval-management/stickerApproval';
import { getOrderParams, getPageUpdate } from '@/utils/commonUtils';

const { Option } = Select;

const initialValues = {
  filter1: '',
  // filter2: 'all',
  sort: '',
  searchType: 'factoryName',
  search: '',
};
const components = {
  body: {
    row: EditableRow,
    cell: EditableCell,
  },
};

const getTableData = async ({ current, pageSize }, formData) => {
  const { orders, search } = getOrderParams(formData);

  const result = await getList({
    pageIndex: current,
    pageSize,
    orders,
    params: search,
  });
  console.log(result);
  const data = result.data || {};

  return {
    total: data.total || 0,
    list: data.data || [],
  };
};

function StickerApproval() {
  const { refreshApproval } = useOutletContext();
  const [form] = Form.useForm();
  const { tableProps, search, params, run } = useAntdTable(getTableData, {
    form,
    defaultParams: [{ current: 1, pageSize: 10 }, initialValues],
  });
  const { formColumns, formType, setFormColumns, getModalFormColumns } =
    useModalFormColumns(COLUMNS);
  const { showDetail, selectRecord, handleShowDetail, handleCloseDetail } =
    useDetailModal();
  const {
    showDetail: showTurnDownModal,
    selectRecord: turnDownRecord,
    handleShowDetail: handleShowTurnDown,
    handleCloseDetail: handleCloseTurnDown,
  } = useDetailModal();
  const {
    showDetail: showPassModal,
    selectRecord: passRecord,
    handleShowDetail: handleShowPass,
    handleCloseDetail: handleClosePass,
  } = useDetailModal();

  const [selectedRowKeys, setSelectedRowKeys] = useTableRowSelection({
    dataSource: tableProps.dataSource,
    keyName: 'movieId',
  });

  const handleSave = (val) => {
    console.log(val);
  };

  const handleDetail = (record) => {
    getModalFormColumns(record, 'detail');
    handleShowDetail(record);
  };

  const rejectSticker = async (movieIds, rejectReason) => {
    const result = await batchReject({
      movieIds,
      rejectReason,
    });
    if (result.code === 200) {
      message.success('驳回成功');
      handleCloseTurnDown();
      if (showDetail) {
        handleCloseDetail();
      }
      const page = getPageUpdate(tableProps, movieIds.length);
      run(
        {
          ...params[0],
          current: page,
        },
        params[1]
      );
      refreshApproval();
    }
  };

  const { tableColumns, setTableColumns } = useTableColumns(COLUMNS, {
    onSave: handleSave,
    controlColumns: {
      title: '操作',
      key: 'action',
      width: 150,
      align: 'center',
      fixed: 'right',
      render: (text, record) => (
        <>
          {/* <Button type="link" size="small" onClick={() => setShowPreview(true)}>
            视频播放
          </Button> */}
          <Button type="link" size="small" onClick={() => handleDetail(record)}>
            编辑
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => handleShowPass(record)}
          >
            通过
          </Button>
          <Button
            type="link"
            size="small"
            danger
            onClick={() => handleShowTurnDown([record.movieId])}
          >
            驳回
          </Button>
        </>
      ),
    },
  });

  const handlePass = async (val) => {
    const passParams = {
      movieId: passRecord.movieId,
    };
    const result = await pass(passParams);

    if (result.code === 200) {
      message.success('操作成功');
      const page = getPageUpdate(tableProps, 1);
      run(
        {
          ...params[0],
          current: page,
        },
        params[1]
      );
      handleClosePass();
      if (showDetail) {
        handleCloseDetail();
      }
      refreshApproval();
    }
  };

  return (
    <div className="long-video-approval">
      <PageHeader ghost={false} backIcon={false} title="动态审批" />
      <div className="content-wrap">
        <Space direction="vertical" style={{ width: '100%' }}>
          <Card>
            <Form initialValues={initialValues} form={form}>
              <Row>
                <Col span={6} offset={0}>
                  <Form.Item
                    label="筛选1"
                    name="filter1"
                    style={{ marginBottom: '10px' }}
                  >
                    <Select>
                      {FILTER1_OPTIONS.map((item) => (
                        <Option value={item.value} key={item.value}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {/* <Col span={5} offset={1}>
                  <Form.Item
                    label="筛选2"
                    name="filter2"
                    style={{ marginBottom: 0 }}
                  >
                    <Select>
                      {FILTER2_OPTIONS.map((item) => (
                        <Option value={item.value} key={item.value}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col> */}
                <Col span={6} offset={1}>
                  <Form.Item
                    label="排序1"
                    name="sort"
                    style={{ marginBottom: 0 }}
                  >
                    <Select>
                      {SORT_OPTIONS.map((item) => (
                        <Option value={item.value} key={item.value}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6} offset={1}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.searchType !== curValues.searchType
                    }
                  >
                    {({ getFieldValue }) => {
                      const type = getFieldValue('searchType');
                      const item = SEARCH_TYPE_LIST.find(
                        (item) => item.value === type
                      );
                      return (
                        <Form.Item
                          label="关键搜索"
                          name="search"
                          style={{ marginBottom: 0, marginLeft: '-19px' }}
                        >
                          <Input
                            placeholder={`输入${item.label}`}
                            addonBefore={selectBefore}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={2} offset={1}>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Button type="primary" onClick={search.submit}>
                      确认查看
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
          <Card>
            <div className="table-title">查询结果</div>
            <div className="batch-btn-wrap">
              <Space>
                <Button
                  type="primary"
                  size="small"
                  danger
                  disabled={!selectedRowKeys.length}
                  onClick={() => handleShowTurnDown(selectedRowKeys)}
                >
                  驳回
                </Button>
              </Space>
            </div>
            <Table
              className="edit-table"
              components={components}
              rowClassName={() => 'editable-row'}
              bordered
              columns={tableColumns}
              scroll={{ x: 'max-content' }}
              rowSelection={{
                selectedRowKeys,
                onChange: (selectedRowKeys) =>
                  setSelectedRowKeys(selectedRowKeys),
              }}
              {...tableProps}
              pagination={{
                ...tableProps.pagination,
                showTotal: (total) => `共 ${total} 条`,
                // showSizeChanger: true,
                // showQuickJumper: true,
              }}
              rowKey="movieId"
            />
          </Card>
        </Space>
      </div>
      <ApprovalDetailModal
        columns={formColumns}
        visible={showDetail}
        onClose={handleCloseDetail}
        data={selectRecord}
        onTurnDown={() => handleShowTurnDown([selectRecord.movieId])}
        onPass={handleShowPass}
        centered
        type="sticker"
        title="动态详情"
      />
      <TurnDownModal
        labelList={TURN_DOWN_LABELS}
        visible={showTurnDownModal}
        data={turnDownRecord}
        onClose={handleCloseTurnDown}
        onOk={rejectSticker}
        type="sticker"
        tips="驳回后，该动态将消失，请慎重操作"
      />
      <ApprovalPassModal
        topContent={[
          <div key="1">该动态贴为片商免费提供</div>,
          <div key="2">是否确认通过</div>,
        ]}
        visible={showPassModal}
        data={passRecord}
        onClose={handleClosePass}
        onOk={handlePass}
        type="sticker"
      />
    </div>
  );
}

export default StickerApproval;
