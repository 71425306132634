/* eslint-disable eqeqeq */
import { DatePicker, Form, InputNumber, Image } from 'antd';
import { useState } from 'react';

export const FILTER1_OPTIONS = [
  { value: '', name: '不限类型' },
  { value: 'videoAttribute_4', name: '只看限免' },
  { value: 'videoAttribute_3', name: '只看永久免费' },
  { value: 'videoAttribute_2', name: '只看金币' },
  { value: 'videoAttribute_1', name: '只看VIP' },
];
export const FILTER2_OPTIONS = [
  { value: '', name: '全部作品' },
  { value: 'putaway_false', name: '只看未上架作品' },
  { value: 'putaway_true', name: '只看已上架作品' },
  // { value: 'videoAttribute_1', name: '只看片商作品' },
  // { value: 'videoAttribute_1', name: '只看普通用户作品' },
];
export const FILTER3_OPTIONS = [
  { value: '', name: '不限合作类型' },
  { value: 'cooperationType_3', name: '免费' },
  { value: 'cooperationType_1', name: '分成' },
  { value: 'cooperationType_2', name: '买断' },
];
export const SORT1_OPTIONS = [
  { value: '', name: '不限排序' },
  { value: 'watchs-DESC', name: '按播放量高低' },
  { value: 'thumbs-DESC', name: '按点赞数高低' },
  { value: 'goldNumTotal-DESC', name: '按已赚金币高低' },
  { value: 'factoryGrade-DESC', name: '按片商等级高低' },
  // { value: 'cooperationType_2', name: '按用户等级高低' },
];

export const COOPERATION_MAP = {
  1: '分成',
  2: '买断',
  3: '免费',
};

const COOPERATION_PRICE_KEY = {
  1: {
    key: 'royaltyRate',
    unit: '%',
  },
  2: {
    key: 'buyout',
    unit: '元',
  },
};

function MultiImg({ text }) {
  const [visible, setVisible] = useState(false);
  return text ? (
    <>
      <Image
        preview={{ visible: false }}
        src={text[0]}
        width="60%"
        onClick={() => setVisible(true)}
      />
      <span>共{text.length}张</span>
      <div style={{ display: 'none' }}>
        <Image.PreviewGroup
          preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}
        >
          {text.map((url) => (
            <Image src={url} key={url} />
          ))}
        </Image.PreviewGroup>
      </div>
    </>
  ) : (
    ''
  );
}

export const COLUMNS = [
  {
    title: '动态贴id',
    dataIndex: 'movieId',
    align: 'center',
    // ellipsis: true,
    width: 80,
    fixed: 'left',
    notShowForm: true,
  },
  {
    title: '片商名/等级',
    dataIndex: 'factory',
    align: 'center',
    notShowForm: true,
    render: (text) =>
      text ? `${text?.factoryName}/${text?.factoryGrade}` : '',
  },
  // {
  //   title: '用户名/等级',
  //   dataIndex: 'user',
  //   align: 'center',
  //   notShowForm: true,
  //   render: (text) => '',
  // },
  {
    title: '动态内容',
    dataIndex: 'des',
    align: 'center',
    width: 120,
    render: (text) => <div className="des">{text}</div>,
    formate: (text) => <div>{text || '无'}</div>,
  },
  {
    title: '动态贴对外类型',
    dataIndex: 'videoAttribute',
    align: 'center',
    width: 110,
    notShowForm: true,
    // formEditable: true,
    // editable: true,
    // type: 'select',
    // selectList: [
    //   { value: 1, label: '会员片' },
    //   { value: 2, label: '金币片' },
    //   { value: 3, label: '永久免费' },
    //   { value: 4, label: '限时免费' },
    // ],
    // rules: [{ required: true, message: '请选择视频对外类型' }],
    render: (text, record) => {
      if (text == 1) {
        // 1vip视频，2金币视频 3免费
        return '会员片';
      }
      if (text == 2) {
        return `金币片/${record.moviePrice || 0}金币`;
      }
      if (text == 3) {
        return '永久免费';
      }
      if (text == 4) {
        return `限免${record.freeDays}天/${record.freeDateTime}到期`;
      }
      return '未设置';
    },
  },
  // {
  //   title: '限时免费天数',
  //   dataIndex: 'freeDays',
  //   notShowTable: true,
  //   conditionForm: true,
  //   formate: (text, record) => (
  //     <Form.Item
  //       noStyle
  //       shouldUpdate={(prevValues, currentValues) =>
  //         prevValues.videoAttribute !== currentValues.videoAttribute
  //       }
  //     >
  //       {({ getFieldValue }) => {
  //         const videoAttribute = getFieldValue('videoAttribute');
  //         return (
  //           videoAttribute === 4 && (
  //             <Form.Item
  //               label="限时免费天数"
  //               name="freeDays"
  //               rules={[{ required: true, message: '请设置限时免费天数' }]}
  //             >
  //               <InputNumber min={0} />
  //             </Form.Item>
  //           )
  //         );
  //       }}
  //     </Form.Item>
  //   ),
  // },
  // {
  //   title: '金币售价',
  //   dataIndex: 'moviePrice',
  //   notShowTable: true,
  //   conditionForm: true,
  //   formate: () => (
  //     <Form.Item
  //       noStyle
  //       shouldUpdate={(prevValues, currentValues) =>
  //         prevValues.videoAttribute !== currentValues.videoAttribute
  //       }
  //     >
  //       {({ getFieldValue }) => {
  //         const videoAttribute = getFieldValue('videoAttribute');
  //         return (
  //           videoAttribute === 2 && (
  //             <Form.Item
  //               label="金币售价"
  //               name="moviePrice"
  //               rules={[{ required: true, message: '请选择限时免费到期时间' }]}
  //             >
  //               <InputNumber style={{ width: '100%' }} />
  //             </Form.Item>
  //           )
  //         );
  //       }}
  //     </Form.Item>
  //   ),
  // },
  {
    title: '观看量',
    dataIndex: 'watchs',
    align: 'center',
    width: 70,
    // formEditable: true,
    editable: true,
    type: 'inputNumber',
    rules: [{ required: true, message: '请输入播放量' }],
  },
  {
    title: '点赞量',
    dataIndex: 'thumbs',
    align: 'center',
    width: 70,
    // formEditable: true,
    editable: true,
    type: 'inputNumber',
    rules: [{ required: true, message: '请输入收藏量' }],
  },
  {
    title: '已赚金币',
    dataIndex: 'goldNumTotal',
    align: 'center',
    width: 70,
    notShowForm: true,
  },
  {
    title: '是否上架',
    width: 70,
    align: 'center',
    dataIndex: 'onShelf',
    editable: true,
    type: 'switch',
    valuePropName: 'checked',
  },
  {
    title: '动态图',
    dataIndex: 'verCoverList',
    align: 'center',
    width: 120,
    type: 'image',
    render: (text) => <MultiImg text={text} />,

    // notShowForm: true,
  },
  {
    title: '审批通过时间',
    dataIndex: 'examineTime',
    align: 'center',
    width: 100,
    render: (text) => (text ? text.replace('T', ' ') : ''),
    notShowForm: true,
  },
  {
    title: '导演/演员',
    dataIndex: 'actors',
    align: 'center',
    width: 120,
    ellipsisRow: 2,
    type: 'avatar',
    notShowTable: true,
  },
];
