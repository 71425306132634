/* eslint-disable no-shadow */
import { useState } from 'react';
import { PageHeader, Card, Button, Table, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useAntdTable, useMemoizedFn } from 'ahooks';
import { COLUMNS } from './config';
import { AddCutModal, CutDetailModal } from './components';
import { EditableRow, EditableCell } from '@/components';
import { useTableColumns, useDetailModal } from '@/hooks';
import {
  getList,
  add,
  del,
  changeStatus,
} from '@/services/business-management/channel-management/channelCut';
import { getPageUpdate } from '@/utils/commonUtils';

import './index.less';

const components = {
  body: {
    row: EditableRow,
    cell: EditableCell,
  },
};

const getTableData = async ({ current, pageSize }, formData) => {
  const result = await getList({
    page: current,
    size: pageSize,
    config: 0, // 默认0渠道砍量，1砍量配置
  });

  const data = result.data || {};
  const list = data.list || [];
  list.forEach((item) => {
    item.enable = item.state === 1;
  });
  return {
    total: data.total || 0,
    list,
  };
};

function ChannelCut() {
  const { tableProps, run, params, refresh } = useAntdTable(getTableData, {
    defaultParams: [{ current: 1, pageSize: 10 }],
  });
  const [showAdd, setShowAdd] = useState(false);
  const { showDetail, selectRecord, handleShowDetail, handleCloseDetail } =
    useDetailModal();

  const handleSave = async (val) => {
    const result = await changeStatus({
      recedeId: val.recedeId,
    });
    if (result.code === 200) {
      message.success('操作成功');
      refresh();
    }
  };

  const handleDel = useMemoizedFn(async (record) => {
    const result = await del({
      recedeId: record.recedeId,
    });
    if (result.code === 200) {
      message.success('删除成功');
      if (showDetail) {
        handleCloseDetail();
      }
      const page = getPageUpdate(tableProps, 1);
      run({
        ...params[0],
        current: page,
      });
    }
  });

  const { tableColumns, setTableColumns } = useTableColumns(COLUMNS, {
    params,
    onSave: handleSave,
    delTitle: (record) => `确定要删除这条砍量任务吗?`,
    onDelete: handleDel,
    onDetail: handleShowDetail,
  });

  const handlePublish = async (val) => {
    const result = await add(val);
    if (result.code === 200) {
      message.success('发布成功');
      run({
        ...params[0],
        current: 1,
      });
      setShowAdd(false);
    }
  };

  return (
    <div className="cut-config-wrap">
      <PageHeader ghost={false} backIcon={false} title="渠道砍量" />
      <div className="cut-config-content">
        <Card>
          <Button
            type="primary"
            onClick={() => setShowAdd(true)}
            style={{ marginBottom: '10px' }}
          >
            <PlusOutlined />
            新建渠道砍量任务
          </Button>
          <Table
            bordered
            className="edit-table"
            components={components}
            rowClassName={() => 'editable-row'}
            columns={tableColumns}
            scroll={{ x: 'max-content' }}
            {...tableProps}
            pagination={{
              ...tableProps.pagination,
              showTotal: (total) => `共 ${total} 条`,
            }}
            rowKey="recedeId"
          />
        </Card>
      </div>
      <AddCutModal
        visible={showAdd}
        onClose={() => setShowAdd(false)}
        onOk={handlePublish}
      />
      <CutDetailModal
        visible={showDetail}
        data={selectRecord}
        onClose={handleCloseDetail}
        onDel={() => handleDel(selectRecord)}
        onOk={handleSave}
      />
    </div>
  );
}

export default ChannelCut;
