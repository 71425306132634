import { post } from '@/utils/http';

export const getList = (params) =>
  post({
    url: '/manage/video/action/get/page',
    data: params,
  });
export const edit = (params) =>
  post({
    url: '/manage/video/action/update',
    data: params,
  });
export const batchDel = (params) =>
  post({
    url: '/manage/video/action/delete/batch',
    data: params,
  });
export const batchChangeStatus = (params) =>
  post({
    url: '/manage/video/action/status/change',
    data: params,
  });
