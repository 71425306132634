import { PageHeader, Card, Button, Table, Image, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useAntdTable } from 'ahooks';
import { useDetailModal, useModalFormColumns, useTableColumns } from '@/hooks';
import {
  EditableRow,
  EditableCell,
  DetailModal,
  PlayerModal,
} from '@/components';
import { getList, add, edit, del } from '@/services/advertisingManagement';
import { COLUMNS } from './config';
import { isImage } from '@/utils/commonUtils';

const components = {
  body: {
    row: EditableRow,
    cell: EditableCell,
  },
};

const getTableData = async () => {
  const result = await getList({
    pageIndex: 1,
    pageSize: 100000,
  });

  const data = result.data || {};
  return {
    total: data.total || 0,
    list: data.data || [],
  };
};

let NEW_COLUMNS = [];

function AdvertisingManagement() {
  const { tableProps, refresh } = useAntdTable(getTableData);
  const { showDetail, selectRecord, handleShowDetail, handleCloseDetail } =
    useDetailModal();
  const {
    showDetail: showPreview,
    selectRecord: selectVideo,
    handleShowDetail: handleShowPreview,
    handleCloseDetail: handleClosepreview,
  } = useDetailModal();

  if (!NEW_COLUMNS.length) {
    NEW_COLUMNS = COLUMNS.map((item) => {
      if (item.dataIndex !== 'source') {
        return item;
      }
      return {
        ...item,
        render: (text, record) => {
          let content = '';
          if (record.sourceType === 1) {
            // 1图片 2视频
            content = <Image src={text} width="70%" />;
          }
          if (record.sourceType === 2) {
            content = (
              <Button
                type="link"
                size="small"
                onClick={() => handleShowPreview(record)}
              >
                点击播放
              </Button>
            );
          }
          return content;
        },
        format: (text, record) => {
          let content = '';
          if (record.sourceType === 1) {
            // 1图片 2视频
            content = <Image src={text} width="20%" />;
          }
          if (record.sourceType === 2) {
            content = (
              <Button
                type="link"
                size="small"
                onClick={() => handleShowPreview(record)}
              >
                点击播放
              </Button>
            );
          }
          return content;
        },
      };
    });
  }

  const { formColumns, formType, setFormColumns, getModalFormColumns } =
    useModalFormColumns(NEW_COLUMNS);

  const handleSave = async (val) => {
    val.status = val.status ? 1 : 0;

    if (showDetail && formType === 'add') {
      val.sourceType = isImage(val.source) ? 1 : 2; // 资源类型 1图片 2视频
      const result = await add(val);

      if (result.code === 200) {
        message.success('添加成功');
        handleCloseDetail();
        refresh();
      }
    } else {
      const result = await edit(val);
      if (result.code === 200) {
        message.success('编辑成功');
        if (showDetail) {
          handleCloseDetail();
        }
        refresh();
      }
    }
  };

  const handleDel = async (record) => {
    const result = await del({
      adId: record.adId,
    });
    if (result.code === 200) {
      message.success('删除成功');
      refresh();
    }
  };

  const { tableColumns, setTableColumns } = useTableColumns(NEW_COLUMNS, {
    onSave: handleSave,
    delTitle: (record) => `确定要删除该条广告吗?`,
    onDelete: handleDel,
    onDetail: (record) => {
      getModalFormColumns(record, 'detail');
      handleShowDetail(record);
    },
  });

  const handleShowAdd = () => {
    getModalFormColumns({}, 'add');
    handleShowDetail({});
  };

  const onValuesChange = (changedValues, allValues) => {
    if (Object.keys(changedValues)[0] === 'type') {
      // setFormColumns
      getModalFormColumns(allValues, formType);
    }
  };

  return (
    <div className="product-config-wrap">
      <PageHeader ghost={false} backIcon={false} title="广告管理" />
      <div className="content-wrap">
        <Card>
          <Button
            type="primary"
            style={{ marginBottom: '10px' }}
            onClick={handleShowAdd}
          >
            <PlusOutlined />
            添加广告
          </Button>
          <Table
            className="edit-table"
            components={components}
            rowClassName={() => 'editable-row'}
            bordered
            columns={tableColumns}
            {...tableProps}
            pagination={false}
            rowKey="adId"
          />
        </Card>
      </div>
      <DetailModal
        modalType={formType}
        title={formType === 'add' ? '新建商品' : '商品详情'}
        columns={formColumns}
        data={selectRecord}
        visible={showDetail}
        onClose={handleCloseDetail}
        // updateTable={getData}
        onOk={handleSave}
        onValuesChange={onValuesChange}
        centered
      />
      {showPreview && (
        <PlayerModal
          onClose={handleClosepreview}
          url={selectVideo.source}
          videoType="mp4"
          noTab
        />
      )}
    </div>
  );
}

export default AdvertisingManagement;
