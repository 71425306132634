/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-filename-extension */
import { Image } from 'antd';
import {
  getTagList,
  COOPERATION_MAP,
  getCooperationText,
} from '../commonConfig';

export const FILTER1_OPTIONS = [
  { value: '', name: '不限时长' },
  {
    value: 'minMovieDuration_60-maxMovieDuration_300',
    name: '只看1分钟-5分钟作品',
  },
  { value: 'maxMovieDuration_60', name: '只看1分钟以下作品' },
];
export const FILTER2_OPTIONS = [
  { value: '', name: '不限大小' },
  { value: 'maxMovieSize_30720', name: '只看30兆以下作品' },
  { value: 'minMovieSize_30720', name: '只看30兆以上作品' },
];
export const FILTER3_OPTIONS = [
  { value: '', name: '不限用户' },
  { value: 'invite', name: '只看片商作品' },
  { value: 'recharge', name: '只看普通用户作品' },
];

export const SORT_OPTIONS = [
  { value: '', name: '不限排序' },
  { value: 'passRate-DESC', name: '按通过率高低' },
  { value: 'contributionRate-DESC', name: '按利润率高低' },
  { value: 'createTime-ASC', name: '最旧提审' },
  { value: 'createTime-DESC', name: '最近提审' },
  { value: 'factoryGrade-DESC', name: '按片商等级高低' },
  // { value: 'bug', name: '按用户等级高低' },
];

export const COLUMNS = [
  {
    title: '影片id',
    dataIndex: 'movieId',
    align: 'center',
    // ellipsis: true,
    width: 80,
    fixed: 'left',
    notShowForm: true,
  },
  {
    title: '片商名/等级',
    dataIndex: 'factory',
    align: 'center',
    notShowForm: true,
    render: (text) =>
      text ? `${text?.factoryName}/${text?.factoryGrade}` : '',
  },
  // {
  //   title: '用户名/等级',
  //   dataIndex: 'name',
  //   align: 'center',
  //   notShowForm: true,
  // },
  {
    title: '通过率',
    dataIndex: ['factory', 'passRate'],
    align: 'center',
    width: 60,
    notShowForm: true,
    render: (text) => (text || text === 0 ? `${text}%` : ''),
  },
  {
    title: '贡献利润率',
    dataIndex: ['factory', 'contributionRate'],
    align: 'center',
    width: 60,
    notShowForm: true,
    render: (text) => (text || text === 0 ? `${text}%` : ''),
  },
  {
    title: '作品名',
    dataIndex: 'movieName',
    align: 'center',
    width: 120,
    // ellipsisRow: 2,
    formEditable: true,
    type: 'input',
    rules: [
      { required: true, message: '请输入作品名' },
      { type: 'string', max: 27, message: '作品名长度不能超过27个字符' },
    ],
    formSort: 1,
  },
  {
    title: '是否全网首发',
    dataIndex: 'publish',
    align: 'center',
    render: (text) => (text ? '是' : '否'),
    notShowForm: true,
  },
  // {
  //   title: '影片介绍',
  //   dataIndex: 'intro',
  //   align: 'center',
  //   width: 120,
  //   // ellipsisRow: 2,
  //   formEditable: true,
  //   type: 'textarea',
  //   rules: [
  //     { required: true, message: '请输入影片介绍' },
  //     { type: 'string', max: 100, message: '影片介绍长度不能超过100个字符' },
  //   ],
  // },
  {
    title: '影片时长/大小',
    dataIndex: 'movieDuration',
    align: 'center',
    width: 120,
    render: (text, record) =>
      `${Math.ceil((text || 0) / 60)}分钟${Math.ceil(
        (record.movieSize || 0) / 1024
      )}mb`,
    notShowForm: true,
  },
  {
    title: '竖封面',
    dataIndex: 'verCoverUrl',
    align: 'center',
    width: 100,
    // editable: true,
    type: 'image',
    render: (text) => (text ? <Image src={text} width="60%" /> : ''),
    // imageTyoe: 'vertical',
    valuePropName: 'fileList',
    formEditable: true,
    // formDetail
    rules: [{ required: true, message: '请上传竖封面' }],
    formSort: 2,
  },
  {
    title: '影片标签',
    dataIndex: 'tagsList',
    align: 'center',
    width: 120,
    ellipsisRow: 2,
    type: 'tag',
    render: (text) => <div className="des">{(text || []).join('、')}</div>,
    formSort: 6,
    // type: 'labelSelect',
    // formEditable: true,
    // labelList: [
    //   // { value: 'all', label: '不限标签', isAll: true },
    //   { value: '1', label: '标签1标签1' },
    //   { value: '2', label: '标签2' },
    //   { value: '3', label: '标签3标签3' },
    //   { value: '4', label: '标签4标签4' },
    //   { value: '5', label: '标签5' },
    //   { value: '6', label: '标签6' },
    //   { value: '7', label: '标签7' },
    //   { value: '8', label: '标签8' },
    //   { value: '9', label: '标签9' },
    //   { value: '10', label: '标签10' },
    //   { value: '11', label: '标签11' },
    // ],
    // rules: [
    //   { required: true, message: '请选择影片标签' },
    //   {
    //     validator: async (rule, value) => {
    //       console.log(value);
    //       if (value.length > 5) {
    //         throw new Error('最多只能选择5个标签!');
    //       }
    //     },
    //   },
    // ],
  },
  {
    title: '意向合作类型',
    dataIndex: 'cooperationType',
    align: 'center',
    width: 70,
    notShowForm: true,
    render: (text) => COOPERATION_MAP[text],
  },
  {
    title: '意向买断价格',
    dataIndex: 'buyout',
    align: 'center',
    width: 70,
    notShowForm: true,
    render: (text) => (text || text === 0 ? `${text}元` : ''),
  },
  {
    title: '意向分成比例',
    dataIndex: 'royaltyRate',
    align: 'center',
    width: 70,
    notShowForm: true,
    render: (text) => (text || text === 0 ? `${text}%` : ''),
  },

  {
    title: '视频对外类型',
    dataIndex: 'videoAttribute',
    align: 'center',
    width: 70,
    notShowForm: true,
    render: (text, record) => {
      if (text == 1) {
        // 1vip视频，2金币视频 3免费 4.限免
        return '会员片';
      }
      if (text == 2) {
        return `金币片/${record.moviePrice || 0}金币`;
      }
      if (text == 3) {
        return '永久免费';
      }
      return '未设置';
    },
  },
  {
    title: '所属专题',
    dataIndex: 'subjectId',
    align: 'center',
    render: (_, record) => record.subjectName,
    formEditable: true,
    type: 'labelSelect',
    labelList: getTagList(2),
    labelSelectMode: 'single',
    formSort: 4,
  },
  {
    title: '是否推荐到每日主打',
    dataIndex: 'recommend',
    align: 'center',
    editable: true,
    required: true,
    type: 'switch',
    valuePropName: 'checked',
    switchText: ['已推荐', '未推荐'],
    formSort: 3,
  },
  {
    title: '提审时间',
    dataIndex: 'createTime',
    align: 'center',
    width: 90,
    notShowForm: true,
    render: (text) => text?.replace('T', ' '),
  },
  {
    title: '地区标签',
    dataIndex: 'address',
    notShowTable: true,
    formEditable: false,
    type: 'tag',
    // labelList: [
    //   { value: 1, label: '地区1' },
    //   { value: 2, label: '地区2' },
    //   { value: 3, label: '地区3' },
    //   { value: 4, label: '地区4' },
    // ],
    // rules: [{ required: true, message: '请选择地区标签' }],
    formSort: 5,
  },
  {
    title: '合作意向',
    dataIndex: 'cooperationType',
    align: 'center',
    notShowTable: true,
    formate: (text, record) => getCooperationText(text, record, true),
    formSort: 7,
  },
];

export const TURN_DOWN_LABELS = [
  { value: '1', label: '没有平台广告' },
  { value: '2', label: '没有片头或片尾' },
  { value: '3', label: '没有官方漂浮水印' },
  { value: '4', label: '封面太差' },
  { value: '5', label: '没有带官方冷启动' },
  { value: '6', label: '封面没带标题' },
  { value: '7', label: '剧情内容不符合要求' },
  { value: '8', label: '清晰度太低' },
  { value: '9', label: '演员与介绍不符' },
  { value: '10', label: '预告片质量太低' },
  { value: '11', label: '恶意上传刷量，请尽快整改，否则封号处理' },
];
