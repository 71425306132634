export const COLUMNS = [
  {
    title: '功能项',
    dataIndex: 'feature',
    align: 'center',
  },
  {
    title: '图片配置',
    dataIndex: 'pictureUrl',
    align: 'center',
    editable: true,
    type: 'image',
    valuePropName: 'fileList',
    uploadUrl: '/manage/config/baseConfig/image/upload',
  },
  {
    title: '文本配置',
    dataIndex: 'content',
    align: 'center',
    // ellipsis: true,
    editable: true,
    type: 'input',
    rules: [{ required: true, message: '请输入文本配置' }],
  },
  {
    title: '域名配置',
    dataIndex: 'domain',
    align: 'center',
    ellipsis: true,
    editable: true,
    type: 'input',
    rules: [{ required: true, message: '请输入域名配置' }],
  },
];

export const DATA_SOURCE = [
  {
    key: 0,
    name: '官网域名展示',
    image: 'no-need',
    domain: 'no-need',
    text: 'www.baidu.comwww.baidu.comwww.baidu.com',
  },
  {
    key: 1,
    name: 'app桌面图标配置',
    text: 'no-need',
    domain: 'no-need',
    image: [
      {
        url: 'https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png',
      },
    ],
  },
  {
    key: 2,
    name: '分享文字',
    image: 'no-need',
    text: '快来使用这个软件吧，点击后方链接:',
    domain: 'no-need',
  },
  {
    key: 3,
    name: '片商合作链接',
    image: 'no-need',
    text: '片商演员合作(需翻墙)',
    domain: 'www.baidu.com',
  },
];
