/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Form } from 'antd';

export const EditableContext = React.createContext(null);

function EditableRow({ index, ...props }) {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
}
export default EditableRow;
