import { post } from '@/utils/http';

export const getList = (params) =>
  post({
    url: '/manage/channel/recede/listRecede',
    data: params,
  });
export const add = (params) =>
  post({
    url: '/manage/channel/recede/publishRecede',
    data: params,
  });

export const del = (params) =>
  post({
    url: '/manage/channel/recede/delete',
    data: params,
  });
export const check = (params) =>
  post({
    url: '/manage/channel/recede/checkChannelId',
    data: params,
  });
export const changeStatus = (params) =>
  post({
    url: '/manage/channel/recede/loadOn',
    data: params,
  });
