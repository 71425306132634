/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Button, Form, Input, message } from 'antd';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRequest } from 'ahooks';
import { UserOutlined, LockOutlined, SafetyOutlined } from '@ant-design/icons';
import * as Lockr from 'lockr';
import { SliderVertify } from '@/components';
import { login, getCaptcha } from '@/services/login';

import './index.less';

function Login({ onLogin }) {
  const navigate = useNavigate();
  const [verify, setVerify] = useState(false);
  const {
    data: captchaData,
    refresh,
    loading,
  } = useRequest(async () => {
    const result = await getCaptcha();
    console.log(result);
    let url;
    if (result && result.type !== 'application/json') {
      url = window.URL.createObjectURL(result);
    } else {
      message.error('获取验证码失败');
    }
    return url;
  });
  const [form] = Form.useForm();
  const rules = useRef({
    username: [{ required: true, message: '请输入管理员账号' }],
    pwd: [{ required: true, message: '请输入密码' }],
    code: [{ required: true, message: '请输入验证码' }],
  });
  const onFinish = async (values) => {
    if (verify) {
      const result = await login(values);

      // 模拟数据
      // result.code = 200;
      // result.data = {
      //   role: 1, // 角色，超管0，管理员1
      //   // menu: ['B0', 'B5', 'B51', 'F0', 'D0', 'D1', 'D2', 'D3'], // 权限下的菜单code，超管没有菜单，默认全部菜单
      //   menu: [],
      //   username: '111',
      //   nickname: '超管',
      // };

      if (result.code === 200) {
        message.success('登陆成功');
        Lockr.set('user', {
          // value: result.data?.menu || [],
          value: result.data,
          expires: 24 * 60 * 60 * 1000,
          startTime: new Date().getTime(),
        });
        onLogin();
      }
    } else {
      message.error('请进行图片拖动验证');
    }
  };
  return (
    <div className="login-wrap">
      <div className="login-content">
        <Form
          wrapperCol={{ span: 24 }}
          form={form}
          onFinish={onFinish}
          name="login-form"
          validateTrigger={['onBlur', 'onChange']}
        >
          <Form.Item name="username" rules={rules.current.username}>
            <Input
              prefix={<UserOutlined />}
              size="large"
              placeholder="管理员账号"
            />
          </Form.Item>
          <Form.Item name="pwd" rules={rules.current.pwd}>
            <Input.Password
              size="large"
              prefix={<LockOutlined />}
              placeholder="密码"
            />
          </Form.Item>
          <Form.Item noStyle>
            <Form.Item
              name="code"
              rules={rules.current.code}
              style={{ display: 'inline-block', marginRight: '8px' }}
            >
              <Input
                size="large"
                prefix={<SafetyOutlined />}
                placeholder="验证码"
                style={{ width: '200px' }}
              />
            </Form.Item>
            <img
              src={captchaData}
              alt=""
              className="code-img"
              onClick={refresh}
            />
          </Form.Item>

          <SliderVertify onSuccess={() => setVerify(true)} />
          <Form.Item>
            <Button type="primary" htmlType="submit" size="large" block>
              登录
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default Login;
