import { useEffect, useRef } from 'react';
// import 'xgplayer';
import XgPlayer from 'xgplayer';
import Mp4Player from 'xgplayer-mp4';
import HlsJsPlayer from 'xgplayer-hls.js';
import uid from 'uid';
import './index.less';

function Player({ active, type, url }) {
  const playerDom = useRef(null);
  const player = useRef(null);
  useEffect(() => {
    if (playerDom.current) {
      player.current =
        type === 'mp4'
          ? new XgPlayer({
              el: playerDom.current,
              // url: 'https://storage.cloud.google.com/houtai1237/zip_movie/20220912/FR1569177538981515264/VID_20220912121158838.mp4',
              url,
              playsinline: true,
              // maxBufferLength: 10, // 设置最大缓冲区时长，默认5秒
            })
          : new HlsJsPlayer({
              el: playerDom.current,
              // url: '/sintel/master.m3u8',
              url,
              autoplay: false,
              playsinline: true,
            });
    }
  }, []);
  useEffect(() => {
    if (!active && playerDom.current && !player.current.paused) {
      console.log(player.current.paused);
      player.current.pause();
    }
  }, [active]);
  return <div className="player" ref={playerDom} />;
}

export default Player;
