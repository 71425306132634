/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useState, useEffect, useRef } from 'react';
import {
  Input,
  Form,
  DatePicker,
  InputNumber,
  Image,
  Upload,
  Select,
  Switch,
  message,
  Space,
} from 'antd';
import { EditOutlined, LoadingOutlined } from '@ant-design/icons';
import xss from 'xss';
import moment from 'moment';
import { getLockrValue } from '@/utils/commonUtils';
import { EditableContext } from '../editable-row';
import './index.less';

const whiteArr = ['style'];
const { TextArea } = Input;
const { Option } = Select;

const onIgnoreTagAttr = (tag, name, value, isWhiteAttr) => {
  if (whiteArr.indexOf(name) > -1) {
    // 通过内置的escapeAttrValue函数来对属性值进行转义
    // console.log(name, value);
    return `${name}="${xss.escapeAttrValue(value)}"`;
  }
  return false;
};

function EditableCell({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  type,
  ellipsis,
  valuePropName,
  selectList,
  rules,
  ellipsisRow,
  render,
  // condition,
  formSort,
  imageType,
  detailNotEditable,
  format, // form用的
  notShowTime,
  uploadUrl,
  notShowForm,
  min,
  // renderSwitchText,
  labelSelectMode,
  labelList,
  formEditable,
  switchText,
  ...restProps
}) {
  const [editing, setEditing] = useState(false);
  const [uploading, setUploading] = useState(false);
  const inputRef = useRef(null);
  // const dateRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editing]);

  useEffect(() => {
    if (type === 'switch') {
      form.setFieldsValue(record);
    }
  }, [type, record]);

  const toggleEdit = () => {
    if (type === 'image') {
      return;
    }
    setEditing(!editing);
    // form.setFieldsValue({
    //   [dataIndex]: record[dataIndex],
    // });
    if (!editing) {
      if (type === 'date') {
        form.setFieldsValue({
          ...record,
          [dataIndex]: record[dataIndex] ? moment(record[dataIndex]) : null,
        });
        return;
      }
      if (type === 'image') {
        let formateValue;
        if (record[dataIndex]) {
          const imageData = Array.isArray(record[dataIndex])
            ? record[dataIndex]
            : [record[dataIndex]];
          formateValue = imageData.map((url) => ({ url }));
        }

        form.setFieldsValue({
          ...record,
          [dataIndex]: formateValue,
        });
        return;
      }

      form.setFieldsValue(record);
    }
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      console.log(record, values);
      if (type === 'date') {
        values[dataIndex] = notShowTime
          ? values[dataIndex].format('YYYY-MM-DD')
          : values[dataIndex].format('YYYY-MM-DD HH:mm');
      }
      handleSave({ ...record, ...values }, dataIndex);
      form.setFieldsValue(values);
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
      toggleEdit();
    }
  };

  const handleOpenChange = async (open) => {
    if (!open) {
      setEditing(false);
    }
  };

  const handleChangeImage = (file) => {
    setUploading(true);
    setTimeout(() => {
      setUploading(false);
    }, 5 * 1000);
    console.log(file);
    return false;
  };

  const uploadChange = async ({ file }) => {
    if (!uploading) {
      setUploading(true);
    }
    const { response } = file;
    if (!response) {
      return;
    }

    // 模拟数据
    // response.code = 200;
    // response.data = {
    //   path: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // };

    setUploading(false);
    if (response.code === 200) {
      message.success('上传成功');

      handleSave({ ...record, [dataIndex]: response.data.path });
    } else {
      message.error(response.msg || '上传失败');
    }
  };

  let childNode = children;
  let cellNode = children;

  const FORM_ITEM_MAP = {
    input: <Input ref={inputRef} onPressEnter={save} onBlur={save} />,
    textarea: <TextArea rows={2} ref={inputRef} onBlur={save} />,
    date: (
      <DatePicker
        showTime={notShowTime ? false : { format: 'HH:mm' }}
        // onOk={save}
        format={notShowTime ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm'}
        onOpenChange={handleOpenChange}
        allowClear={false}
        open={editing}
        onChange={save}
      />
    ),
    inputNumber: (
      <InputNumber
        ref={inputRef}
        onPressEnter={save}
        onBlur={save}
        min={min || 0}
      />
    ),
    select: (
      <Select
        style={{ width: '100%' }}
        onDropdownVisibleChange={handleOpenChange}
        open={editing}
        onChange={save}
      >
        {(
          (typeof selectList === 'function'
            ? selectList(record)
            : selectList) || []
        ).map((item) => (
          <Option
            value={item.value}
            key={item.value}
            className="edit-cell-option"
          >
            {item.label}
          </Option>
        ))}
      </Select>
    ),
    switch: switchText ? (
      <Switch
        onChange={save}
        checkedChildren={switchText[0]}
        unCheckedChildren={switchText[1]}
      />
    ) : (
      <Switch onChange={save} />
    ),
  };

  if (type === 'image' && record[dataIndex] !== 'no-need') {
    let formateValue = [];
    if (record[dataIndex]) {
      formateValue = Array.isArray(record[dataIndex])
        ? record[dataIndex]
        : [record[dataIndex]];
    }
    cellNode = (
      <div onClick={(e) => e.stopPropagation()} className="image-cell-wrap">
        {formateValue.map((item) => (
          <Image
            src={item}
            width={imageType === 'vertical' ? '50%' : '70%'}
            key={item}
          />
        ))}

        <span className={`edit ${uploading ? 'uploading' : ''}`}>
          <Upload
            accept="image/*"
            action={uploadUrl}
            data={{ request: JSON.stringify({ path: record[dataIndex] }) }}
            onChange={uploadChange}
            itemRender={() => ''}
            headers={{
              'remind-me': getLockrValue('remindMe') || '',
            }}
            maxCount={1}
          >
            <EditOutlined
              style={{
                fontSize: '20px',
                marginLeft: '8px',
                display: uploading ? 'none' : 'inline-block',
              }}
            />
          </Upload>
          <LoadingOutlined
            style={{
              fontSize: '20px',
              marginLeft: '8px',
              display: uploading ? 'inline-block' : 'none',
            }}
          />
        </span>
      </div>
    );
  }

  // if (type === 'select') {
  //   formItemNode = (
  //     <Select
  //       style={{ width: '100%' }}
  //       onDropdownVisibleChange={handleOpenChange}
  //       open={editing}
  //       onChange={save}
  //     >
  //       {(selectList || []).map((item) => (
  //         <Option
  //           value={item.value}
  //           key={item.value}
  //           className="edit-cell-option"
  //         >
  //           {item.label}
  //         </Option>
  //       ))}
  //     </Select>
  //   );
  // }

  // if (type === 'switch') {
  //   formItemNode = <Switch onChange={save} />;
  // }

  if (editable) {
    if (record[dataIndex] === 'no-need') {
      childNode = '';
    } else if (record[`${dataIndex}Rich`] || type === 'editor') {
      childNode = (
        <div
          dangerouslySetInnerHTML={{
            __html: xss(record[dataIndex], {
              onIgnoreTagAttr,
            }),
          }}
        />
      );
    } else {
      childNode =
        editing || type === 'switch' ? (
          <Form.Item
            style={{
              margin: 0,
            }}
            name={dataIndex}
            valuePropName={valuePropName || 'value'}
            rules={rules}
          >
            {/* {formItemNode} */}
            {FORM_ITEM_MAP[type]}
          </Form.Item>
        ) : (
          <div
            style={
              ellipsisRow
                ? {
                    maxHeight: `${22.5 * ellipsisRow}px`,
                    WebkitLineClamp: ellipsisRow,
                  }
                : {}
            }
            className={`editable-cell-value-wrap ${
              ellipsis ? 'ellipsis-cell' : ''
            } ${type === 'image' ? 'editable-cell-no-hover' : ''} ${
              ellipsisRow ? 'ellipsis-row' : ''
            }`}
            onClick={toggleEdit}
          >
            {cellNode}
          </div>
        );
    }
  }

  return <td {...restProps}>{childNode}</td>;
}

export default EditableCell;
