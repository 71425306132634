import { PageHeader, Card, Table, message } from 'antd';
import { useRequest } from 'ahooks';
import { EditableRow, EditableCell, DetailModal } from '@/components';
import { useDetailModal, useTableColumns } from '@/hooks';
import { COLUMNS, DATA_SOURCE } from './config';
import {
  getList,
  edit,
} from '@/services/business-management/config-management/basicConfig';

import './index.less';

const components = {
  body: {
    row: EditableRow,
    cell: EditableCell,
  },
};

const getConfigList = async () => {
  const result = await getList();
  const list = result.data || [];
  list.forEach((item) => {
    item.pictureUrl = item.pictureUrl || 'no-need';
    item.content = item.content || 'no-need';
    item.domain = item.domain || 'no-need';
  });
  return list;
};

function BasicConfig() {
  const { data: dataSource, loading, refresh } = useRequest(getConfigList);
  const { showDetail, selectRecord, handleShowDetail, handleCloseDetail } =
    useDetailModal();

  const handleSave = async (val) => {
    const result = await edit(val);
    if (result.code === 200) {
      message.success('编辑成功');
      refresh();
      if (showDetail) {
        handleCloseDetail();
      }
    }
  };

  const { tableColumns, setTableColumns } = useTableColumns(COLUMNS, {
    onSave: handleSave,
    onDetail: (record) => handleShowDetail(record),
    noDelete: true,
  });

  return (
    <div className="basic-config">
      <PageHeader ghost={false} backIcon={false} title="基础配置" />
      <div className="basic-content-wrap">
        <Card>
          <Table
            className="edit-table"
            components={components}
            rowClassName={() => 'editable-row'}
            bordered
            dataSource={dataSource}
            columns={tableColumns}
            pagination={false}
            loading={loading}
            rowKey="baseConfigId"
          />
        </Card>
      </div>
      <DetailModal
        visible={showDetail}
        data={selectRecord}
        columns={COLUMNS}
        onClose={handleCloseDetail}
        onOk={handleSave}
        title="配置详情"
      />
    </div>
  );
}

export default BasicConfig;
