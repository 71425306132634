import { useDeepCompareEffect } from 'ahooks';
import { useState } from 'react';

const useTableRowSelection = ({ dataSource, keyName }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  useDeepCompareEffect(() => {
    const keys = selectedRowKeys.filter((key) =>
      dataSource.find((item) => item[keyName] === key)
    );
    setSelectedRowKeys(keys);
  }, [dataSource, selectedRowKeys]);

  return [selectedRowKeys, setSelectedRowKeys];
};

export default useTableRowSelection;
