/* eslint-disable react/jsx-filename-extension */
import { SortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import { Tag } from 'antd';

const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
));

export const SORT1_OPTIONS = [
  { value: 'all', name: '不限今日排序' },
  { value: 'invite', name: '今日邀请最多' },
  { value: 'recharge', name: '今日业绩最多' },
  { value: 'vip', name: '今日VIP分佣最多' },
  { value: 'coin', name: '今日金币分佣最多' },
];

export const COLUMNS = [
  // {
  //   title: '排序',
  //   dataIndex: 'sort',
  //   align: 'center',
  //   width: 50,
  //   className: 'drag-visible',
  //   render: () => <DragHandle />,
  // },
  {
    title: 'id',
    dataIndex: 'id',
    align: 'center',
    ellipsis: true,
    className: 'drag-visible',
    width: 100,
  },
  {
    title: '通道编码',
    dataIndex: 'channelCode',
    align: 'center',
    className: 'drag-visible',
  },
  {
    title: '平台名称',
    dataIndex: 'platformName',
    align: 'center',
    className: 'drag-visible',
  },
  {
    title: '通道名称',
    dataIndex: 'channelName',
    align: 'center',
    className: 'drag-visible',
  },
  {
    title: '权重',
    dataIndex: 'weight',
    align: 'center',
    width: 70,
    className: 'drag-visible',
  },
  {
    title: '通道状态',
    dataIndex: 'status',
    align: 'center',
    width: 80,
    type: 'switch',
    aditable: true,
    className: 'drag-visible',
    render: (text) =>
      text === 1 ? (
        <Tag color="success">开启</Tag>
      ) : (
        <Tag color="error">关闭</Tag>
      ),
  },
  {
    title: '支付额度',
    dataIndex: 'amountList',
    align: 'center',
    className: 'drag-visible',
    render: (text) => text?.join('、'),
  },
];
