import { post } from '@/utils/http';

export const getLabelList = (params) =>
  post({
    url: '/manage/tag/manage/get/page',
    data: params,
  });
export const add = (params) =>
  post({
    url: '/manage/tag/manage/insert',
    data: params,
  });
export const edit = (params) =>
  post({
    url: '/manage/tag/manage/update',
    data: params,
  });
export const batchDelete = (params) =>
  post({
    url: '/manage/tag/manage/delete/batch',
    data: params,
  });
export const batchChangeStatus = (params) =>
  post({
    url: '/manage/tag/manage/status/change',
    data: params,
  });
export const getAllLabel = (params) =>
  post({
    url: '/manage/tag/manage/get/multi',
    data: params,
  });
