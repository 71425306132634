import { useState } from 'react';
import { Modal, Button, Tabs } from 'antd';
import { Player } from '@/components';

import './index.less';

const { TabPane } = Tabs;

function FilmModal({ onClose }) {
  const [activeKey, setActiveKey] = useState('1');

  const handleChangeTab = (key) => {
    setActiveKey(key);
  };

  return (
    <Modal
      title="预览视频"
      visible
      className="film-modal"
      width={1000}
      destroyOnClose
      onCancel={onClose}
      onOk={onClose}
    >
      <Tabs activeKey={activeKey} onChange={handleChangeTab}>
        <TabPane tab="预告片" key="1">
          <div className="player-content">
            <Player active={activeKey === '1'} />
          </div>
        </TabPane>
        <TabPane tab="正片" key="2">
          <div className="player-content">
            <Player active={activeKey === '2'} />
          </div>
        </TabPane>
      </Tabs>
    </Modal>
  );
}

export default FilmModal;
