/* eslint-disable no-shadow */
/* eslint-disable react/jsx-filename-extension */
import { Form, Input, InputNumber, Select, Image, Button } from 'antd';

const { Option } = Select;

const SYSTEM_LIST = [
  {
    value: 'ios',
    label: '苹果',
  },
  {
    value: 'android',
    label: '安卓',
  },
];

const UPDATE_TERMINAL = [
  {
    value: 'android',
    label: '安卓',
  },
  {
    value: 'ios_shop',
    label: '苹果商店版',
  },
  {
    value: 'ios_company',
    label: '苹果企业签',
  },
  {
    value: 'ios_pwa',
    label: '苹果pwa',
  },
];

const UPDATE_TYPE = [
  {
    value: '3',
    label: '灰更',
  },
  {
    value: '2',
    label: '软更',
  },
  {
    value: '1',
    label: '强更',
  },
];

export const COLUMNS = [
  {
    title: '更新版本',
    dataIndex: 'versionNum',
    align: 'center',
    width: 80,
    editable: true,
    type: 'input',
    rules: [{ required: true, message: '请输入更新版本' }],
  },
  {
    title: '更新系统',
    dataIndex: 'system',
    align: 'center',
    width: 70,
    editable: false,
    formEditable: true,
    detailNotEditable: true,
    type: 'select',
    rules: [{ required: true, message: '请选择更新系统' }],
    selectList: SYSTEM_LIST,
    render: (text, record, index) => {
      const item = SYSTEM_LIST.find((item) => item.value === text);
      return item?.label;
    },
  },
  {
    title: '更新端口',
    dataIndex: 'port',
    align: 'center',
    editable: false,
    formEditable: true,
    detailNotEditable: true,
    type: 'select',
    width: 80,
    rules: [{ required: true, message: '请输入更新端口' }],
    selectList: UPDATE_TERMINAL,
    render: (text, record, index) => {
      const item = UPDATE_TERMINAL.find((item) => item.value === text);
      return item?.label;
    },
  },
  {
    title: '更新方式',
    dataIndex: 'updateWay',
    align: 'center',
    width: 70,
    editable: true,
    type: 'select',
    rules: [{ required: true, message: '请选择更新方式' }],
    // selectList: UPDATE_TYPE,
    selectList: (record) =>
      record.system === 'ios'
        ? UPDATE_TYPE.filter((item) => item.label !== '灰更')
        : UPDATE_TYPE,
    render: (text, record, index) => {
      const type = UPDATE_TYPE.find((item) => item.value === text);
      return type ? type.label : '';
    },
    notShowForm: true,
  },
  {
    title: '更新方式',
    dataIndex: 'updateWay',
    notShowTable: true,
    customForm: true,
    conditionForm: true,
    format: () => (
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.system !== currentValues.system
        }
      >
        {({ getFieldValue }) => {
          const system = getFieldValue('system');
          const options =
            system === 'ios'
              ? UPDATE_TYPE.filter((item) => item.label !== '灰更')
              : UPDATE_TYPE;

          return (
            <Form.Item
              label="更新方式"
              name="updateWay"
              rules={[{ required: true, message: '请选择更新方式' }]}
            >
              <Select>
                {options.map((item) => (
                  <Option value={item.value} key={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          );
        }}
      </Form.Item>
    ),
  },
  {
    title: '更新时间',
    dataIndex: 'createTime',
    align: 'center',
    width: 140,
    render: (text) => (text ? text.replace('T', ' ') : ''),
    format: (text) => (text ? text.replace('T', ' ') : ''),
    formEditable: true,
    detailNotEditable: true,
    type: 'date',
    rules: [{ required: true, message: '请选择更新时间' }],
  },
  {
    title: '更新标题',
    dataIndex: 'title',
    align: 'center',
    width: 150,
    // ellipsis: true,
    editable: true,
    type: 'input',
    rules: [{ required: true, message: '请输入更新标题' }],
  },
  {
    title: '更新文案',
    dataIndex: 'des',
    align: 'center',
    editable: true,
    type: 'textarea',
    rules: [{ required: true, message: '请输入更新文案' }],
  },
  {
    title: '更新链接',
    dataIndex: 'appUrl',
    align: 'center',
    width: 150,
    editable: true,
    ellipsis: true,
    type: 'input',
    render: (text) => (
      <a href={text} target="_blank" rel="noreferrer">
        {text}
      </a>
    ),
  },
  {
    title: '上传更新包',
    dataIndex: 'path',
    notShowTable: true,
    formEditable: true,
    type: 'upload',
    valuePropName: 'fileList',
    uploadUrl: '/manage/config/version/image/upload',
  },
  {
    title: '是否生效',
    dataIndex: 'status',
    align: 'center',
    editable: true,
    type: 'switch',
    valuePropName: 'checked',
    width: 70,
    rules: [{ required: true, message: '请选择是否生效' }],
  },
];
