/* eslint-disable react/jsx-filename-extension */
import { Image } from 'antd';

export const SORT1_OPTIONS = [
  { value: 0, name: '不限今日排序' },
  { value: 1, name: '今日邀请最多' },
  { value: 2, name: '今日业绩最多' },
  { value: 3, name: '今日VIP分佣最多' },
  { value: 4, name: '今日金币分佣最多' },
];
export const SORT2_OPTIONS = [
  { value: 0, name: '不限历史排序' },
  { value: 1, name: '历史邀请最多' },
  { value: 2, name: '历史业绩最多' },
  { value: 3, name: '历史VIP分佣最多' },
  { value: 4, name: '历史金币分佣最多' },
  { value: 5, name: '余额最多' },
  { value: 6, name: '历史提现最多' },
];

export const COLUMNS = [
  {
    title: '渠道id',
    dataIndex: 'channelId',
    align: 'center',
    ellipsis: true,
    width: 80,
    fixed: 'left',
    notShowForm: true,
  },
  {
    title: '渠道名',
    dataIndex: 'channelName',
    align: 'center',
    formEditable: true,
    type: 'input',
    rules: [{ required: true, message: '请输入渠道名' }],
    formSort: 1,
  },
  {
    title: '渠道链接',
    dataIndex: 'channelUrl',
    align: 'center',
    ellipsis: true,
    width: 120,
    render: (text) => (
      <a href={text} target="_blank" rel="noreferrer">
        {text}
      </a>
    ),
    // formEditable: true,
    type: 'input',
    rules: [
      { required: true, message: '请输入渠道链接' },
      { type: 'url', message: '请输入正确的渠道链接' },
    ],
    formSort: 3,
    suffix: '复制',
    suffixType: 'copy',
  },
  {
    title: '分佣比例',
    dataIndex: 'percent',
    align: 'center',
    width: 120,
    notShowForm: true,
    render: (text, record) =>
      `VIP${record.percentVip || 0}%/金币${record.percentCoin || 0}%`,
  },
  {
    title: '今日邀请 / 历史邀请',
    dataIndex: 'invite',
    align: 'center',
    width: 130,
    notShowForm: true,
    render: (text, record, index) =>
      `${record.inviteToday || 0}/${record.inviteTotal}`,
  },
  {
    title: '今日贡献业绩 / 历史贡献业绩',
    dataIndex: 'income',
    align: 'center',
    width: 100,
    notShowForm: true,
    render: (text, record, index) =>
      `${record.incomeToday || 0}/${record.incomeTotal || 0}`,
  },
  {
    title: '今日VIP分佣 / 历史VIP分佣',
    dataIndex: 'vip',
    align: 'center',
    width: 100,
    notShowForm: true,
    render: (text, record, index) => `${record.vipToday}/${record.vipTotal}`,
  },
  {
    title: '今日金币分佣 / 历史金币分佣',
    dataIndex: 'coin',
    align: 'center',
    width: 100,
    notShowForm: true,
    render: (text, record, index) => `${record.coinToday}/${record.coinTotal}`,
  },
  {
    title: '账户余额',
    dataIndex: 'amount',
    align: 'center',
    width: 70,
    formSort: 17,
  },
  {
    title: '已提现',
    dataIndex: 'withdraw',
    align: 'center',
    width: 70,
    formSort: 18,
  },
  {
    title: '提现门槛',
    dataIndex: 'withdrawLimit',
    align: 'center',
    width: 70,
    formEditable: true,
    type: 'inputNumber',
    rules: [{ required: true, message: '请输入提现门槛' }],
    formSort: 6,
    suffix: '元',
  },
  {
    title: '是否禁用',
    dataIndex: 'disable',
    align: 'center',
    editable: true,
    type: 'switch',
    valuePropName: 'checked',
    width: 70,
    rules: [{ required: true, message: '请选择是否禁用' }],
    formSort: 7,
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    align: 'center',
    width: 90,
    notShowForm: true,
    render: (text) => (text ? text.replace('T', ' ') : ''),
  },
  {
    title: '登录密码',
    dataIndex: 'pwd',
    notShowTable: true,
    formEditable: true,
    type: 'password',
    rules: [{ required: true, message: '请输入登录密码' }],
    formSort: 2,
  },
  {
    title: 'VIP分佣比例',
    dataIndex: 'percentVip',
    notShowTable: true,
    formEditable: true,
    type: 'inputNumber',
    rules: [{ required: true, message: '请输入VIP分佣比例' }],
    formSort: 4,
    suffix: '%',
  },
  {
    title: '金币分佣比例',
    dataIndex: 'percentCoin',
    notShowTable: true,
    formEditable: true,
    type: 'inputNumber',
    rules: [{ required: true, message: '请输入金币分佣比例' }],
    formSort: 5,
    suffix: '%',
  },
  {
    title: '',
    dataIndex: '',
    notShowTable: true,
    formSort: 8,
  },
  {
    title: '今日邀请',
    dataIndex: 'inviteToday',
    notShowTable: true,
    formSort: 9,
  },
  {
    title: '历史邀请',
    dataIndex: 'inviteTotal',
    notShowTable: true,
    formSort: 10,
  },
  {
    title: '今日贡献业绩',
    dataIndex: 'incomeToday',
    notShowTable: true,
    formSort: 11,
  },
  {
    title: '历史贡献业绩',
    dataIndex: 'incomeTotal',
    notShowTable: true,
    formSort: 12,
  },
  {
    title: '今日VIP分佣',
    dataIndex: 'vipToday',
    notShowTable: true,
    formSort: 13,
  },
  {
    title: '历史VIP分佣',
    dataIndex: 'vipTotal',
    notShowTable: true,
    formSort: 14,
  },
  {
    title: '今日金币分佣',
    dataIndex: 'coinToday',
    notShowTable: true,
    formSort: 15,
  },
  {
    title: '历史金币分佣',
    dataIndex: 'coinTotal',
    notShowTable: true,
    formSort: 16,
  },
];
