import { post } from '@/utils/http';

export const getList = (params) =>
  post({
    url: '/manage/video/long/get/page',
    data: params,
  });
export const edit = (params) =>
  post({
    url: '/manage/video/long/update',
    data: params,
  });
export const batchDel = (params) =>
  post({
    url: '/manage/video/long/delete/batch',
    data: params,
  });
export const batchChangeStatus = (params) =>
  post({
    url: '/manage/video/long/status/change',
    data: params,
  });
export const examineAgain = (params) =>
  post({
    url: '/manage/video/long/examineAgain',
    data: params,
  });
