import { post } from '@/utils/http';

export const getList = (params) =>
  post({
    url: '/manage/channel/manage/list',
    data: params,
  });
export const add = (params) =>
  post({
    url: '/manage/channel/manage/submit',
    data: params,
  });

export const del = (params) =>
  post({
    url: '/manage/channel/manage/del',
    data: params,
  });
