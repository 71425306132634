// import logo from './logo.svg';
import { useState, useEffect } from 'react';
import { Button } from 'antd';
import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { useDeepCompareEffect, useUpdate } from 'ahooks';
import * as Lockr from 'lockr';

import Login from '@/pages/login';
import Home from '@/pages/data';

import FilmingManagement from '@/pages/business-management/filming-management';
import UserManagement from '@/pages/business-management/user-management';
import UpdateManagement from '@/pages/business-management/update-management';
import FilmCompanyManagement from '@/pages/business-management/film-company-management';
import OrderManagement from '@/pages/business-management/order-management';

import CutConfig from '@/pages/business-management/config-management/cut-config';
import BasicConfig from '@/pages/business-management/config-management/basic-config';
import AnnouncementConfig from '@/pages/business-management/config-management/announcement-config';
import ThematicConfig from '@/pages/business-management/config-management/thematic-config';
import LabelConfig from '@/pages/business-management/config-management/label-config';
import ProductConfig from '@/pages/business-management/config-management/product-config';
import AwardConfig from '@/pages/business-management/config-management/award-config';
import DomainConfig from '@/pages/business-management/config-management/domain-config';

import ChannelList from '@/pages/business-management/channel-management/channel-list';
import ChannelCut from '@/pages/business-management/channel-management/channel-cut';

import LongVideoManagement from '@/pages/business-management/works-management/long-video-management';
import ShortVideoManagement from '@/pages/business-management/works-management/short-video-management';
import StickerManagement from '@/pages/business-management/works-management/sticker-management';

import LongVideoApproval from '@/pages/approval-management/long-video-approval';
import ShortVideoApproval from '@/pages/approval-management/short-video-approval';
import StickerApproval from '@/pages/approval-management/sticker-approval';
import TriviaApproval from '@/pages/approval-management/trivia-approval';
import CashOutApproval from '@/pages/approval-management/cash-out-approval';
import CommentApproval from '@/pages/approval-management/comment-approval';
import AdWordFilter from '@/pages/approval-management/ad-word-filter';

import WorkOrder from '@/pages/customer-service/work-order';
import QuickMessage from '@/pages/customer-service/quick-message';
import DomainTest from '@/pages/customer-service/domain-test';

import AisleManagement from '@/pages/aisle-management';

import AdvertisingManagement from '@/pages/advertising-management';
import ModalManagement from '@/pages/modal-management';

import NotFound from '@/pages/404';

import { MainLayout, CustomerServiceLayout } from '@/components';

import menuData from '@/config/menuData';

import { getLockrValue, getUrl } from '@/utils/commonUtils';

import './App.less';
import './reset-antd.less';

const getCurrentMenu = (menuData, pathname) => {
  let activeCurrentMenu; // 具体某个菜单
  const getActiveMenu = (data) => {
    data.forEach((item) => {
      if (activeCurrentMenu) {
        return;
      }
      if (item.url === pathname) {
        activeCurrentMenu = item;
      } else if (item.children) {
        getActiveMenu(item.children);
      }
    });
  };
  getActiveMenu(menuData);
  return activeCurrentMenu;
};

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLogin, setIsLogin] = useState(!!getLockrValue('user'));
  const [finalMenuData, setFinalMenuData] = useState([]);

  useEffect(() => {
    if (!isLogin) {
      Lockr.rm('user');
      Lockr.rm('remindMe');
      navigate('/login');
    } else {
      const user = getLockrValue('user');
      const menu = user.menu || [];

      const getMenu = (data) => {
        const arr = [];
        data.forEach((item) => {
          if (menu.indexOf(item.value) > -1 && !item.children) {
            arr.push(item);
          } else if (item.children) {
            const childArr = getMenu(item.children);
            if (childArr.length) {
              arr.push({
                ...item,
                children: childArr,
              });
            }
          }
        });

        return arr;
      };
      const newMenu = menu.length ? getMenu(menuData) : menuData;
      setFinalMenuData(newMenu);

      const currentMenu = getCurrentMenu(newMenu, location.pathname);

      if (location.pathname === '/login' || location.pathname === '/') {
        const url = getUrl(newMenu[0]);
        navigate(url);
      } else if (!currentMenu) {
        // 无权限或者不存在的路由
        navigate('/404');
      }
    }
  }, [isLogin]);

  return (
    <div className="App">
      {/* <Link to="/">首页</Link>
      <Link to="/about">关于</Link> */}
      <Routes>
        {!!finalMenuData.length && (
          <Route
            path="/"
            element={
              <MainLayout
                menuData={finalMenuData}
                onLogout={() => setIsLogin(false)}
              />
            }
          >
            <Route path="data" element={<Home />} />
            <Route path="filming-management" element={<FilmingManagement />} />
            <Route path="order-management" element={<OrderManagement />} />
            <Route path="user-management" element={<UserManagement />} />
            {/* <Route path="thematic-config" element={<ThematicConfig />} /> */}
            <Route path="cut-config" element={<CutConfig />} />
            <Route path="basic-config" element={<BasicConfig />} />
            <Route path="domain-config" element={<DomainConfig />} />
            <Route
              path="announcement-config"
              element={<AnnouncementConfig />}
            />
            {/* <Route path="label-config" element={<LabelConfig />} /> */}
            <Route path="product-config" element={<ProductConfig />} />
            <Route path="award-config" element={<AwardConfig />} />
            <Route path="update-management" element={<UpdateManagement />} />
            <Route path="channel-list" element={<ChannelList />} />
            <Route path="channel-cut" element={<ChannelCut />} />
            <Route
              path="long-video-management"
              element={<LongVideoManagement />}
            />
            <Route
              path="short-video-management"
              element={<ShortVideoManagement />}
            />
            <Route path="sticker-management" element={<StickerManagement />} />
            <Route path="long-video-approval" element={<LongVideoApproval />} />
            <Route
              path="short-video-approval"
              element={<ShortVideoApproval />}
            />
            <Route path="sticker-approval" element={<StickerApproval />} />
            <Route path="trivia-approval" element={<TriviaApproval />} />
            <Route path="cash-out-approval" element={<CashOutApproval />} />
            <Route path="comment-approval" element={<CommentApproval />} />
            <Route path="ad-word-filter" element={<AdWordFilter />} />
            <Route path="customer-service" element={<CustomerServiceLayout />}>
              <Route path="work-order" element={<WorkOrder />} />
              <Route path="quick-message" element={<QuickMessage />} />
              <Route path="domain-test" element={<DomainTest />} />
            </Route>
            <Route path="aisle-management" element={<AisleManagement />} />
            <Route
              path="film-company-management"
              element={<FilmCompanyManagement />}
            />
            <Route
              path="advertising-management"
              element={<AdvertisingManagement />}
            />
            <Route path="modal-management" element={<ModalManagement />} />
          </Route>
        )}

        <Route
          path="login"
          element={<Login onLogin={() => setIsLogin(true)} />}
        />
        <Route path="404" element={<NotFound />} />

        {/* <Route path=":about" element={<About />}>
          <Route path="home" element={<Home />} />
        </Route> */}
      </Routes>
    </div>
  );
}

export default App;
