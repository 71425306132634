import 'braft-editor/dist/index.css';
import 'braft-extensions/dist/color-picker.css';
import BraftEditor from 'braft-editor';
import ColorPicker from 'braft-extensions/dist/color-picker';
import { useRef } from 'react';

BraftEditor.use(
  ColorPicker({
    includeEditors: ['editor-with-color-picker'],
    theme: 'light', // 支持dark和light两种主题，默认为dark
  })
);

function Editor({ value = [], onChange }) {
  const editorRef = useRef(null);
  const handleBlur = () => {
    onChange(editorRef.current.getValue().toHTML());
  };
  return (
    <BraftEditor
      className="rich-editor"
      id="editor-with-color-picker"
      value={BraftEditor.createEditorState(value)}
      ref={editorRef}
      onBlur={handleBlur}
    />
  );
}

export default Editor;
