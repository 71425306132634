import {
  Avatar,
  Button,
  Space,
  Input,
  Table,
  message as antdMessage,
} from 'antd';
import { useState, useRef, useEffect } from 'react';
import { CaretDownOutlined, UserOutlined } from '@ant-design/icons';
import { useRequest, useInterval, useDeepCompareEffect } from 'ahooks';
import { columns } from './config';
import {
  getMessageList,
  sendMessage,
  read,
  addVip,
} from '@/services/customer-service/workOrder';

import './index.less';
import './lite-chat.css';

const getMessageData = async (userId) => {
  const result = await getMessageList({
    pageIndex: 1, // 分页起始页
    pageSize: 100000, // 分页大小
    params: {
      userId,
    },
  });
  const list = result.data?.data || [];
  return list.reverse();
};

function ChatWindow({
  contact,
  onShowOrder,
  onShowDetail,
  quickList,
  refreshOrder,
}) {
  const {
    data: messageList,
    run: runMessageList,
    refresh: refreshMessage,
    mutate: mutateMessageList,
  } = useRequest(getMessageData, {
    defaultParams: [contact.userId],
    manual: true,
  });

  const chatEndRef = useRef(null);
  const [message, setMessage] = useState('');
  const [showQuick, setShowQuick] = useState(true);

  const handleSelectQuick = (record) => {
    setMessage(`${message}${record.content}`);
    const textareaDom = document.getElementById('chat-input');
    textareaDom.focus();
    setTimeout(() => {
      textareaDom.scrollTop = textareaDom.scrollHeight;
    }, 0);
  };

  const handleSend = async () => {
    const params = {
      reply: {
        userId: contact.userId,
        // customerServiceId: '1501909208424124420', // 临时数据
        // customerServiceName: '客服名称', // 临时数据
        chatContent: message,
      },
      workOrder: {
        workOrderId: contact.workOrderId,
      },
    };
    mutateMessageList([
      ...messageList,
      {
        // customerServiceName: '客服名称', // 临时数据
        // customerServiceId: '1501909208424124420', // 临时数据
        chatContent: message,
        sendBy: 2,
        chatContentType: 1,
      },
    ]);
    setMessage('');
    const result = await sendMessage(params);
    if (result.code === 200) {
      refreshMessage();
    }
  };

  useDeepCompareEffect(() => {
    chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    const unReadRecord = [];
    messageList?.forEach((item) => {
      if (item.customerServiceStatus === 0) {
        // 已读1，未读0
        unReadRecord.push(item.id);
      }
    });
    if (unReadRecord.length) {
      const readMsg = async () => {
        const result = await read({
          chatRecordIds: unReadRecord,
        });
        if (result.code === 200) {
          refreshOrder();
        }
      };

      readMsg();
    }
  }, [messageList]);

  useEffect(() => {
    mutateMessageList([]);
    setMessage('');
    runMessageList(contact.userId);
  }, [contact.userId]);

  useInterval(() => {
    refreshMessage();
  }, 30 * 1000);

  const keyDown = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const handleAddVip = async (days) => {
    const result = await addVip({
      userId: contact.userId,
      days,
    });
    if (result.code === 200) {
      antdMessage.success('添加成功');
    }
  };

  return (
    <div className="chat-window">
      <div className="window-top">
        <div className="name">{contact?.nickname}</div>
        <div className="vip-wrap">
          <div>赠送临时会员</div>
          <Space>
            <Button type="primary" size="small" onClick={() => handleAddVip(1)}>
              加1天
            </Button>
            <Button type="primary" size="small" onClick={() => handleAddVip(2)}>
              加2天
            </Button>
            <Button type="primary" size="small" onClick={() => handleAddVip(3)}>
              加3天
            </Button>
          </Space>
        </div>
      </div>
      <div className="message-wrap lite-chatbox">
        {messageList?.map((item) =>
          item.sendBy === 2 ? ( // 1用户  2客服
            <div className="cright cmsg" key={item.id || item.chatContent}>
              <span className="headIcon">
                <Avatar shape="square" size={34} icon={<UserOutlined />} />
              </span>
              <span className="name">{item.customerServiceName}</span>
              <span className="content">
                {item.chatContentType === 1 && item.chatContent}
                {item.chatContentType === 2 && (
                  <img src={item.chatContent} alt="" />
                )}
              </span>
            </div>
          ) : (
            <div className="cleft cmsg" key={item.id}>
              <span className="headIcon">
                <Avatar
                  shape="square"
                  size={34}
                  icon={<UserOutlined />}
                  src={contact.photo}
                />
              </span>
              <span className="name">{contact.nickname}</span>
              <span className="content">
                {item.chatContentType === 1 && item.chatContent}
                {item.chatContentType === 2 && (
                  <img src={item.chatContent} alt="" />
                )}
              </span>
            </div>
          )
        )}
        <div id="messagesEndRef" ref={chatEndRef} style={{ height: '1px' }} />
      </div>
      <div className="message-input">
        <div className="input-wrap">
          <Input.TextArea
            autoSize={{ minRows: 3, maxRows: 3 }}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            id="chat-input"
            // onPressEnter={(e) => {
            //   e.preventDefault();
            //   handleSend();
            // }}
            onKeyDown={keyDown}
          />
          <div className="control-wrap">
            <div
              className="quick-reply"
              onClick={() => setShowQuick(!showQuick)}
            >
              快捷回复
              <CaretDownOutlined rotate={showQuick ? 180 : 0} />
            </div>
            <Space>
              <Button type="primary" size="small" onClick={onShowOrder}>
                查看订单
              </Button>
              <Button type="primary" size="small" onClick={onShowDetail}>
                查看用户
              </Button>
              <Button type="primary" size="small" onClick={handleSend}>
                发送
              </Button>
            </Space>
          </div>
          <div
            className={`quick-wrap ${
              showQuick ? 'show-quick' : 'hidden-quick'
            }`}
          >
            <Table
              columns={columns}
              dataSource={quickList || []}
              size="small"
              pagination={false}
              scroll={{ y: 120 }}
              onRow={(record) => ({
                onClick: (event) => {
                  handleSelectQuick(record);
                }, // 点击行
              })}
              rowKey="fastReplyId"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatWindow;
