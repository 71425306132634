import { Layout, Menu, Dropdown, Badge } from 'antd';
import { Outlet, useLocation, useNavigate, Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useRequest, useInterval } from 'ahooks';
import { getUrl, getLockrValue } from '@/utils/commonUtils';

import './index.less';

import logo from '../../images/web-logo.png';
import userIcon from '../../images/user-icon.png';
import { logout } from '@/services/login';
import { getApprovalCount } from '@/services/commonService';
import { getList } from '@/services/customer-service/workOrder';

const { Header, Content, Sider } = Layout;

const getFirstActiveKey = (menuData, pathname) => {
  let activeMenu;
  let activeCurrentMenu; // 具体某个菜单
  const getActiveMenu = (data, parentMenu) => {
    data.forEach((item) => {
      if (activeMenu) {
        return;
      }
      if (item.url === pathname) {
        activeMenu = parentMenu || item;
        activeCurrentMenu = item;
      } else if (item.children) {
        getActiveMenu(item.children, parentMenu || item);
      }
    });
  };
  getActiveMenu(menuData);
  return { activeMenu, activeCurrentMenu };
};

const getApprovalNum = async () => {
  const result = await getApprovalCount();
  return result.data || {};
};
const getUnDealCount = async () => {
  const result = await getList({
    pageIndex: 1, // 分页起始页
    pageSize: 10, // 分页大小
    params: {
      status: 0, // 状态 0未处理 1已处理
    },
  });
  return result.data?.total;
};

function MainLayout({ menuData, onLogout }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [firstActiveKey, setFirstActiveKey] = useState([]);
  const [secondMenuData, setSecondMenuData] = useState([]);
  const [secondMenuOpenKey, setSecondMenuOpenKey] = useState([]);
  const { data: approvalCount, refresh: refreshApproval } =
    useRequest(getApprovalNum);
  const { data: unDealCount, refresh: refreshCustomService } =
    useRequest(getUnDealCount);

  useEffect(() => {
    const { activeMenu, activeCurrentMenu } = getFirstActiveKey(
      menuData,
      location.pathname
      // location.pathname === '/' ? menuData[0].url : location.pathname
    );
    if (activeMenu) {
      setFirstActiveKey([activeMenu.url]);
      setSecondMenuData(activeMenu.children || []);
      setSecondMenuOpenKey([activeCurrentMenu.parentKey || '']);
    } else {
      // 没有权限或者不存在的路由
      navigate('/404');
    }
  }, []);

  const handleChangeFirstMenu = ({ key }) => {
    const currentMenu = menuData.find((menu) => menu.url === key);
    setSecondMenuData(currentMenu.children || []);
    const url = getUrl(currentMenu);
    navigate(url);
  };

  const handleClickMenu = ({ key }) => {
    console.log(5678);
    if (key === location.pathname) {
      return;
    }
    navigate(key);
  };

  const handleLogout = async () => {
    const result = await logout();
    if (result.code === 200) {
      // Lockr.rm('user');
      // Lockr.rm('remindMe');
      // navigate('/login');
      onLogout();
    }
  };

  useInterval(() => {
    refreshApproval();
    refreshCustomService();
  }, 60 * 1000);

  return (
    <Layout className="main-layout">
      <Header className="header">
        <div className="left-wrap">
          <div className="logo">
            {/* <img src={logo} alt="logo" /> */}
            后台配置系统
          </div>
          {!!firstActiveKey.length && (
            <Menu
              theme="dark"
              mode="horizontal"
              onClick={handleChangeFirstMenu}
              defaultSelectedKeys={firstActiveKey}
              style={{ width: '1000px' }}
            >
              {menuData.map((menu) => (
                <Menu.Item key={menu.url} icon={menu.icon}>
                  {menu.name}
                  {menu.badge &&
                    menu.url === '/approval-management' &&
                    approvalCount?.newExamine && (
                      <Badge count="新" size="small" />
                    )}
                  {menu.badge &&
                    menu.url === '/customer-service' &&
                    !!unDealCount && <Badge count="新" size="small" />}
                </Menu.Item>
              ))}
            </Menu>
          )}
        </div>
        <Dropdown
          overlay={
            <Menu onClick={handleLogout}>
              <Menu.Item>退出登录</Menu.Item>
            </Menu>
          }
        >
          <div className="user-wrap">
            <img src={userIcon} alt="user" className="user-icon" />
            <span className="username">
              {getLockrValue('user')?.nickname ||
                getLockrValue('user')?.username}
            </span>
          </div>
        </Dropdown>
      </Header>
      <Layout>
        {!!secondMenuData.length && (
          <Sider
            width={150}
            style={{ overflowY: 'auto', overflowX: 'hidden' }}
            className="site-layout-background"
          >
            <Menu
              mode="inline"
              // defaultSelectedKeys={[location.pathname]}
              selectedKeys={[location.pathname]}
              defaultOpenKeys={secondMenuOpenKey}
              style={{ minHeight: '100%', borderRight: 0 }}
              // onClick={handleClickMenu}
            >
              {secondMenuData.map((menu) =>
                menu.children ? (
                  <Menu.SubMenu
                    key={menu.url}
                    icon={menu.icon}
                    title={menu.name}
                  >
                    {menu.children.map((item) => (
                      <Menu.Item key={item.url}>
                        <Link to={item.url}>{item.name}</Link>
                      </Menu.Item>
                    ))}
                  </Menu.SubMenu>
                ) : (
                  <Menu.Item key={menu.url} icon={menu.icon}>
                    <Link to={menu.url}>
                      {menu.name}
                      {menu.parentKey === '/approval-management' && (
                        <Badge
                          count={(approvalCount || {})[menu.badge]}
                          size="small"
                          offset={[-5, -16]}
                        />
                      )}
                    </Link>
                  </Menu.Item>
                )
              )}
            </Menu>
          </Sider>
        )}
        <Layout style={{ overflow: 'auto' }}>
          <Content className="site-layout-background">
            {/* <div className="content-wrap"> */}
            <Outlet
              context={{
                refreshApproval,
                unDealCount,
                refreshCustomService,
              }}
            />
            {/* </div> */}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default MainLayout;
