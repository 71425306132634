/* eslint-disable react/jsx-props-no-spreading */
import { Modal, Form, InputNumber, Switch, Button, Input } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useEffect, useRef } from 'react';

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    offset: 6,
    span: 18,
  },
};

function EditModal({ visible, onClose, onOk, data = {}, title }) {
  const [form] = Form.useForm();
  const init = useRef(null);
  useEffect(() => {
    if (visible) {
      form.setFieldsValue(data);
    } else if (init.current) {
      form.resetFields();
    }
    init.current = true;
  }, [visible, data]);

  const handleOk = async () => {
    const values = await form.validateFields();
    console.log(values);
    onOk({ ...data, ...values });
    // onClose();
  };

  return (
    <Modal
      title={data?.platformName}
      visible={visible}
      onOk={handleOk}
      onCancel={onClose}
    >
      <Form form={form} {...formItemLayout}>
        <Form.Item
          name="channelName"
          label="通道名称"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="channelCode"
          label="通道编码"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="weight"
          label="权重"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber style={{ width: '80%' }} />
        </Form.Item>
        <Form.Item
          name="status"
          label="通道状态"
          valuePropName="checked"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Switch />
        </Form.Item>
        <Form.List
          name="amountList"
          rules={[
            {
              required: true,
            },
          ]}
          initialValue={['']}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  {...(index === 0
                    ? formItemLayout
                    : formItemLayoutWithOutLabel)}
                  label={index === 0 ? '支付额度' : ''}
                  required
                  key={field.key}
                >
                  <Form.Item
                    {...field}
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                      {
                        required: true,
                        message: '请输入支付额度',
                      },
                    ]}
                    noStyle
                  >
                    <InputNumber
                      placeholder="请输入支付额度"
                      style={{ width: '80%' }}
                    />
                  </Form.Item>
                  {fields.length > 1 ? (
                    <MinusCircleOutlined
                      style={{ marginLeft: '20px' }}
                      onClick={() => remove(field.name)}
                    />
                  ) : null}
                </Form.Item>
              ))}
              <Form.Item {...formItemLayoutWithOutLabel}>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: '60%' }}
                  icon={<PlusOutlined />}
                >
                  添加支付额度
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
}

export default EditModal;
