/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import {
  Modal,
  Form,
  Row,
  Col,
  InputNumber,
  DatePicker,
  Input,
  Upload,
  Select,
  Switch,
  Button,
  message,
  Space,
} from 'antd';
import { UploadOutlined, EditOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import ClipboardJS from 'clipboard';
import moment from 'moment';
import { getValue, getLockrValue } from '@/utils/commonUtils';
import { Editor } from './components';
import LabelSelect from '../label-select';

import './index.less';

const { Option } = Select;

function DetailModal({
  modalType = 'detail',
  columns,
  data = {},
  visible,
  onClose,
  title,
  inline,
  width,
  labelCol,
  wrapperCol,
  centered,
  labelWrap,
  onValuesChange = () => {},
  onOk,
}) {
  const [form] = Form.useForm();
  const init = useRef(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState('');
  const [previewImage, setPreviewImage] = useState('');
  const editorRefs = useRef({});

  const handlePreview = (file) => {
    setPreviewVisible(true);
    setPreviewImage(file.url || file.thumbUrl);
  };

  const getFormItem = ({
    type,
    labelList = [],
    selectList = [],
    dataIndex,
    placeholder,
    // eslint-disable-next-line no-shadow
    title,
    autoSize,
    detailEditable,
    notShowTime,
    uploadUrl,
    accept,
    min,
    labelSelectMode,
  }) => {
    const uploadChange = ({ file, fileList }) => {
      const { response } = file;
      if (!response) {
        return;
      }
      // 模拟数据
      // response.code = 200;
      // response.data = {
      //   path: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
      // };

      if (response.code === 200) {
        message.success('上传成功');
      } else {
        message.error(response.msg || '上传失败');
        if (modalType === 'add') {
          form.setFields([{ name: dataIndex, value: undefined }]);
        } else {
          let formateValue;
          if (data[dataIndex]) {
            const imageData = Array.isArray(data[dataIndex])
              ? data[dataIndex]
              : [data[dataIndex]];
            formateValue = imageData.map((url) => ({ url }));
          }
          form.setFields([{ name: dataIndex, value: formateValue }]);
        }
      }
    };

    const FORM_MAP = {
      inputNumber: (
        <InputNumber
          style={{ width: '100%' }}
          placeholder={placeholder || `请输入${title}`}
          min={min || 0}
        />
      ),
      date: (
        <DatePicker
          showTime={notShowTime ? false : { format: 'HH:mm' }}
          format={notShowTime ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm'}
          allowClear={false}
        />
      ),
      input: <Input placeholder={placeholder || `请输入${title}`} />,
      image: (
        <Upload
          // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          action={uploadUrl}
          data={
            data[dataIndex]
              ? { request: JSON.stringify({ path: data[dataIndex] }) }
              : undefined
          }
          listType="picture-card"
          showUploadList={{
            showPreviewIcon: true,
            showRemoveIcon: false,
          }}
          onPreview={handlePreview}
          accept="image/*"
          headers={{
            'remind-me': getLockrValue('remindMe') || '',
          }}
          maxCount={1}
          onChange={uploadChange}
        >
          <EditOutlined />
        </Upload>
      ),
      textarea: (
        <Input.TextArea
          placeholder={placeholder || `请输入${title}`}
          autoSize={autoSize}
        />
      ),
      labelSelect: <LabelSelect labelList={labelList} mode={labelSelectMode} />,
      select: (
        <Select placeholder={placeholder || `请选择${title}`}>
          {selectList.map((item) => (
            <Option value={item.value} key={item.value}>
              {item.label}
            </Option>
          ))}
        </Select>
      ),
      switch: <Switch />,
      editor: <Editor />,
      password: (
        <Input.Password
          placeholder={placeholder || `请输入${title}`}
          visibilityToggle={modalType === 'add'}
        />
      ),
      upload: (
        <Upload
          action={uploadUrl}
          data={
            data[dataIndex]
              ? { request: JSON.stringify({ source: data[dataIndex] }) }
              : undefined
          }
          onChange={uploadChange}
          accept={accept}
          maxCount={1}
          showUploadList={{
            showRemoveIcon: false,
          }}
          headers={{
            'remind-me': getLockrValue('remindMe') || '',
          }}
        >
          <Button icon={<UploadOutlined />}>点击上传</Button>
        </Upload>
      ),
    };
    const realType = data[`${dataIndex}Rich`] ? 'editor' : type;
    return FORM_MAP[realType];
  };

  const handleOk = async () => {
    const values = await form.validateFields();
    console.log(values);
    // onClose();
    Object.keys(values).forEach((key) => {
      if (moment.isMoment(values[key])) {
        const item = columns.find((item) => item.dataIndex === key);
        values[key] = item.notShowTime
          ? values[key].format('YYYY-MM-DD')
          : values[key].format('YYYY-MM-DD HH:mm');
      }
    });
    columns.forEach((item) => {
      if (
        (item.editable || item.formEditable) &&
        (modalType === 'add' ||
          (modalType === 'detail' && !item.detailNotEditable)) &&
        data[item.dataIndex] !== 'no-need'
      ) {
        if (item.type === 'image' || item.type === 'upload') {
          values[item.dataIndex] =
            (values[item.dataIndex] || [])[0]?.response?.data?.path ||
            data[item.dataIndex];
        }
      }
    });
    onOk({ ...data, ...values });
  };

  useEffect(() => {
    if (visible) {
      console.log(data);
      const formData = { ...data };
      columns.forEach((item) => {
        if (
          (item.editable || item.formEditable) &&
          (modalType === 'add' ||
            (modalType === 'detail' && !item.detailNotEditable)) &&
          data[item.dataIndex] !== 'no-need'
        ) {
          if (item.type === 'date') {
            formData[item.dataIndex] = formData[item.dataIndex]
              ? moment(formData[item.dataIndex])
              : null;
          }

          if (item.type === 'image') {
            let formateValue;
            if (formData[item.dataIndex]) {
              const imageData = Array.isArray(formData[item.dataIndex])
                ? formData[item.dataIndex]
                : [formData[item.dataIndex]];
              formateValue = imageData.map((url) => ({ url }));
            }
            formData[item.dataIndex] = formateValue;
          }
        }
      });
      form.setFieldsValue(formData);
      init.current = true;
    } else if (init.current) {
      form.resetFields();
      const ele = document.querySelector('.ant-modal-body');
      if (ele) {
        ele.scrollTo(0, 0);
      }
    }
  }, [visible, data]);

  useEffect(() => {
    const clipboardArr = [];
    columns.forEach((item) => {
      if (item.suffixType === 'copy') {
        const clipboard = new ClipboardJS(`#${item.dataIndex}-suffix`, {
          text(trigger) {
            return form.getFieldValue(item.dataIndex);
          },
        });

        clipboard.on('success', (e) => {
          message.success('复制成功！');
        });

        clipboard.on('error', (e) => {
          message.error('复制失败！');
        });
        clipboardArr.push(clipboard);
      }
    });
    return () => {
      clipboardArr.forEach((clipboard) => {
        clipboard.destroy();
      });
    };
  }, [columns]);

  const getValueFromEvent = (e) => {
    // console.log('Upload event:', e);

    if (Array.isArray(e)) {
      return e;
    }

    if (e.fileList) {
      // return e && e.fileList;
      // e.file.status = 'success';
      return e && e.fileList;
    }

    return e && e.target.value;
  };

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={handleOk}
      onCancel={onClose}
      width={width || '800px'}
      centered={centered}
      bodyStyle={{
        maxHeight: '600px',
        overflow: 'auto',
      }}
    >
      <Form
        labelCol={{ span: labelCol || (inline ? 10 : 4) }}
        wrapperCol={{ span: wrapperCol || (inline ? 14 : 16) }}
        form={form}
        onValuesChange={onValuesChange}
        labelWrap={labelWrap}
      >
        <Row>
          {columns.map((item, index) => (
            <Col
              span={item.colSpan || (inline ? 10 : 24)}
              offset={
                item.colOffset !== undefined
                  ? item.colOffset
                  : inline && index % 2 !== 0
                  ? 2
                  : 0
              }
              key={item.title}
              style={{ position: 'relative' }}
            >
              {(item.editable || item.formEditable) &&
              (modalType === 'add' ||
                (modalType === 'detail' && !item.detailNotEditable)) &&
              data[item.dataIndex] !== 'no-need' ? (
                <>
                  <Form.Item
                    labelCol={
                      item.labelCol !== undefined
                        ? { span: item.labelCol }
                        : undefined
                    }
                    wrapperCol={
                      item.wrapperCol !== undefined
                        ? { span: item.wrapperCol }
                        : undefined
                    }
                    label={item.title}
                    name={item.dataIndex}
                    rules={item.rules || []}
                    valuePropName={item.valuePropName || 'value'}
                    getValueFromEvent={
                      item.type === 'image' || item.type === 'upload'
                        ? getValueFromEvent
                        : undefined
                    }
                  >
                    {getFormItem(item)}
                  </Form.Item>
                  {item.suffix && item.suffixType === 'copy' && (
                    <Button
                      type="link"
                      className="form-item-suffix"
                      id={`${item.dataIndex}-suffix`}
                    >
                      {item.suffix}
                    </Button>
                  )}
                  {item.suffix && !item.suffixType && (
                    <span className="form-item-suffix suffix-label">
                      {item.suffix}
                    </span>
                  )}
                </>
              ) : (
                data[item.dataIndex] !== 'no-need' && (
                  <Form.Item
                    label={item.title}
                    labelCol={
                      item.labelCol !== undefined
                        ? { span: item.labelCol }
                        : undefined
                    }
                    wrapperCol={
                      item.wrapperCol !== undefined
                        ? { span: item.wrapperCol }
                        : undefined
                    }
                    noStyle={item.conditionForm}
                  >
                    <span style={{ position: 'relative' }}>
                      {item.format
                        ? item.format(data[item.dataIndex], data, { modalType })
                        : getValue(data, item.dataIndex)}
                      {item.suffix && item.suffixType === 'copy' && (
                        <Button
                          type="link"
                          className="form-item-suffix"
                          id={`${item.dataIndex}-suffix`}
                          style={{ transform: 'translate(100%,-22%)' }}
                        >
                          {item.suffix}
                        </Button>
                      )}
                      {item.suffix && !item.suffixType && (
                        <span className="form-item-suffix suffix-label">
                          {item.suffix}
                        </span>
                      )}
                    </span>
                  </Form.Item>
                )
              )}
            </Col>
          ))}
        </Row>
      </Form>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        <div style={{ textAlign: 'center' }}>
          <img alt="example" style={{ width: '90%' }} src={previewImage} />
        </div>
      </Modal>
    </Modal>
  );
}

export default DetailModal;
