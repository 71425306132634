import { useState } from 'react';
import { Avatar } from 'antd';
import { UserOutlined, CaretRightOutlined } from '@ant-design/icons';
import './index.less';

const LEVEL_MAP = {
  '-1': '游客',
  0: '临时VIP',
};

function UserDetail({ contact, onFold, fold }) {
  return (
    <div className="chat-user-detail">
      <CaretRightOutlined
        className={`arrow ${fold ? 'fold' : ''}`}
        onClick={onFold}
        rotate={fold ? 180 : 0}
      />
      {!fold && (
        <>
          <div className="user-avater">
            <Avatar size={64} src={contact?.photo} icon={<UserOutlined />} />
          </div>
          <div className="nickname">{contact?.nickname}</div>
          <div className="invite-code">邀请码：{contact?.inviteCode}</div>
          <div className="vip-level">
            VIP等级：
            {LEVEL_MAP[contact?.userMemberLevel] ||
              `v${contact.userMemberLevel}`}
          </div>
          <div className="vip-level">电话：{contact?.phone}</div>
          <div className="max-times">
            每日最大观影次数：{contact?.dayWatchNumber}
          </div>
          <div className="rest-times">
            当日剩余观影次数：{contact?.dayWatchNumberLefted}
          </div>
        </>
      )}
    </div>
  );
}

export default UserDetail;
