export const COLUMNS = [
  {
    title: '奖励项',
    dataIndex: 'content',
    align: 'center',
  },
  {
    title: '奖励条件',
    dataIndex: 'condition',
    align: 'center',
  },
  {
    title: '奖励次数',
    dataIndex: 'num',
    align: 'center',
    editable: true,
    type: 'inputNumber',
    rules: [{ required: true, message: '请输入奖励次数' }],
    render: (text) => (text || text === 0 ? `${text}次` : ''),
  },
  {
    title: '奖励时间',
    dataIndex: 'day',
    align: 'center',
    editable: true,
    type: 'inputNumber',
    rules: [{ required: true, message: '请输入奖励时间' }],
    render: (text) => (text || text === 0 ? `${text}天` : ''),
  },
  {
    title: '奖励参数',
    dataIndex: 'param',
    align: 'center',
    editable: true,
    type: 'input',
    rules: [{ required: true, message: '请输入奖励参数' }],
    width: 150,
  },
  {
    title: '是否启用',
    dataIndex: 'start',
    align: 'center',
    editable: true,
    type: 'switch',
    valuePropName: 'checked',
    width: 70,
    rules: [{ required: true, message: '请选择是否启用' }],
  },
];

export const DATA_SOURCE = [
  {
    key: 0,
    name: '奖励一次性次数',
    condition: '每日登录',
    times: 0,
    day: 'no-need',
    count: 'no-need',
    open: true,
  },
  {
    key: 1,
    name: '奖励VIP',
    condition: '每日登录',
    times: 'no-need',
    day: 0,
    count: 'no-need',
    open: true,
  },
  {
    key: 2,
    name: '奖励一次性次数',
    condition: '每邀请1人',
    times: 0,
    day: 'no-need',
    count: 'no-need',
    open: true,
  },
];
