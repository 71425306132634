import { post } from '@/utils/http';

export const getBossChart = (params) =>
  post({
    url: '/manage/data/graph',
    data: params,
  });
export const getBossKanban = (params) =>
  post({
    url: '/manage/data/tab',
    data: params,
  });
export const getConverseData = (params) =>
  post({
    url: '/manage/data/conversion',
    data: params,
  });
export const getBusinessData = (params) =>
  post({
    url: '/manage/data/business',
    data: params,
  });
export const getOverviewData = (params) =>
  post({
    url: '/manage/data/overview',
    data: params,
  });
export const getInstallData = (params) =>
  post({
    url: '/manage/data/distribution',
    data: params,
  });
