import { Vertify } from '@alex_xu/react-slider-vertify';
import { useState } from 'react';

import slider1 from './images/slider1.png';
import slider2 from './images/slider2.png';
import slider3 from './images/slider3.png';
import slider4 from './images/slider4.png';
import slider5 from './images/slider5.png';
import slider6 from './images/slider6.png';
import slider7 from './images/slider7.png';
import successImg from './images/success.png';

import './index.less';

const sliderArr = [
  slider1,
  slider2,
  slider3,
  slider4,
  slider5,
  slider6,
  slider7,
];

function SliderVertify({ onSuccess, on }) {
  const [success, setSuccess] = useState(false);
  const [imgUrl, setImgUrl] = useState(
    sliderArr[Math.round(Math.random() * 6)]
  );
  const handleRefresh = () => {
    const index = Math.round(Math.random() * 6);
    setImgUrl(sliderArr[index]);
  };
  const handleSuccess = () => {
    setSuccess(true);
    if (typeof onSuccess === 'function') {
      onSuccess();
    }
  };
  return (
    <div className="verify-wrap">
      <Vertify
        width={328}
        height={160}
        visible
        onSuccess={handleSuccess}
        // onFail={() => alert('fail')}
        imgUrl={imgUrl}
        // onRefresh={() => alert('refresh')}
        onRefresh={handleRefresh}
      />
      {success && (
        <div className="success-wrap">
          <img src={successImg} alt="" />
          <div className="success-text">验证通过</div>
        </div>
      )}
    </div>
  );
}

export default SliderVertify;
