/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
import { PageHeader, Card, Button, Table, message, Popconfirm } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useAntdTable, useMemoizedFn } from 'ahooks';
import { COLUMNS } from './config';
import { DetailModal, genDragTable } from '@/components';
import { useDetailModal, useTableColumns, useModalFormColumns } from '@/hooks';
import {
  getList,
  add,
  edit,
  del,
  move,
} from '@/services/customer-service/quickMessage';
import { getPageUpdate } from '@/utils/commonUtils';

import './index.less';

const getTableData = async ({ current, pageSize }, formData) => {
  const result = await getList({
    pageIndex: current,
    pageSize,
  });

  const data = result.data || {};
  return {
    total: data.total || 0,
    list: data.data || [],
  };
};

function QuickMessage() {
  const { tableProps, run, params, mutate, refresh } = useAntdTable(
    getTableData,
    {
      defaultParams: [{ current: 1, pageSize: 10 }],
    }
  );
  const { showDetail, selectRecord, handleShowDetail, handleCloseDetail } =
    useDetailModal();
  const { formColumns, formType, getModalFormColumns } =
    useModalFormColumns(COLUMNS);

  const handleDel = useMemoizedFn(async (record) => {
    const result = await del({
      fastReplyId: record.fastReplyId,
    });
    if (result.code === 200) {
      message.success('删除成功');
      const page = getPageUpdate(tableProps, 1);
      run({
        ...params[0],
        current: page,
      });
    }
  });

  const handleMove = async (fastReplyId, operate) => {
    const result = await move({
      fastReplyId,
      operate,
    });
    if (result.code === 200) {
      message.success('操作成功');
      refresh();
    }
  };

  const { tableColumns, setTableColumns } = useTableColumns(COLUMNS, {
    controlColumns: {
      title: '操作',
      key: 'action',
      width: 200,
      align: 'center',
      fixed: 'right',
      render: (text, record) => (
        <>
          <Button
            type="link"
            size="small"
            onClick={() => {
              getModalFormColumns(record, 'detail');
              handleShowDetail(record);
            }}
          >
            编辑
          </Button>
          <Popconfirm
            title="确定要删除该条快捷回复吗"
            onConfirm={() => handleDel(record)}
            okText="确定"
            cancelText="取消"
          >
            <Button size="small" type="text" danger>
              删除
            </Button>
          </Popconfirm>

          <Button
            type="link"
            size="small"
            onClick={() => handleMove(record.fastReplyId, 'up')}
          >
            上移
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => handleMove(record.fastReplyId, 'down')}
          >
            下移
          </Button>
        </>
      ),
    },
  });

  const handleShowAdd = () => {
    getModalFormColumns({}, 'add');
    handleShowDetail({});
  };
  const handleOk = async (value) => {
    let result;
    if (formType === 'add') {
      result = await add(value);
    } else {
      result = await edit(value);
    }

    if (result.code === 200) {
      const page = { ...params[0] };
      if (formType === 'add') {
        page.current = 1;
      }
      run(page);
      handleCloseDetail();
      message.success('操作成功！');
    }
  };

  return (
    <div className="quick-massage">
      <PageHeader ghost={false} backIcon={false} title="快捷消息设置" />
      <div className="content-wrap">
        <Card>
          <Button
            type="primary"
            style={{ marginBottom: '10px' }}
            onClick={handleShowAdd}
          >
            <PlusOutlined />
            新增快捷回复
          </Button>
          <Table
            className="edit-table"
            // components={{
            //   body: {
            //     wrapper: genDragTable.genDragTableBody(
            //       tableProps.dataSource,
            //       onSortEnd,
            //       onSortStart
            //     ),
            //     row: genDragTable.genDragTableRow(
            //       tableProps.dataSource,
            //       'fastReplyId'
            //     ),
            //   },
            // }}
            rowClassName={() => 'editable-row'}
            bordered
            columns={tableColumns}
            rowKey="fastReplyId"
            // scroll={{ x: 'max-content' }}
            {...tableProps}
            pagination={{
              ...tableProps.pagination,
              showTotal: (total) => `共 ${total} 条`,
            }}
          />
        </Card>
      </div>
      <DetailModal
        // width={1000}
        columns={formColumns}
        data={selectRecord}
        destroyOnClose
        visible={showDetail}
        onClose={handleCloseDetail}
        onOk={handleOk}
        modalType={formType}
        title={formType === 'add' ? '新增快捷回复' : '快捷回复详情'}
        // inline
        centered
      />
    </div>
  );
}

export default QuickMessage;
