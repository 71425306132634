const TYPE_MAP = {
  MEMBER: '会员',
  COIN: '金币',
};
export const STATUS_MAP = {
  SUCCEED: '支付成功', // 启用1，禁用0
  AWAIT: '等待支付',
  FAIL: '支付失败',
};
export const columns = [
  {
    title: '订单创建时间',
    dataIndex: 'createTime',
    width: 150,
    align: 'center',
  },
  {
    title: '商户订单号',
    dataIndex: 'busOrderId',
    align: 'center',
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'center',
  },
  {
    title: '订单类型',
    dataIndex: 'type',
    width: 80,
    align: 'center',
    render: (text) => TYPE_MAP[text] || '',
  },
  {
    title: '订单金额',
    dataIndex: 'money',
    width: 80,
    align: 'center',
  },
  {
    title: '状态',
    dataIndex: 'status',
    width: 80,
    align: 'center',
    render: (text) => STATUS_MAP[text] || '',
  },
];
