import { post } from '@/utils/http';

export const getUserData = (params) =>
  post({
    url: '/manage/user/manage/data',
    data: params,
  });
export const addTimes = (params) =>
  post({
    url: '/manage/user/manage/add',
    data: params,
  });
export const delOrDisabledUser = (params) =>
  post({
    url: '/manage/user/manage/perform',
    data: params,
  });

export const moveAccount = (params) =>
  post({
    url: '/manage/user/manage/change',
    data: params,
  });
