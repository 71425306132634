import { post } from '@/utils/http';

export const getList = (params) =>
  post({
    url: '/manage/chal/queryChalAll',
    data: params,
  });
export const addPlateform = (params) =>
  post({
    url: '/manage/chal/insertPlat',
    data: params,
  });
export const getPlateforms = (params) =>
  post({
    url: '/manage/chal/queryPlat',
    data: params,
  });
export const addPayAisle = (params) =>
  post({
    url: '/manage/chal/insertChal',
    data: params,
  });
export const editPayAisle = (params) =>
  post({
    url: '/manage/chal/updateChal',
    data: params,
  });
