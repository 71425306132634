/* eslint-disable react/jsx-props-no-spreading */
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

import './index.less';

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

const genDragTableBody = (dataSource, onSortEnd, onSortStart) => {
  const handleSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        [].concat(dataSource),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      console.log('Sorted items: ', newData);
      // setDataSource(newData);
      onSortEnd(newData);
    }
  };
  const handleSortStart = ({ node, index }) => {
    // console.log(node, index);
    if (onSortStart) {
      onSortStart(dataSource[index]);
    }
  };
  return function (props) {
    return (
      <SortableBody
        useDragHandle
        disableAutoscroll
        helperClass="row-dragging"
        onSortEnd={handleSortEnd}
        onSortStart={handleSortStart}
        {...props}
      />
    );
  };
};

function genDragTableRow(dataSource, key) {
  return function ({ className, style, ...restProps }) {
    const index = dataSource.findIndex(
      (x) => x[key] === restProps['data-row-key']
    );
    return <SortableItem index={index} {...restProps} />;
  };
}

export default {
  genDragTableBody,
  genDragTableRow,
};
