/* eslint-disable react/jsx-filename-extension */
import { Image } from 'antd';

export const SORT1_OPTIONS = [
  { value: '', name: '不限排序' },
  { value: 'createTime-ASC', name: '最旧提审' },
  { value: 'createTime-DESC', name: '最近提审' },
  { value: 'userMemberLevel-DESC', name: '按用户等级高低' },
];

export const COLUMNS = [
  {
    title: '影片id',
    dataIndex: 'movieId',
    align: 'center',
    ellipsis: true,
    width: 110,
  },
  {
    title: '用户名',
    dataIndex: 'userName',
    align: 'center',
    width: 120,
    ellipsis: true,
  },
  {
    title: '用户等级',
    dataIndex: 'userMemberLevel',
    align: 'center',
    width: 70,
  },
  {
    title: '评论内容',
    dataIndex: 'content',
    align: 'center',
    ellipsis: true,
  },
  {
    title: '评论时间',
    dataIndex: 'createTime',
    align: 'center',
    width: 130,
    render: (text) => (text ? text.replace('T', ' ') : ''),
    format: (text) => (text ? text.replace('T', ' ') : ''),
  },
  {
    title: '禁止该用户评论（谨慎操作）',
    dataIndex: 'commentStatus',
    align: 'center',
    editable: true,
    type: 'switch',
    valuePropName: 'checked',
    width: 110,
    // rules: [{ required: true, message: '请选择是否禁止该用户评论' }],
  },
];
