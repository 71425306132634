import { Form, Select } from 'antd';
import { getTags } from '@/services/approval-management/longVideoApproval';

const { Option } = Select;

export const SEARCH_TYPE_LIST = [
  { value: 'factoryName', label: '片商名' },
  // { value: 'username', label: '用户名' },
  { value: 'movieName', label: '影片标题' },
  { value: 'movieId', label: '影片ID' },
];

export const selectBefore = (
  <Form.Item name="searchType" noStyle>
    <Select>
      {SEARCH_TYPE_LIST.map((item) => (
        <Option value={item.value} key={item.value}>
          {item.label}
        </Option>
      ))}
    </Select>
  </Form.Item>
);

export const COOPERATION_MAP = {
  1: '分成',
  2: '买断',
  3: '免费',
};

export const getTagList = (type) => async () => {
  const result = await getTags({
    type, // 1长视频，2短视频
  });
  const list = (result.data?.subject || []).map((item) => ({
    value: item.id,
    label: item.tagsName,
  }));
  return list;
};
