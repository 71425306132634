import {
  PageHeader,
  Card,
  Space,
  Form,
  Row,
  Col,
  Select,
  Input,
  Button,
  Table,
  message,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useAntdTable, useMemoizedFn } from 'ahooks';
import { SORT1_OPTIONS, SORT2_OPTIONS, COLUMNS } from './config';
import { EditableRow, EditableCell, DetailModal } from '@/components';
import { useDetailModal, useTableColumns, useModalFormColumns } from '@/hooks';
import { getPageUpdate } from '@/utils/commonUtils';

import {
  getList,
  add,
  del,
} from '@/services/business-management/channel-management/channelList';

const { Option } = Select;

const components = {
  body: {
    row: EditableRow,
    cell: EditableCell,
  },
};

const initialValues = {
  todaySort: 0,
  sort: 0,
  search: 1,
};

const selectBefore = (
  <Form.Item name="search" noStyle>
    <Select style={{ width: '90px' }}>
      <Option value={1}>渠道ID</Option>
      <Option value={2}>渠道名</Option>
    </Select>
  </Form.Item>
);

const getTableData = async ({ current, pageSize }, formData) => {
  if (!formData.wd) {
    delete formData.search;
  }
  const result = await getList({
    page: current,
    size: pageSize,
    ...formData,
  });

  const data = result.data || {};
  const list = data.list || [];
  list.forEach((item) => {
    item.disable = !item.active;
  });
  return {
    total: data.total || 0,
    list,
  };
};

function ChannelList() {
  const [form] = Form.useForm();
  const { tableProps, search, params, refresh, run } = useAntdTable(
    getTableData,
    {
      form,
      defaultParams: [{ current: 1, pageSize: 10 }, initialValues],
    }
  );

  const { submit } = search;

  const { showDetail, selectRecord, handleShowDetail, handleCloseDetail } =
    useDetailModal();
  const { formColumns, formType, setFormColumns, getModalFormColumns } =
    useModalFormColumns(COLUMNS);

  const handleSave = async (val) => {
    val.active = val.disable ? 0 : 1;
    delete val.disable;
    if (!(formType === 'add' && showDetail)) {
      val = {
        pwd: val.pwd,
        channelId: val.channelId,
        channelName: val.channelName,
        channelUrl: val.channelUrl,
        percentVip: val.percentVip,
        percentCoin: val.percentCoin,
        withdrawLimit: val.withdrawLimit,
        active: val.active,
      };
    }
    const result = await add(val);
    if (result.code === 200) {
      message.success('操作成功');
      if (formType === 'add' && showDetail) {
        run(
          {
            ...params[0],
            current: 1,
          },
          params[1]
        );
        handleCloseDetail();
      } else {
        refresh();
        if (showDetail) {
          handleCloseDetail();
        }
      }
    }
  };

  const handleDel = useMemoizedFn(async (record) => {
    const result = await del({
      channelId: record.channelId,
    });
    if (result.code === 200) {
      message.success('删除成功');
      const page = getPageUpdate(tableProps, 1);
      run(
        {
          ...params[0],
          current: page,
        },
        params[1]
      );
    }
  });

  const { tableColumns, setTableColumns } = useTableColumns(COLUMNS, {
    params,
    onSave: handleSave,
    delTitle: (record) => `确定要删除${record.channelName}吗?`,
    onDelete: handleDel,
    onDetail: (record) => {
      getModalFormColumns(record, 'detail');
      handleShowDetail(record);
    },
  });
  const handleShowAdd = () => {
    getModalFormColumns({}, 'add');
    handleShowDetail({ disable: false });
  };

  return (
    <div className="user-management">
      <PageHeader ghost={false} backIcon={false} title="渠道列表" />
      <div className="content-wrap">
        <Space direction="vertical" style={{ width: '100%' }}>
          <Card>
            <Form initialValues={initialValues} form={form}>
              <Row>
                <Col span={5}>
                  <Space>
                    <Button
                      type="primary"
                      style={{ marginBottom: '10px' }}
                      onClick={handleShowAdd}
                    >
                      <PlusOutlined />
                      新建渠道
                    </Button>
                    <Button
                      type="link"
                      style={{ marginBottom: '10px' }}
                      href="/channel-page"
                    >
                      打开渠道商代理后台
                    </Button>
                  </Space>
                </Col>
                <Col span={4} offset={1}>
                  <Form.Item
                    label="排序1"
                    name="todaySort"
                    style={{ marginBottom: 0 }}
                  >
                    <Select>
                      {SORT1_OPTIONS.map((item) => (
                        <Option value={item.value} key={item.value}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4} offset={1}>
                  <Form.Item
                    label="排序2"
                    name="sort"
                    style={{ marginBottom: 0 }}
                  >
                    <Select>
                      {SORT2_OPTIONS.map((item) => (
                        <Option value={item.value} key={item.value}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6} offset={1}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.searchType !== curValues.searchType
                    }
                  >
                    {({ getFieldValue }) => {
                      const type = getFieldValue('searchType');
                      return (
                        <Form.Item
                          label="关键搜索"
                          name="wd"
                          style={{ marginBottom: 0 }}
                        >
                          <Input
                            placeholder={`输入${
                              type === 'id' ? '渠道id' : '渠道名'
                            }`}
                            addonBefore={selectBefore}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={2} style={{ textAlign: 'right' }}>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Button type="primary" onClick={submit}>
                      确认查看
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
          <Card>
            <div className="table-title">查询结果</div>
            <Table
              className="edit-table"
              components={components}
              rowClassName={() => 'editable-row'}
              bordered
              columns={tableColumns}
              scroll={{ x: 'max-content' }}
              {...tableProps}
              pagination={{
                ...tableProps.pagination,
                showTotal: (total) => `共 ${total} 条`,
                // showSizeChanger: true,
                // showQuickJumper: true,
              }}
              rowKey="channelId"
            />
          </Card>
        </Space>
      </div>
      <DetailModal
        width={1000}
        columns={formColumns}
        data={selectRecord}
        destroyOnClose
        visible={showDetail}
        onClose={handleCloseDetail}
        onOk={handleSave}
        modalType={formType}
        title={
          formType === 'add'
            ? '新建渠道'
            : `片商：${selectRecord.id} / 创建时间：${selectRecord.creatTime}`
        }
        inline
        centered
      />
    </div>
  );
}

export default ChannelList;
