import { Form, Select } from 'antd';

import { getTags } from '@/services/approval-management/longVideoApproval';

const { Option } = Select;

export const SEARCH_TYPE_LIST = [
  { value: 'factoryName', label: '片商名' },
  // { value: 'username', label: '用户名' },
  { value: 'movieName', label: '影片标题' },
];

export const selectBefore = (
  <Form.Item name="searchType" noStyle>
    <Select>
      {SEARCH_TYPE_LIST.map((item) => (
        <Option value={item.value} key={item.value}>
          {item.label}
        </Option>
      ))}
    </Select>
  </Form.Item>
);

export const getTagList = (type) => async () => {
  const result = await getTags({
    type, // 1长视频，2短视频
  });
  const list = (result.data?.subject || []).map((item) => ({
    value: item.id,
    label: item.tagsName,
  }));
  return list;
};
export const COOPERATION_MAP = {
  1: '分成',
  2: '买断',
  3: '免费',
};
export const ROYAKTY_RATE_MAP = {
  0: 15,
  1: 18,
  2: 25,
  3: 30,
};
export const getCooperationText = (text, data, isDetail) => {
  const type = COOPERATION_MAP[text];
  const level = data.factory?.factoryGrade || 0;
  let priceText = '';
  if (text === 1) {
    // 分成
    priceText = `分成比例为${ROYAKTY_RATE_MAP[level]}%`;
  }
  if (text === 2) {
    // 买断
    priceText = `买断价格为${data.buyout}`;
  }

  return (
    <span>
      {`v${level}官方签约用户合作意向为${type}，${
        priceText ? `${priceText}，` : ''
      }${
        isDetail
          ? '在确认通过时，你还可以对合作类型与对外类型进行调整'
          : '你还可以对合作类型与对外类型进行调整'
      }`}
    </span>
  );
};
