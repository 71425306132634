import { Popover, Button, Form, Input, Radio } from 'antd';
import { useState } from 'react';

function MoveAccount({ onMove, record }) {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const handleFinish = (values) => {
    console.log(values);
    onMove(values);
    setVisible(false);
  };
  const handleVisibleChange = (val) => {
    setVisible(val);
    if (val) {
      form.resetFields();
      form.setFieldsValue({
        oldInviteCode: record.inviteCode,
      });
    }
  };

  const popoverForm = (
    <>
      <Form onFinish={handleFinish} form={form} layout="vertical">
        <Form.Item
          name="oldInviteCode"
          label="老账号邀请码"
          style={{ marginBottom: '8px' }}
        >
          <Input style={{ width: '100%' }} disabled />
        </Form.Item>
        <Form.Item
          name="newInviteCode"
          rules={[
            {
              required: true,
              message: `新账号邀请码必填`,
            },
          ]}
          label="将老账号数据迁移至"
        >
          <Input placeholder="输入新账号邀请码" style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item style={{ textAlign: 'center', marginBottom: 0 }}>
          <Button type="primary" htmlType="submit">
            确认迁移
          </Button>
        </Form.Item>
      </Form>
      <div className="move-account-tips">
        请先验证用户真实性，
        迁移后，用户当前账号邀请码将会变回为老账号邀请码，且新账号将载入老账号数据
      </div>
    </>
  );

  return (
    <Popover
      content={popoverForm}
      title="迁移到新账号"
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
    >
      <Button size="small" type="link">
        迁移到新账号
      </Button>
    </Popover>
  );
}

export default MoveAccount;
