import { Card, Radio, DatePicker, Button, Space, Table } from 'antd';
import { useState } from 'react';
import moment from 'moment';
import { useRequest } from 'ahooks';
import { columns } from './column-config';
import { getBossKanban } from '@/services/data';
import './index.less';

const getData = async (date, deviceType) => {
  const result = await getBossKanban({
    date: date.format('YYYY-MM-DD'),
    client: deviceType,
    size: 7,
  });

  const list = result.data || [];
  list.forEach((item, index) => {
    item.index = index;
  });

  return list;
};

function BossKanban() {
  const [selectDate, setSelectDate] = useState(moment());
  const [deviceType, setDeviceType] = useState(0);
  const [dateType, setDateType] = useState('today');
  const [expaned, setExpaned] = useState(false);
  const {
    data = [],
    run,
    loading,
    refresh,
  } = useRequest(getData, {
    defaultParams: [selectDate, deviceType],
  });

  const handleChangeDate = (date, dateString) => {
    setSelectDate(date);
    if (moment().isSame(dateString, 'day')) {
      setDateType('today');
    } else {
      setDateType('all');
    }
  };

  const disabledDate = (current) => current && current > moment().endOf('day');

  const handleChangeDevice = (e) => {
    setDeviceType(e.target.value);
    run(selectDate, e.target.value);
  };

  const handleSelectToday = () => {
    setSelectDate(moment());
    setDateType('today');
    run(moment(), deviceType);
  };

  return (
    <div className="boss-kanban">
      <Card
        title="BOSS看板"
        extra={
          <Space>
            <Radio.Group value={deviceType} onChange={handleChangeDevice}>
              <Radio.Button value={0}>所有端</Radio.Button>
              <Radio.Button value={1}>安卓端</Radio.Button>
              <Radio.Button value={2}>IOS端</Radio.Button>
            </Radio.Group>
            <span
              className={`time-label ${dateType === 'today' ? 'selected' : ''}`}
              onClick={() => handleSelectToday()}
            >
              今天
            </span>
            <DatePicker
              disabledDate={disabledDate}
              value={selectDate}
              onChange={handleChangeDate}
              allowClear={false}
            />
            <Button type="primary" onClick={() => run(selectDate, deviceType)}>
              点击查看
            </Button>
            <Button type="primary" onClick={refresh}>
              更新数据
            </Button>
          </Space>
        }
      >
        <Table
          columns={columns}
          bordered
          loading={loading}
          expandable={{
            expandedRowRender: () => '',
            rowExpandable: (record) => record.index === 1,
            onExpand: (expanded, record) => {
              setExpaned(expanded);
            },
          }}
          scroll={{ x: 'max-content' }}
          dataSource={expaned ? data : data.slice(0, 2)}
          pagination={false}
          rowKey="date"
        />
      </Card>
    </div>
  );
}

export default BossKanban;
