import { Modal, Input, Tag, Space, message } from 'antd';
import { useEffect, useState } from 'react';
import './index.less';

function TurnDownModal({ labelList = [], visible, onClose, data, onOk, tips }) {
  const [string, setString] = useState('');
  const handleSelect = ({ label }) => {
    const index = string.indexOf(label);
    if (index > -1) {
      setString(string.replace(`${label}，`, ''));
    } else {
      setString(`${string}${label}，`);
    }
  };
  const handleOk = () => {
    if (!string) {
      message.error('请输入驳回理由');
      return;
    }
    if (onOk) {
      onOk(data, string);
    }
    // onClose();
  };
  useEffect(() => {
    if (!visible) {
      setString('');
    }
  }, [visible]);
  return (
    <Modal
      title="驳回确认"
      visible={visible}
      className="turn-down-modal"
      onOk={handleOk}
      onCancel={onClose}
      zIndex={1001}
    >
      <div className="tips">{tips || '驳回后，该片将消失，请慎重操作'}</div>
      <Input.TextArea
        rows={4}
        placeholder="输入驳回理由"
        value={string}
        onChange={(e) => setString(e.target.value)}
      />
      <Space wrap style={{ marginTop: '15px' }}>
        {labelList.map((item) => (
          <div
            key={item.value}
            style={{ cursor: 'pointer' }}
            onClick={() => handleSelect(item)}
          >
            <Tag
              color={string.indexOf(item.label) > -1 ? 'processing' : 'default'}
            >
              {item.label}
            </Tag>
          </div>
        ))}
      </Space>
    </Modal>
  );
}
export default TurnDownModal;
