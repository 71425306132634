import { useState, useEffect } from 'react';
import { Modal, Button, Tabs } from 'antd';
import Player from '../player';

import './index.less';

const { TabPane } = Tabs;

function PlayerModal({ onClose, type, noTab, videoType, previewUrl, url }) {
  const [activeKey, setActiveKey] = useState('1');

  const handleChangeTab = (key) => {
    setActiveKey(key);
  };

  useEffect(() => {
    if (type === 'whole') {
      setActiveKey('2');
    } else {
      setActiveKey('1');
    }
  }, [type]);

  return (
    <Modal
      title="预览视频"
      visible
      className="film-modal"
      width={1000}
      destroyOnClose
      onCancel={onClose}
      onOk={onClose}
    >
      {noTab ? (
        <div className="player-content">
          <Player active={activeKey === '1'} type={videoType} url={url} />
        </div>
      ) : (
        <Tabs activeKey={activeKey} onChange={handleChangeTab}>
          <TabPane tab="预告片" key="1">
            <div className="player-content">
              <Player
                active={activeKey === '1'}
                type={videoType}
                url={previewUrl}
              />
            </div>
          </TabPane>
          <TabPane tab="正片" key="2">
            <div className="player-content">
              <Player active={activeKey === '2'} type={videoType} url={url} />
            </div>
          </TabPane>
        </Tabs>
      )}
    </Modal>
  );
}

export default PlayerModal;
