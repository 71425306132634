import { Form, Input, InputNumber, Select, Image, Button } from 'antd';

const { Option } = Select;

const POSITION_LIST = [
  {
    value: 1,
    label: '首页banner',
  },
  {
    value: 2,
    label: '预售banner',
  },
  {
    value: 3,
    label: '会员页banner',
  },
  {
    value: 4,
    label: '制片厂banner',
  },
  // {
  //   value: 5,
  //   label: '短视频广告',
  // },
  {
    value: 6,
    label: '启屏页广告',
  },
];

const JUMP_LIST = [
  {
    value: 1,
    label: '内调APP',
  },
  {
    value: 2,
    label: '外跳H5',
  },
];

const JUMP_PAGE_LIST = [
  {
    value: 1,
    label: '分享详情页',
  },
  {
    value: 3,
    label: '金币充值页',
  },
  {
    value: 2,
    label: 'VIP充值页',
  },
  {
    value: 4,
    label: '代理赚钱页',
  },
  {
    value: 5,
    label: '视频',
  },
  {
    value: 6,
    label: '帖子',
  },
];

export const COLUMNS = [
  {
    title: '广告id',
    dataIndex: 'adId',
    align: 'center',
    width: 165,
  },
  {
    title: '广告标题',
    dataIndex: 'title',
    align: 'center',
    width: 180,
    ellipsis: true,
    formEditable: true,
    type: 'input',
    rules: [{ required: true, message: '请输入广告标题' }],
    formSort: 1,
  },
  {
    title: '广告位置',
    dataIndex: 'position',
    align: 'center',
    formEditable: true,
    type: 'select',
    width: 100,
    rules: [{ required: true, message: '请选择广告位置' }],
    selectList: POSITION_LIST,
    formSort: 3,
    detailNotEditable: true,
    render: (text) => {
      const position = POSITION_LIST.find((item) => item.value === text);
      return position?.label;
    },
    format: (text) => {
      const position = POSITION_LIST.find((item) => item.value === text);
      return position?.label;
    },
  },
  {
    title: '广告图 / 视频',
    dataIndex: 'source',
    align: 'center',
    width: 120,
    type: 'upload',
    formEditable: true,
    formSort: 4,
    valuePropName: 'fileList',
    detailNotEditable: true,
    uploadUrl: '/manage/adConfig/image/upload',
    rules: [{ required: true, message: '请上传广告图 / 视频' }],
    accept: 'image/gif,image/webp,image/jpeg,image/jpg,image/png,video/mp4',
  },
  {
    title: '广告权重',
    dataIndex: 'weight',
    align: 'center',
    width: 70,
    formSort: 6,
    customForm: true,
    conditionForm: true,
    render: (text) => (text ? `${text}%` : '无'),
    format: (text, record) => (
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.position !== currentValues.position
        }
      >
        {({ getFieldValue }) => {
          const position = getFieldValue('position');
          return (
            (position === 6 || position === 5) && (
              <Form.Item
                label="广告权重"
                name="weight"
                rules={[{ required: true, message: '请输入广告权重' }]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            )
          );
        }}
      </Form.Item>
    ),
  },
  {
    title: 'banner呈现顺序',
    dataIndex: 'bannerOrder',
    align: 'center',
    width: 70,
    formSort: 5,
    customForm: true,
    conditionForm: true,
    render: (text) => (text || text === 0 ? text : '无'),
    format: (text, record) => (
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.position !== currentValues.position
        }
      >
        {({ getFieldValue }) => {
          const position = getFieldValue('position');
          const isBanner =
            [1, 2, 3, 4].findIndex((item) => item === position) > -1;
          return (
            isBanner && (
              <Form.Item
                label="banner呈现顺序"
                name="bannerOrder"
                rules={[{ required: true, message: '请输入banner呈现顺序' }]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            )
          );
        }}
      </Form.Item>
    ),
  },
  {
    title: '广告跳转方式',
    dataIndex: 'redirectType',
    align: 'center',
    width: 90,
    formEditable: true,
    detailNotEditable: true,
    type: 'select',
    rules: [{ required: true, message: '请选择广告跳转方式' }],
    selectList: JUMP_LIST,
    formSort: 8,
    render: (text) => {
      const type = JUMP_LIST.find((item) => item.value === text);
      return type?.label;
    },
    format: (text) => {
      const type = JUMP_LIST.find((item) => item.value === text);
      return type?.label;
    },
  },
  {
    title: '广告地址',
    dataIndex: 'redirectUri',
    align: 'center',
    formSort: 9,
    customForm: true,
    conditionForm: true,
    render: (text, record) => {
      if (record.redirectType === 2) {
        // 1内调 2外调
        return record.outerRedirectUri;
      }
      const data = JUMP_PAGE_LIST.find(
        (item) => item.value === record.innerRedirectUriType
      );
      const id = record.innerRedirectUriParams;
      return `${data?.label}${id ? `id:${id}` : ''}`;
    },
    format: (text, record, { modalType }) => (
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.redirectType !== currentValues.redirectType ||
          prevValues.innerRedirectUriType !== currentValues.innerRedirectUriType
        }
      >
        {({ getFieldValue }) => {
          const jumpType = getFieldValue('redirectType');
          if (!jumpType) {
            return '';
          }
          const adressType = getFieldValue('innerRedirectUriType');
          const needId = [5, 6].find((item) => item === adressType);
          return jumpType === 1 ? (
            <Form.Item label="广告地址" required>
              <Form.Item
                noStyle
                name="innerRedirectUriType"
                rules={[{ required: true, message: '请选择跳转地址' }]}
              >
                <Select
                  style={{ width: 120 }}
                  disabled={modalType === 'detail'}
                >
                  {JUMP_PAGE_LIST.map((item) => (
                    <Option value={item.value} key={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {needId && (
                <Form.Item
                  noStyle
                  name="innerRedirectUriParams"
                  rules={[
                    { required: true, message: '请输入视频或帖子需输入参数ID' },
                  ]}
                >
                  <Input
                    placeholder="请输入视频或帖子需输入参数ID"
                    style={{ width: '300px', marginLeft: '10px' }}
                  />
                </Form.Item>
              )}
            </Form.Item>
          ) : (
            <Form.Item
              label="广告地址"
              name="outerRedirectUri"
              rules={[
                { required: true, message: '请输入外跳链接' },
                { type: 'url', message: '请输入正确的外跳链接' },
              ]}
            >
              <Input placeholder="请输入外跳链接" />
            </Form.Item>
          );
        }}
      </Form.Item>
    ),
  },
  {
    title: '广告生成时间',
    dataIndex: 'createTime',
    align: 'center',
    width: 90,
  },
  {
    title: '是否生效',
    dataIndex: 'status',
    align: 'center',
    editable: true,
    type: 'switch',
    valuePropName: 'checked',
    width: 70,
    // rules: [{ required: true, message: '请选择是否生效' }],
    formSort: 2,
    // detailNotEditable: true,
    format: (text) => (text ? '是' : '否'),
  },
  {
    title: '广告停留时间/秒',
    dataIndex: 'stayDuration',
    align: 'center',
    notShowTable: true,
    formSort: 7,
    customForm: true,
    conditionForm: true,
    format: (text, record) => (
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.position !== currentValues.position
        }
      >
        {({ getFieldValue }) => {
          const position = getFieldValue('position');
          return (
            (position === 6 || position === 5) && (
              <Form.Item
                label="广告停留时间(秒)"
                name="stayDuration"
                rules={[{ required: true, message: '请输入广告停留时间' }]}
              >
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            )
          );
        }}
      </Form.Item>
    ),
  },
];
