import { Space } from 'antd';
import {
  BossChart,
  BossKanban,
  ConversionData,
  BusinessData,
  DataOverview,
  InstalledCapacity,
} from './components';
import './index.less';

function DataKanban() {
  return (
    <div className="data-kanban-wrap">
      <Space direction="vertical" size="large">
        <BossChart />
        <BossKanban />
        <ConversionData />
        <BusinessData />
        <DataOverview />
        <InstalledCapacity />
      </Space>
    </div>
  );
}

export default DataKanban;
