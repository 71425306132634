import { post } from '@/utils/http';

export const getList = (params) =>
  post({
    url: '/manage/customerService/domainDetection/get/page',
    data: params,
  });
export const test = (params) =>
  post({
    url: '/manage/customerService/domainDetection/check',
    data: params,
  });
