import {
  PageHeader,
  Card,
  Space,
  Form,
  Row,
  Col,
  Select,
  Input,
  Button,
  Table,
  message,
  Modal,
  Checkbox,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useOutletContext } from 'react-router-dom';
import ClipboardJS from 'clipboard';
import { useAntdTable, useDeepCompareEffect } from 'ahooks';
import {
  SORT1_OPTIONS,
  FILTER1_OPTIONS,
  FILTER2_OPTIONS,
  COLUMNS,
  TURN_DOWN_LABELS,
} from './config';
import {
  EditableRow,
  EditableCell,
  TurnDownModal,
  DetailModal,
} from '@/components';
import {
  useDetailModal,
  useTableColumns,
  useModalFormColumns,
  useTableRowSelection,
} from '@/hooks';
import { PassModal } from './components';
import {
  getList,
  passBatch,
  rejectBatch,
} from '@/services/approval-management/cashOutApproval';
import { getOrderParams } from '@/utils/commonUtils';

const { Option } = Select;
const { confirm } = Modal;

const initialValues = {
  sort: '',
  filter1: '',
  filter2: '',
  searchType: 'factoryName',
  search: '',
};

export const SEARCH_TYPE_LIST = [
  { value: 'factoryName', label: '片商名' },
  { value: 'userName', label: '用户名' },
  { value: 'channelName', label: '渠道名' },
];

export const selectBefore = (
  <Form.Item name="searchType" noStyle>
    <Select>
      {SEARCH_TYPE_LIST.map((item) => (
        <Option value={item.value} key={item.value}>
          {item.label}
        </Option>
      ))}
    </Select>
  </Form.Item>
);

const components = {
  body: {
    row: EditableRow,
    cell: EditableCell,
  },
};

const getTableData = async ({ current, pageSize }, formData) => {
  const { orders, search } = getOrderParams(formData);
  const result = await getList({
    pageIndex: current,
    pageSize,
    orders,
    params: search,
  });

  const data = result.data || {};
  return {
    total: data.total || 0,
    list: data.data || [],
  };
};

function TriviaApproval() {
  const { refreshApproval } = useOutletContext();
  const [form] = Form.useForm();
  const { tableProps, search, refresh } = useAntdTable(getTableData, {
    form,
    defaultParams: [{ current: 1, pageSize: 10 }, initialValues],
  });
  const [selectedRowKeys, setSelectedRowKeys] = useTableRowSelection({
    dataSource: tableProps.dataSource,
    keyName: 'withdrawId',
  });
  const { formColumns, getModalFormColumns } = useModalFormColumns(COLUMNS);
  const { showDetail, selectRecord, handleShowDetail, handleCloseDetail } =
    useDetailModal();
  const {
    showDetail: showTurnDownModal,
    selectRecord: turnDownRecord,
    handleShowDetail: handleShowTurnDown,
    handleCloseDetail: handleCloseTurnDown,
  } = useDetailModal();
  const {
    showDetail: showPassModal,
    selectRecord: passRecord,
    handleShowDetail: handleShowPass,
    handleCloseDetail: handleClosePass,
  } = useDetailModal();

  const handleDetail = (record) => {
    getModalFormColumns(record, 'detail');
    handleShowDetail(record);
  };

  const { tableColumns, setTableColumns } = useTableColumns(COLUMNS, {
    controlColumns: {
      title: '操作',
      key: 'action',
      width: 150,
      align: 'center',
      fixed: 'right',
      render: (text, record) => (
        <>
          {record.status === 1 && (
            <>
              <Button
                type="link"
                size="small"
                onClick={() => handleShowPass(record)}
              >
                通过
              </Button>
              <Button
                type="link"
                size="small"
                danger
                onClick={() => handleShowTurnDown([record.withdrawId])}
              >
                驳回
              </Button>
            </>
          )}

          <Button type="link" size="small" onClick={() => handleDetail(record)}>
            查看
          </Button>
        </>
      ),
    },
  });

  useDeepCompareEffect(() => {
    const copyDataIndex = ['accountNum', 'payee'];
    const clipboardArr = [];
    tableProps.dataSource.forEach((item) => {
      copyDataIndex.forEach((dataIndex) => {
        if (item[dataIndex]) {
          const clipboard = new ClipboardJS(
            `#${dataIndex}-${item.withdrawId}-copy`,
            {
              text(trigger) {
                return item[dataIndex];
              },
            }
          );

          clipboard.on('success', (e) => {
            message.success('复制成功！');
          });

          clipboard.on('error', (e) => {
            message.error('复制失败！');
          });
          clipboardArr.push(clipboard);
        }
      });
    });
    return () => {
      clipboardArr.forEach((clipboard) => {
        clipboard.destroy();
      });
    };
  }, [tableProps.dataSource]);

  const passCashOut = async (withdrawIds) => {
    const result = await passBatch({
      withdrawIds,
    });
    if (result.code === 200) {
      message.success('操作成功');
      refresh();
      handleClosePass();
      setSelectedRowKeys([]);
      refreshApproval();
    }
  };

  const rejectCashOut = async (withdrawIds, rejectReason) => {
    const result = await rejectBatch({
      withdrawIds,
      rejectReason,
    });
    if (result.code === 200) {
      message.success('操作成功');
      refresh();
      handleCloseTurnDown();
      setSelectedRowKeys([]);
      refreshApproval();
    }
  };

  const handleBachPass = () => {
    confirm({
      title: '是否要批量通过提现申请?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        passCashOut(selectedRowKeys);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleChangeCheckbox = (val, record) => {
    if (val) {
      setSelectedRowKeys([...selectedRowKeys, record.withdrawId]);
    } else {
      const newSelect = selectedRowKeys.filter(
        (item) => item !== record.withdrawId
      );
      setSelectedRowKeys(newSelect);
    }
  };

  const handleSelectAll = (selected) => {
    if (selected) {
      const selectKeys = [];
      tableProps.dataSource.forEach((item) => {
        if (item.status === 1) {
          selectKeys.push(item.withdrawId);
        }
      });
      if (selectKeys.length === selectedRowKeys.length) {
        setSelectedRowKeys([]);
      } else {
        setSelectedRowKeys(selectKeys);
      }
    } else {
      setSelectedRowKeys([]);
    }
  };

  return (
    <div className="long-video-approval">
      <PageHeader ghost={false} backIcon={false} title="提现审批" />
      <div className="content-wrap">
        <Space direction="vertical" style={{ width: '100%' }}>
          <Card>
            <Form initialValues={initialValues} form={form}>
              <Row>
                <Col span={5}>
                  <Form.Item
                    label="排序1"
                    name="sort"
                    style={{ marginBottom: '10px' }}
                  >
                    <Select>
                      {SORT1_OPTIONS.map((item) => (
                        <Option value={item.value} key={item.value}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4} offset={1}>
                  <Form.Item
                    label="筛选1"
                    name="filter1"
                    style={{ marginBottom: 0 }}
                  >
                    <Select>
                      {FILTER1_OPTIONS.map((item) => (
                        <Option value={item.value} key={item.value}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={3} offset={1}>
                  <Form.Item
                    label="筛选2"
                    name="filter2"
                    style={{ marginBottom: 0 }}
                  >
                    <Select>
                      {FILTER2_OPTIONS.map((item) => (
                        <Option value={item.value} key={item.value}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6} offset={1}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.searchType !== curValues.searchType
                    }
                  >
                    {({ getFieldValue }) => {
                      const type = getFieldValue('searchType');
                      const item = SEARCH_TYPE_LIST.find(
                        (item) => item.value === type
                      );
                      return (
                        <Form.Item
                          label="关键搜索"
                          name="search"
                          style={{ marginBottom: 0, marginLeft: '-19px' }}
                        >
                          <Input
                            placeholder={`输入${item.label}`}
                            addonBefore={selectBefore}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={2} offset={1}>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Button type="primary" onClick={search.submit}>
                      确认查看
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
          <Card>
            <div className="table-title">查询结果</div>
            <div className="batch-btn-wrap">
              <Space>
                <Button
                  type="primary"
                  size="small"
                  disabled={!selectedRowKeys.length}
                  onClick={handleBachPass}
                >
                  通过
                </Button>
                <Button
                  type="primary"
                  size="small"
                  danger
                  disabled={!selectedRowKeys.length}
                  onClick={() => handleShowTurnDown(selectedRowKeys)}
                >
                  驳回
                </Button>
              </Space>
            </div>
            <Table
              className="edit-table"
              components={components}
              rowClassName={() => 'editable-row'}
              bordered
              columns={tableColumns}
              scroll={{ x: 'max-content' }}
              rowSelection={{
                selectedRowKeys,
                // onChange: (selectedRowKeys) =>
                //   setSelectedRowKeys(selectedRowKeys),
                onSelectAll: handleSelectAll,
                renderCell: (checked, record) =>
                  record.status === 1 ? (
                    <Checkbox
                      checked={checked}
                      onChange={(e) =>
                        handleChangeCheckbox(e.target.checked, record)
                      }
                    />
                  ) : (
                    ''
                  ),
              }}
              {...tableProps}
              pagination={{
                ...tableProps.pagination,
                showTotal: (total) => `共 ${total} 条`,
                // showSizeChanger: true,
                // showQuickJumper: true,
              }}
              rowKey="withdrawId"
            />
          </Card>
        </Space>
      </div>
      <DetailModal
        columns={formColumns}
        visible={showDetail}
        onClose={handleCloseDetail}
        data={selectRecord}
        centered
        title="提现详情"
        inline
      />
      <TurnDownModal
        labelList={TURN_DOWN_LABELS}
        visible={showTurnDownModal}
        data={turnDownRecord}
        onClose={handleCloseTurnDown}
        onOk={rejectCashOut}
        tips=" "
      />
      <PassModal
        visible={showPassModal}
        data={passRecord}
        onClose={handleClosePass}
        onOk={() => passCashOut([passRecord.withdrawId])}
      />
    </div>
  );
}

export default TriviaApproval;
