const TYPE_MAP = {
  1: '历史解绑',
  2: '新增解绑',
};
const STATE_MAP = {
  0: '未生效',
  1: '进行中',
  2: '已停用',
  3: '已结束',
};

export const COLUMNS = [
  {
    title: '砍量序号',
    dataIndex: 'recedeId',
    key: 'recedeId',
    width: 120,
    align: 'center',
  },
  {
    title: '砍量类型',
    dataIndex: 'recedeType',
    key: 'recedeType',
    width: 120,
    align: 'center',
    render: (text) => TYPE_MAP[text] || '',
  },
  {
    title: '砍量对象（无法作用渠道邀请用户）',
    dataIndex: 'area',
    key: 'area',
    width: 160,
    align: 'center',
    render: (text, record) =>
      record.startTime
        ? `${record.startTime}${
            record.endTime ? `-${record.endTime}日` : '日开始'
          }新增的用户`
        : '',
  },
  {
    title: '砍量周期',
    dataIndex: 'exe',
    key: 'exe',
    width: 130,
    align: 'center',
    render: (text, record) => {
      let content = '';
      if (record.recedeType === 1) {
        // 历史解绑
        content = record.exe === 1 ? '瞬间生效' : '';
      } else {
        content = record.always ? '永久' : `${record.endTime}日到期`;
      }
      return content;
    },
  },
  {
    title: '砍量力度',
    dataIndex: 'percent',
    key: 'percent',
    width: 150,
    align: 'center',
    render: (text, record, index) =>
      record.recedeType === 1 // 历史解绑1，新增解绑2
        ? `${text}%的被邀请用户将会解绑上级`
        : `被邀请用户${text}%概率无法绑定上级`,
  },
  {
    title: '已解绑人数',
    dataIndex: 'releaseNumber',
    key: 'releaseNumber',
    width: 60,
    align: 'center',
  },
  {
    title: '已偷掉充值(元)',
    dataIndex: 'amount',
    key: 'amount',
    width: 70,
    align: 'center',
  },
  {
    title: '砍量状态',
    dataIndex: 'taskStart',
    key: 'taskStart',
    width: 70,
    align: 'center',
    render: (text) => STATE_MAP[text] || '',
  },
  {
    title: '是否启用',
    dataIndex: 'enable',
    key: 'enable',
    // needRender: true,
    editable: true,
    width: 70,
    align: 'center',
    type: 'switch',
    valuePropName: 'checked',
  },
  {
    title: '任务开始时间',
    dataIndex: 'taskTime',
    key: 'taskTime',
    width: 90,
    align: 'center',
  },
];

export const CUT_TYPE = [
  {
    value: 1,
    label: '历史解绑：代表着对已经被邀请进入app的用户，进行解绑操作',
  },
  {
    value: 2,
    label: '新增解绑：代表着对未来即将被邀请进入app的用户，进行解绑操作',
  },
];
