/* eslint-disable react/jsx-filename-extension */
import { Image, Form, Input, Space, InputNumber, Button } from 'antd';
// import Form from 'antd/lib/form/Form';

export const FILTER1_OPTIONS = [
  { value: 1, name: '全部订单' },
  { value: 2, name: '今日订单' },
  { value: 3, name: '本周订单' },
];
export const FILTER2_OPTIONS = [
  { value: 1, name: '全部状态' },
  { value: 2, name: '已支付订单' },
  { value: 3, name: '待支付订单' },
];

export const STATUS_MAP = {
  SUCCEED: '支付成功', // 启用1，禁用0
  AWAIT: '等待支付',
  FAIL: '支付失败',
};

const TYPE_MAP = {
  MEMBER: '会员',
  COIN: '金币',
};

export const COLUMNS = [
  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'center',
    // ellipsis: true,
    // width: 200,
    fixed: 'left',
    // notShowForm: true,
    render: (text, record) => (
      <>
        <span>{text}</span>
        <Button
          type="link"
          size="small"
          style={{ fontSize: '12px' }}
          id={`orderId-${record.orderId}-copy`}
        >
          复制
        </Button>
      </>
    ),
    // suffix: '复制',
    // suffixType: 'copy',
  },
  {
    title: '商户订单号',
    dataIndex: 'busOrderId',
    align: 'center',
    render: (text, record) => (
      <>
        <span>{text}</span>
        <Button
          type="link"
          size="small"
          style={{ fontSize: '12px' }}
          id={`busOrderId-${record.orderId}-copy`}
        >
          复制
        </Button>
      </>
    ),
  },
  {
    title: '订单创建时间',
    dataIndex: 'createTime',
    align: 'center',
    width: 90,
    notShowForm: true,
  },
  {
    title: '用户邀请码',
    dataIndex: 'inviteCode',
    align: 'center',
    width: 100,
  },
  {
    title: '用户id',
    dataIndex: 'userId',
    align: 'center',
  },
  {
    title: '用户名',
    dataIndex: 'userName',
    align: 'center',
    ellipsis: true,
  },
  {
    title: '手机号',
    dataIndex: 'phone',
    align: 'center',
  },
  {
    title: '订单商品',
    dataIndex: 'type',
    align: 'center',
    render: (text) => TYPE_MAP[text] || '',
  },
  {
    title: '订单金额',
    dataIndex: 'money',
    align: 'center',
  },
  {
    title: '实际支付金额',
    dataIndex: 'realMoney',
    align: 'center',
  },
  {
    title: '支付方式',
    dataIndex: 'payType',
    align: 'center',
  },
  {
    title: '状态',
    dataIndex: 'status',
    align: 'center',
    render: (text) => STATUS_MAP[text] || '',
  },
  {
    title: '对应支付通道',
    dataIndex: 'platName',
    align: 'center',
  },
  {
    title: '支付费率',
    dataIndex: 'percent',
    align: 'center',
  },
];

export const orderColumns = [
  {
    title: '补单码',
    dataIndex: 'code',
    type: 'input',
    editable: true,
    rules: [{ required: true, message: '请输入补单码' }],
    placeholder: '输入补单码（订单金额必须与补单码金额相匹配）',
  },
];
