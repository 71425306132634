import { Modal, Form, Row, Col, InputNumber, DatePicker } from 'antd';
import { useEffect } from 'react';

const FORM_MAP = {
  inputNumber: <InputNumber style={{ width: '100%' }} />,
  date: (
    <DatePicker
      showTime={{ format: 'HH:mm' }}
      format="YYYY-MM-DD HH:mm"
      allowClear={false}
    />
  ),
};

function DetailModal({ columns, data = {}, visible, onClose, updateTable }) {
  const [form] = Form.useForm();

  const handleOk = async () => {
    const values = await form.validateFields();
    console.log(values);
    updateTable();
    onClose();
  };

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(data);
    }
  }, [visible]);

  return (
    <Modal
      title={`查看用户${visible}`}
      visible={visible}
      onOk={handleOk}
      onCancel={onClose}
      width="800px"
    >
      <Form labelCol={{ span: 10 }} wrapperCol={{ span: 14 }} form={form}>
        <Row>
          {columns.map((item, index) => (
            <Col span="10" offset={index % 2 === 0 ? 1 : 2} key={item.title}>
              {item.editable ? (
                <Form.Item
                  label={item.title}
                  name={item.dataIndex}
                  rules={item.rules || []}
                >
                  {FORM_MAP[item.type]}
                </Form.Item>
              ) : (
                <Form.Item label={item.title}>
                  <span>{data[item.dataIndex]}</span>
                </Form.Item>
              )}
            </Col>
          ))}
        </Row>
      </Form>
    </Modal>
  );
}

export default DetailModal;
