import { post } from '@/utils/http';

export const getArrangeData = (params) =>
  post({
    url: '/manage/video/manage/dateData',
    data: params,
  });
export const getVideoByDate = (params) =>
  post({
    url: '/manage/video/manage/table',
    data: params,
  });
export const searchVideo = (params) =>
  post({
    url: '/manage/video/manage/search',
    data: params,
  });
export const addToday = (params) =>
  post({
    url: '/manage/video/manage/add',
    data: params,
  });
export const sortVideo = (params) =>
  post({
    url: '/manage/video/manage/save',
    data: params,
  });
export const delVideo = (params) =>
  post({
    url: '/manage/video/manage/delete',
    data: params,
  });
