export const COLUMNS = [
  {
    title: '域名名称',
    dataIndex: 'domainName',
    align: 'center',
    editable: true,
    type: 'input',
    rules: [{ required: true, message: '请输入域名名称' }],
  },
  {
    title: '域名链接',
    dataIndex: 'url',
    align: 'center',
    editable: true,
    ellipsis: true,
    type: 'input',
    rules: [{ required: true, message: '请输入域名链接' }],
  },
];
